<template>
  	<teleport to="#modals">
		<Transition name="fade" appear>
			<div class="modal-overlay" @click.self="toggleModal" aria-label="Close modal">
				<div :class="{ modal: true, shadowHigh: !isHovered, shadowMedium: isHovered}" 
				role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
				@mouseenter="isHovered = true" @mouseleave="isHovered = false">
					<header class="modal-header" id="modalTitle">
						<h1 v-if="review.albumData.Artist"> {{ review.albumData.Artist }}</h1>
						<h2 v-if="review.albumData.Album"> {{ review.albumData.Album }} ({{ review.albumData.Year }})</h2>
					</header>
					<section class="modal-body" id="modalDescription">
						
						<form @submit.prevent="editActiveReview(review)">
							<input type="text" v-model="review.reviewHeader">
							<textarea type="text" v-model="review.reviewBody"></textarea>
							<star-rating
								:increment="0.5"
								:star-size="30"
								:padding="2"
								:animate="true"
								v-model:rating="review.reviewRating">
							</star-rating>
							<button>Update Review</button>
						</form>

						<div class="modal-preview">
							<h2 v-if="review.reviewHeader">{{ review.reviewHeader }}</h2>
							<p class="reviewBody" v-if="review.reviewBody">{{ review.reviewBody }}</p>
							<div class="metadata">
								<p v-if="review.userName">Written by <strong>{{ review.userName }}</strong> {{ timeConverter(review.created) }}</p>
							</div>
						</div>

					</section>

					<!-- Close button -->
					<span @click="toggleModal" class="close-btn material-icons" aria-label="Close modal">cancel</span>
					
				</div>
			</div>
		</Transition>
  	</teleport>
</template>

<script>
import { ref } from 'vue'
import { auth, db } from '@/firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, updateDoc, serverTimestamp, deleteField, setDoc } from 'firebase/firestore'
import StarRating from 'vue-star-rating'


export default {
	components: { StarRating },
	emits: ['close'],
	props: ['review'],
	setup(props, context) {

		const isHovered = ref(false)

		const toggleModal = () => {
			context.emit('close')
			console.log(props.review)
		}
		// Edit record
		const editActiveReview = async(review) => {
			console.log('Edit Review:', review)

			const data = {}
			if (review.reviewHeader !== undefined && review.reviewHeader != ''){
				data.reviewHeader = review.reviewHeader
			} else {updateDoc(reviewHeader, {reviewHeader: deleteField()})}
			if (review.reviewBody !== undefined && review.reviewBody != ''){
				data.reviewBody = review.reviewBody
			} else {updateDoc(reviewBody, {reviewBody: deleteField()})}
			if (review.reviewRating !== undefined && review.reviewRating != ''){
				data.reviewRating = review.reviewRating
			} else {updateDoc(reviewRating, {reviewRating: deleteField()})}

			data.updated = serverTimestamp()
			
			console.log('Edit Data:', data)
			
			const docRef = doc(db, 'reviews', review.id)
			await setDoc(docRef, data, { merge: true })
            .then (docRef => {
                console.log('Updated Document')
				toggleModal()
            })
            .catch(error =>{
                console.log(error)
            })
		}

		const makeArray = (string) => {
            let array = string.split(', ')
            return array
        }
		const dateConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit"} )
				return newdate
            }
        } 
		const timeConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit", hour:"numeric", minute:"2-digit", second:"2-digit"} )
				return newdate
            }
        } 

		return { makeArray, toggleModal, timeConverter, dateConverter, editActiveReview, isHovered
		}
	}
}
</script>

<style scoped>
	body {
		height: 100vh;
		overflow-y: hidden;
	}
	.modal {
		min-width: 40vw;
		margin: 30px 20px;
		height: 90vh;
	}
	.modal-header,
	.modal-footer {
		padding: 15px;
		display: flex;
	}
	.modal-preview {
		margin-top: 30px;
		padding: 25px;
		background-color: #1212122a;
	}
	.modal-preview p {
		margin: 20px 0;
	}
</style>