export const getMusicians = async (album) => {
	console.log('%c getMusicians ','color:red;background:gray',album)
	let musiciansArray = [] // Array over alle instrumter og alle musikere
	if(album.musicianBassArr){album.musicianBassArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Bass'})})}
	if(album.musicianClarinetArr){album.musicianClarinetArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Clarinet'})})}
	if(album.musicianCongaArr){album.musicianCongaArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Conga'})})}
	if(album.musicianCornetteArr){album.musicianCornetteArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Cornette'})})}
	if(album.musicianDrumsArr){album.musicianDrumsArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Drums'})})}
	if(album.musicianEuphoniumArr){album.musicianEuphoniumArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Euphonium'})})}
	if(album.musicianFluteArr){album.musicianFluteArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Flute'})})}
	if(album.musicianGuitarArr){album.musicianGuitarArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Guitar'})})}
	if(album.musicianHarpArr){album.musicianHarpArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Harp'})})}
	if(album.musicianHornsArr){album.musicianHornsArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Horns'})})}
	if(album.musicianOrganArr){album.musicianOrganArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Organ'})})}
	if(album.musicianPercussionArr){album.musicianPercussionArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Percussion'})})}
	if(album.musicianPianoArr){album.musicianPianoArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Piano'})})}
	if(album.musicianSaxAltoArr){album.musicianSaxAltoArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Alto Saxophone'})})}
	if(album.musicianSaxBarytonArr){album.musicianSaxBarytonArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Baryton Saxophone'})})}
	if(album.musicianSaxSopranoArr){album.musicianSaxSopranoArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Soprano Saxophone'})})}
	if(album.musicianSaxTenorArr){album.musicianSaxTenorArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Tenor Saxophone'})})}
	if(album.musicianSitarArr){album.musicianSitarArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Sitar'})})}
	if(album.musicianStringArr){album.musicianStringArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Strings'})})}
	if(album.musicianTromboneArr){album.musicianTromboneArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Trombone'})})}
	if(album.musicianTrumpetArr){album.musicianTrumpetArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Trumpet'})})}
	if(album.musicianTubaArr){album.musicianTubaArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Tuba'})})}
	if(album.musicianVibraphoneArr){album.musicianVibraphoneArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Vibraphone'})})}
	if(album.musicianVocalArr){album.musicianVocalArr.forEach((musician)=> {musiciansArray.push({name: musician, instrument: 'Vocal'})})}
	// Get new array from musiciansArray
	let result = groupMusiciansByInstruments(musiciansArray);
	console.log('%c musicians ','color:khaki', result)
	return result
}

const groupMusiciansByInstruments = (array) => {
	// Create a map to keep track of musicians and their instruments
	const musicianMap = new Map();
  
	array.forEach(musician => {
	  const { name, instrument } = musician;
	  
	  // If the musician already exists in the map, add the instrument to their list
	  if (musicianMap.has(name)) {
		musicianMap.get(name).push(instrument);
	  } else {
		// Otherwise, create a new entry in the map with the instrument in an array
		musicianMap.set(name, [instrument]);
	  }
	});
	// Convert the map back to an array of objects and sort by name
	return Array.from(musicianMap, ([name, instruments]) => ({ name, instruments }))
		.sort((a, b) => a.name.localeCompare(b.name));
  }