// Import functions from firebase
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBr9wINjnQ1a9M0jJpR-edgqtPVrPrZ870",
    authDomain: "albums-bb9bf.firebaseapp.com",
    projectId: "albums-bb9bf",
    storageBucket: "albums-bb9bf.appspot.com",
    messagingSenderId: "1001627286320",
    appId: "1:1001627286320:web:04bf2f4bfe36726cfda6f5"
  };

// Initialize Firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore()
const auth = getAuth()

export { db, auth }