<template>
	<div class="reviewModule">
		<div v-for="review in reviews" :key="review.id" class="reviewContainer">
			<h1 v-if="review.albumData.Artist"> {{ review.albumData.Artist }}</h1>
			<h2 v-if="review.albumData.Album"> {{ review.albumData.Album }} ({{ review.albumData.Year }})</h2>
			<div class="album-cover">
				<img v-if="review.albumData.DiscogsID" :src="require('../assets/covers/' + review.albumData.albumID + '.jpg')" 
				:alt="review.albumData.Album" loading="lazy"
				class="bilde"
				@click="toggleAlbum(review)"
				:id="review.albumData.id">

				<star-rating v-if="review.reviewRating"
					:increment="0.5"
					:star-size="30"
					:padding="2"
					:animate="true"
					:rating="review.reviewRating">
				</star-rating>
			</div>

			<h2 v-if="review.reviewHeader && review.userName == 'Allmusic'">Review by {{ review.reviewHeader }}</h2>
			<h2 v-else-if="review.reviewHeader">{{ review.reviewHeader }}</h2>
			<div v-if="review.reviewBody" class="reviewBody">
				<p v-if="!review.readMore && review.reviewBody" class="reviewBody" >{{ review.reviewBody.slice(0, 200) }}</p>
				<a class="readMore" v-if="!review.readMore && review.reviewBody.length > 200" @click="activateReadMore(review)">Read more...</a>
				<p v-if="review.readMore && review.reviewBody" class="reviewBody" v-html="review.reviewBody"></p>
				<a class="readMore" v-if="review.readMore && review.reviewBody.length > 200" @click="activateReadMore(review)">Show less ...</a>
			</div>

			<div class="metadata">
				<p v-if="review.userName">Written by <strong>{{ review.userName }}</strong> {{ timeConverter(review.created) }}</p>
				<p v-if="review.created">Created {{ review.timeCreated}} ago</p>
				<p v-if="review.updated">Updated {{ review.timeUpdated }} ago</p>
			</div>

			<div v-if="user" class="icons">				
				<div class="tooltip iconEdit">
					<div @click="openEditModal(review)"><span v-if="user.uid  == review.userID" class="material-icons">edit</span><span class="tooltiptext">Edit Review</span></div>
				</div>
				<div class="tooltip iconDelete">
					<div @click="openDeleteModal(review)"><span v-if="user.uid  == review.userID" class="material-icons">delete</span><span class="tooltiptext">Delete Review</span></div>
					<!-- <div @click="deleteReview(review)"><span class="material-icons">delete</span><span class="tooltiptext">Delete Review</span></div> -->
				</div>
				<div class="tooltip iconComment">
					<div :class="{ icon: true, iconReview: review.comments }"
						@click="openCommentsModal(review)">
						<span class="material-icons">comment</span>
						<span class="tooltiptext">See Comments</span>
					</div>
				</div>
				<div class="tooltip iconMore">
					<div @click="toggelIDs()"><span class="material-icons">add_circle</span><span class="tooltiptext">Show More</span></div>
				</div>
			</div>

			<div v-if="review.showAlbum" class="albumData">

				<h2>Album Details:</h2>
				<div v-if="review.albumData" class="album">
					<h4 v-if="review.albumData.Series">Series: {{review.albumData.Series}}</h4>
					<h4 v-if="review.albumData.Label">Label: {{review.albumData.Label}}: {{review.albumData.CatalogID}}</h4>
					<p v-if="review.albumData.DiscogsID">DiscogsID: <a :href="review.albumData.DiscogsID" target="blank">{{review.albumData.DiscogsID}}</a></p>
				</div>

	
				<div v-if="review.albumData">
					<h3 class="subheading">Dates</h3>
					<div class="subcontent">
						<p v-if="review.albumData.Recorded1">
							<span v-if="review.albumData.Recorded1">Recorded: {{dateConverter(review.albumData.Recorded1)}}</span>
							<span v-if="review.albumData.Recorded2">, {{dateConverter(review.albumData.Recorded2)}}</span>
							<span v-if="review.albumData.Recorded3">, {{dateConverter(review.albumData.Recorded3)}}</span>
							<span v-if="review.albumData.Recorded4">, {{dateConverter(review.albumData.Recorded4)}}</span>
							<span v-if="review.albumData.Recorded5">, {{dateConverter(review.albumData.Recorded5)}}</span>
						</p>
						<p v-if="review.albumData.RecordedDates">RecordedDates: {{review.albumData.Recorded1}}</p>
						<p v-if="review.albumData.Release">Release: {{dateConverter(review.albumData.Release)}}</p>
						<p v-if="review.albumData.Collected">Collected: {{dateConverter(review.albumData.Collected)}}</p>
						<p v-if="review.albumData.Created">Created: {{dateConverter(review.albumData.Created)}}</p>
						<p v-if="review.albumData.timeCreated">timeCreated: {{review.albumData.timeCreated}}</p>
						<p v-if="review.albumData.Update">Update: {{dateConverter(review.albumData.Update)}}</p>
						
					</div>
				</div>

				<div class="musicians">
					<h3 class="showInfo">Musicians</h3>
                            <template v-if="review.albumData.musicianSaxAlto">
                                <div v-for="SaxAlto in makeArray(review.albumData.musicianSaxAlto)" :key="SaxAlto">
                                    <p>Alto Saxophone: <span>{{SaxAlto}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianSaxBaryton">
                                <div v-for="SaxBaryton in makeArray(review.albumData.musicianSaxBaryton)" :key="SaxBaryton">
                                    <p>Baryton Saxophone: <span>{{SaxBaryton}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianBass">
                                <div v-for="Bass in makeArray(review.albumData.musicianBass)" :key="Bass">
                                    <p>Bass: <span>{{Bass}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianClarinet">
                                <div v-for="Clarinet in makeArray(review.albumData.musicianClarinet)" :key="Clarinet">
                                    <p>Clarinet: <span>{{Clarinet}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianConga">
                                <div v-for="Conga in makeArray(review.albumData.musicianConga)" :key="Conga">
                                    <p>Conga: <span>{{Conga}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianCornette">
                                <div v-for="Cornette in makeArray(review.albumData.musicianCornette)" :key="Cornette">
                                    <p>Cornette: <span>{{Cornette}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianDrums">
                                <div v-for="Drums in makeArray(review.albumData.musicianDrums)" :key="Drums">
                                    <p>Drums: <span>{{Drums}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianEuphonium">
                                <div v-for="Euphonium in makeArray(review.albumData.musicianEuphonium)" :key="Euphonium">
                                    <p>Euphonium: <span>{{Euphonium}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianFlute">
                                <div v-for="Flute in makeArray(review.albumData.musicianFlute)" :key="Flute">
                                    <p>Flute: <span>{{Flute}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianGuitar">
                                <div v-for="Guitar in makeArray(review.albumData.musicianGuitar)" :key="Guitar">
                                    <p>Guitar: <span>{{Guitar}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianHarp">
                                <div v-for="Harp in makeArray(review.albumData.musicianHarp)" :key="Harp">
                                    <p>Harp: <span>{{Harp}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianHorns">
                                <div v-for="Horns in makeArray(review.albumData.musicianHorns)" :key="Horns">
                                    <p>Horns: <span>{{Horns}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianOrgan">
                                <div v-for="Organ in makeArray(review.albumData.musicianOrgan)" :key="Organ">
                                    <p>Organ: <span>{{Organ}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianPercussion">
                                <div v-for="Percussion in makeArray(review.albumData.musicianPercussion)" :key="Percussion">
                                    <p>Percussion: <span>{{Percussion}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianPiano">
                                <div v-for="Piano in makeArray(review.albumData.musicianPiano)" :key="Piano">
                                    <p>Piano: <span>{{Piano}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianSitar">
                                <div v-for="Sitar in makeArray(review.albumData.musicianSitar)" :key="Sitar">
                                    <p>Sitar: <span>{{Sitar}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianSaxSoprano">
                                <div v-for="SaxSoprano in makeArray(review.albumData.musicianSaxSoprano)" :key="SaxSoprano">
                                    <p>Soprano Saxophone: <span>{{SaxSoprano}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianString">
                                <div v-for="String in makeArray(review.albumData.musicianString)" :key="String">
                                    <p>String: <span>{{String}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianSaxTenor">
                                <div v-for="SaxTenor in makeArray(review.albumData.musicianSaxTenor)" :key="SaxTenor">
                                    <p>Tenor Saxophone: <span>{{SaxTenor}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianTrombone">
                                <div v-for="Trombone in makeArray(review.albumData.musicianTrombone)" :key="Trombone">
                                    <p>Trombone: <span>{{Trombone}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianTrumpet">
                                <div v-for="Trumpet in makeArray(review.albumData.musicianTrumpet)" :key="Trumpet">
                                    <p>Trumpet: <span>{{Trumpet}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianTuba">
                                <div v-for="Tuba in makeArray(review.albumData.musicianTuba)" :key="Tuba">
                                    <p>Tuba: <span>{{Tuba}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianVibraphone">
                                <div v-for="Vibraphone in makeArray(review.albumData.musicianVibraphone)" :key="Vibraphone">
                                    <p>Vibraphone: <span>{{Vibraphone}}</span></p>
                                </div>
                            </template>
                            <template v-if="review.albumData.musicianVocal">
                                <div v-for="Vocal in makeArray(review.albumData.musicianVocal)" :key="Vocal">
                                    <p>Vocal: <span>{{Vocal}}</span></p>
                                </div>
                            </template>
                        </div>
						<h3 class="subheading">Tracks</h3>
						<div class="subcontent">
							<p v-for="(song, index) in review.albumData.Songs" :key="song" class="song">
								{{index+1}}: {{song}}
							</p>
						</div>
						<h3 class="subheading">Links</h3>
						<div class="subcontent">
							<p><a v-if="review.albumData.Review" :href="review.albumData.Review" target="blank">Allmusic</a></p>
							<p><a v-if="review.albumData.Amazon" :href="review.albumData.Amazon" target="blank">Amazon</a></p>
							<p><a v-if="review.albumData.Discogs" :href="review.albumData.Discogs" target="blank">Discogs</a></p>
							<p><a v-if="review.albumData.Play" :href="review.albumData.Play" target="blank">Release Links</a></p>
							<p><a v-if="review.albumData.SeriesLink" :href="review.albumData.SeriesLink" target="blank">Series</a></p>
							<p><a v-if="review.albumData.Wiki" :href="review.albumData.Wiki" target="blank">Wikipedia</a></p>
						</div>

			</div>
			
			<div v-if="showIDs" class="showIDs">
				<p v-if="review.id">reviewID: {{ review.id }}</p>
				<p v-if="review.albumID">albumID: {{ review.albumID }}</p>
				<p v-if="review.userID">userID: {{ review.userID }}</p>
				
				<!-- DENNE ER IKKE FERDIG -->
				<div v-if="review.albumCollection" class="albumCollection">			
					<h3 class="subheading" v-show="albumCollection">User Collection</h3>
					<div v-if="review.albumCollection.id">
						<p v-if="review.albumCollection.Butikk">Butikk: {{review.albumCollection.Butikk}}</p>
						<p v-if="review.albumCollection.Ordered">Ordered: {{timeConverter(review.albumCollection.Ordered)}}</p>
						<p v-if="review.albumCollection.Collected">Collected: {{timeConverter(review.albumCollection.Collected)}}</p>
						<p v-if="review.albumCollection.Sum">Sum: {{review.albumCollection.Sum}}</p>
						<p v-if="review.albumCollection.Updated">Update: {{timeConverter(review.albumCollection.Updated)}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<DeleteModal
		v-if="showDeleteModal" 
		class="albumModal"
		@close="closeDeleteModal"
		:review="activeModalReview"
	/>
	<EditReview 
		v-if="showEditModal" 
		class="albumModal"
		@close="closeEditModal"
		:review="activeModalReview"
	/>
	<ReviewComments 
		v-if="showCommentsModal" 
		class="albumModal"
		@close="closeCommentsModal"
		:review="activeModalReview"
	/>
</template>


<script>
import { ref } from 'vue'
// Firebase imports
import DeleteModal from '@/components/modals/DeleteModal.vue'
import EditReview from '@/components/modals/EditReview.vue'
import ReviewComments from '@/components/modals/ReviewComments.vue'
import { auth, db } from '@/firebase/config'
import { formatDistanceToNow } from 'date-fns'
import { arrayRemove, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import StarRating from 'vue-star-rating'

export default{
	components: { DeleteModal, EditReview, ReviewComments, StarRating },
	emits: ['close'],
	props: ['album','reviews', 'user'],
	setup(props) {
		const showAlbum = ref(false)
		const showIDs = ref(false)
		const showDeleteModal = ref(false)
		const showEditModal = ref(false)
		const showCommentsModal = ref(false)
		const activeModalReview = ref(null)
		const user = ref(auth.currentUser)
		const readMore = ref(false)


		const activateReadMore = (review) => {
			if(!review.readMore){
				review.readMore = true
			} else {
				review.readMore = false
			}
		}

		const toggleAlbum = (review) => {
			review.showAlbum = !review.showAlbum
			console.log('toggleAlbum', review)
		}
		const toggelIDs = () => {
			showIDs.value = !showIDs.value
			console.log('toggelIDs: ', showIDs.value)
		}

		// Delete record
		const deleteReview = async (review) => {
			console.log('deleteReview', review)
			console.log('user: ', user)
			let test = confirm('Really want to delete?')
            if (test){
				const docRef = doc(db, 'reviews', review.id)
                await deleteDoc(docRef)

				// UPDATE ALBUM
				const albumRef = doc(db, 'Jazz', review.albumID)
				await updateDoc(albumRef, {
					reviews: arrayRemove(review.reviewID)
				})
				console.log( 'Removed review', review.reviewID, 'from album albumReviews')

				// UPDATE USER
				let userRef = doc(db, 'users', user.id)
				await updateDoc(userRef, {
					reviewIDs: arrayRemove(review.reviewID),
					reviews: arrayRemove(review.albumID)
				})
				console.log(review.albumTitle, review.albumID,  'is removed from user => reviews array:')
            }
		}

		const addComment = (review) => {
			console.log('addComment')
			console.log('review: ', review)

		}

		const openCommentsModal = (review) => {
			console.log('openCommentsModal')
			console.log('Review:', review)
			activeModalReview.value = review
			showCommentsModal.value = true
			showDeleteModal.value = false
			showEditModal.value = false
			console.log('openCommentsModal')
			
		}
		const openEditModal = (review) => {
			console.log('openAlbumModal')
			console.log('Review:', review)
			activeModalReview.value = review
			showCommentsModal.value = false
			showDeleteModal.value = false
			showEditModal.value = true
			
		}
		const openDeleteModal = (review) => {
			console.log('openAlbumModal')
			console.log('Review:', review)
			activeModalReview.value = review
			showCommentsModal.value = false
			showDeleteModal.value = true
			showEditModal.value = false
		}
		const closeEditModal = () => {
			console.log('closeLogin')
			activeModalReview.value = null
			showEditModal.value = false
		}

		const closeDeleteModal = () => {
			console.log('closeLogin')
			activeModalReview.value = null
			showDeleteModal.value = false
		}
		const closeCommentsModal = () => {
			console.log('closeComments')
			activeModalReview.value = null
			showCommentsModal.value = false
		}

		const makeArray = (string) => {
            let array = string.split(', ')
            return array
        }

		const dateConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit"} )
				return newdate
            }
        } 
		const timeConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit", hour:"numeric", minute:"2-digit", second:"2-digit"} )
				return newdate
            }
        } 

		return { 
			dateConverter, timeConverter, makeArray, 
			toggleAlbum, showAlbum, showIDs, toggelIDs, deleteReview, 
			showDeleteModal, openDeleteModal, closeDeleteModal, 
			showCommentsModal, openCommentsModal, closeCommentsModal,
			activeModalReview,
			readMore, activateReadMore, formatDistanceToNow, user, addComment,
			showEditModal, openEditModal, closeEditModal
		}
	}
}
</script>

<style scoped>
.text-class{
	font-weight: 700;
	font-size: 3em;
}
.reviewModule {
	margin-top: 20px;
	padding: 5px;
	display: flex;
	flex-wrap: wrap;
	/* justify-content: space-between;  */
	align-items: stretch;
	gap: 10px;
	background: rgba(200, 200, 200, 0.199); 
}
.reviewContainer{
	background-color: rgba(25, 25, 35, 0.3);
	padding: 10px;
	flex: 0 1 12%;
	flex-grow: 1;
	min-width: 200px;
	max-width: 400px;
}
.reviewContainer h2{
	text-align: center;
}
.vue-star-rating{
	width: 200px;
	display: block;
	margin: auto;
}
.readMore{
	cursor: pointer;
}
.reviewContainer .icons {
	margin: 30px auto;
} 
.iconMore {
	color: #235d8e;
}
.iconComment {
    color: #ad8569;
}
.reviewContainer h3{
	/* margin-bottom: 10px; */
	margin-top: 15px;
	font-size: 1.4em;
	cursor: pointer;
}
.reviewContainer .metadata{
	margin: 20px 0 40px;
	font-style: italic;
}
.reviewContainer .showMore{
	position: absolute;
	bottom: 10px;
	right: 10px;
	padding-top: 20px;
}
.showIDs{
	display: block;
}
.album-cover{
    display: block;
}
.album-cover img{
	width: 100%;
    cursor: pointer;
}
.album-cover img:last-child{
    margin: 0px;
}
.albumBox {
	max-width: 320px;
	min-width: min-content;
	background: #222;
	padding: 20px;
	margin: 10px auto;
	border: 3px sienna;
	box-shadow: 1px 1px 2px #ccc;
	border-radius: 3px;
	overflow: hidden;
}
.albumInfo .subheading{
	cursor: pointer;
    margin-top: 0px;
	font-size: 1.2em;
	text-decoration: none;
	margin: 5px 10px;
}
.subcontent{
	font-size: 1em;
}

/* we will explain what these classes do next! */
.transitionHeader-enter-active,
.transitionHeader-leave-active {
  	transition: all 1s ease-in-out;
}
.transitionHeader-enter-from,
.transitionHeader-leave-to {
  	opacity: 0;
  	transform: translateX(200%);
}
.transitionDates-enter-active,
.transitionDates-leave-active {
	/* transition-delay: 0.5s; */
	transition: all 1s ease-in-out;
}
.transitionDates-enter-from,
.transitionDates-leave-to {
	/* transition-delay: 0.5s; */
  	opacity: 0;
  	transform: translateX(-200%);
}
.transitionMusician-enter-active,
.transitionMusician-leave-active {
	/* transition-delay: 1s; */
	transition: all 1s ease-out;
}
.transitionMusician-enter-from,
.transitionMusician-leave-to {
	/* transition-delay: 0.5s; */
	transform: scale(1%);
	opacity: 0;
  	/* transform: translateX(-200%); */
}
.transitionLinks-enter-active,
.transitionLinks-leave-active {
	/* transition-delay: 1.5s; */
	transition: all 1s ease-in-out;
}
.transitionLinks-enter-from,
.transitionLinks-leave-to {
	/* transition-delay: 1s; */
  	opacity: 0;
	transform: scale(0%);
  	
}
.transitionSongs-enter-active,
.transitionSongs-leave-active {
	/* transition-delay: 2s; */
	transition: all 0.5s ease-in-out;
}
.transitionSongs-enter-from,
.transitionSongs-leave-to {
	/* transition-delay: 0.5s; */
  	opacity: 0;
  	transform: translateX(100%);
}

@media only screen and (max-width: 1800px) {

	.reviewModule{
		grid-template-columns: 1fr 1fr 1fr;
		
	}
}
@media only screen and (max-width: 1300px) {
    .albumBox {
        grid-template-columns: 2fr 1fr;
    }
	.reviewModule{
		grid-template-columns: 1fr 1fr;
		
	}
}
@media only screen and (max-width: 1090px) {
    .albumBox {
        grid-template-columns: 2fr 1fr;
    }
	.reviewModule{
		grid-template-columns: 1fr 1fr;
	}
	.modal {
		width: 300px;
	}
}
@media only screen and (max-width: 900px) {
    .albumBox {
        grid-template-columns: 2fr 1fr;
    }
	.albumContainer{
		grid-template-columns: 1fr 1fr;
	}
	.reviewModule{
		grid-template-columns: 1fr;
	}
}
@media only screen and (max-width: 700px) {
	.albumBox {
		grid-template-columns: 2fr;
    }
	.albumContainer{
		grid-template-columns: 1fr;
	}
}
</style>