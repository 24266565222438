<template>
	<div class="moduleContainer">
		<template v-for="musician in musicians" :key="musician">
			<div class="moduleContent" v-if="artistAlias.includes(musician.name)">
				<div class="musicianBox">
					<h3 class="subheading">{{ musician.name }}</h3>
					<router-link :to="{ name: 'Artist', class: 'artistLink', params: { id: artistIDs[artistAlias.indexOf(musician.name)] } }">
						<img :src="require('@/assets/profiles/' + artistIDs[artistAlias.indexOf(musician.name)] + '.jpg')"
						:alt=" musician.name"
						class="bilde">
					</router-link>
					<template v-for="instrument in musician.instruments" :key="instrument">
						<h4>{{ instrument }}</h4>
					</template>
				</div>
			</div>
			<div class="moduleContent" v-else >
				<div class="musicianBox">
					<h3 class="subheading">{{  musician.name }}</h3>
					<div class="defaultPicture">
						<img :src="require('@/assets/profiles/jazzartist.jpg')"
						:alt=" musician.name"
						class="bilde">
					</div>
					<template v-for="instrument in musician.instruments" :key="instrument">
						<h4>{{ instrument }}</h4>
					</template>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { toKebab } from '@/composables/stringConverter'
export default {
	props: ['album', 'artistNames','artistIDs','artistAlias','musicians'],
	setup(props) {

		return { toKebab }
	}

}
</script>

<style scoped>
	.moduleContent{
		padding: 0;
	}
	.moduleContainer{
		padding-top: 10px;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		justify-content: space-around;
		flex-direction: row;
		 /* max-width: 180px; */
		/* padding: 10px; */
	}
	.musicianBox{	
		max-width: 400px;
		min-width: 180px;
		/* min-height: 200px; */
		background: radial-gradient(circle at 10% 20%, rgba(69, 86, 102, .8) 0%, rgba(34, 34, 34, .6) 90%);
		border-radius: 5px;
		/* padding-top: 10px; */
		flex: 1 0 31%;
		flex-grow: 1;
	}
	.musicianBox h3,
	.musicianBox h4{
		text-align: center;
		color: #eee;
		padding: 3px 0;
		text-shadow: 2px 1px 2px rgba(0,0,0,0.9);
	}
	.musicianBox h4:last-child{
		padding-bottom: 5px;
	}
	.box{
		flex: 1 0 31%;
		flex-grow: 1;
	}
	img{
		width: 150px;
		display: block;
		border-radius: 50%;
		--crop-focus-x: 0.6;
		--crop-focus-y: 0.1;
		width: 150px;
		aspect-ratio: 1 / 1;
		object-fit: cover;
		object-position: calc(var(--crop-focus-x) * 100%) calc(var(--crop-focus-y) * 100%);
		margin: 0 auto;
		box-shadow: 0 0 10px rgba(0,0,0,0.9);
	}
	/* .defaultPicture{

	} */
</style>