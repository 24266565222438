<template>
	<div class="moduleContent">
		<!-- <h3>Biography</h3> -->
		<div class="artistBio">
			<p v-if="artist.bioText && !readMore" v-html="artist.bioText.slice(0, 1000)"></p>
			<a class="readMore" v-if="!readMore" @click="readMore = true">Read more...</a>
			<p v-if="artist.bioText && readMore" v-html="artist.bioText"></p>
			<a class="readMore" v-if="readMore" @click="readMore = false">Read less...</a>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';

export default {
	props: ['artist'],
	setup(props) {
		const readMore = ref(false)

	return { readMore, }
	}

}
</script>

<style scoped>
	.moduleContent{
		background: #6d5cc122;
		box-shadow: 3px 3px 8px #333;
	}
</style>