<template>
<div class="loginBox">
  <form @submit.prevent="handleSubmit">
    <h2>Sign Up</h2>

    <label for="email">Email:</label>
    <input id="email" type="email" name="email" v-model="email" autocomplete="off" required>

    <label for="password">Password:</label>
    <input id="password" type="password" name="password" v-model="password" autocomplete="off" required>
    
    <label for="displayname">Displayname:</label>
    <input id="displayname" type="text" name="displayname" v-model="displayname" autocomplete="off" required>

    <button>Sign Up</button>
    <div v-if="error">{{ error }}</div>
  </form>
</div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useSignup from '@/composables/useSignup'

// Firebase import
import { db } from '@/firebase/config'
import { serverTimestamp, doc, setDoc } from 'firebase/firestore'

export default {
  setup() {
    const email = ref('')
    const password = ref('')
    const displayname = ref('')
    // const admin = ref(false)

    const { signup, error, UID } = useSignup()
    const router = useRouter()

    const handleSubmit = async () => {
      await signup(email.value, password.value, displayname.value)

      // Lag bruker (med Auth ID) i users collection
      const docRef = doc(db, 'users', UID.value )
      await setDoc(docRef, {
		  admin: false,
		  albumPosts: [],
		  blogPosts: [],
		  collected: [],
		  created: serverTimestamp(),
		  email: email.value,
		  favorites: [],
		  name: displayname.value,
		  password: password.value,
		  pictures: [],
      })

      if (!error.value) {
        router.push('/')
      }
    }

    return { email, password, displayname, handleSubmit, error }
  }
}
</script>

<style scoped>
form label, form input, form select {
  display: block;
}
form label{
    color: #fff;
    font-size: 18px;
}
form input,
form select {
  padding: 6px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 8px;
}
form input:focus, 
form input:active{
    outline: 3px solid #bbb;
}
button {
  background: #bbb;
  color: #333;
  border: 0;
  border-radius: 6px;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 20px;
}
.loginBox{
    background: #333;
    padding: 30px;
    margin: 30px;
    box-shadow: 0 0 20px;
    border-radius: 10px;
}
.loginBox h2{
    color: #fff;
    margin-bottom: 10px;
    font-size: 24px;
}
</style>