<template>
	<div class="moduleContent">
		<!-- <h3 v-if="album.Artist">{{ album.Artist }}</h3>
		<h3 v-if="album.Album">{{ album.Album }} ({{ album.Year }})</h3> -->
		<star-rating
			v-if="album.ratingAverage"
			class="avgReview"
			v-bind:star-size="25"
			:increment="0.01"
			:glow="2" 
			glow-color="#ffd055"
			text-class="avg-star-text"
			:padding="0"
			:animate="false"
			:read-only="true"
			:show-rating="true"
			inactive-color="#212121"
			v-model:rating="album.ratingAverage">
		</star-rating>
		<div>
			<p v-if="album.ratingCount">Total Ratings <span class="strong">{{album.ratingCount}}</span></p>
			<p v-if="album.Series">Series <span class="strong">{{album.Series}}</span></p>
			<p v-if="album.Label">Label <span class="strong">{{album.Label}}</span></p>
			<p v-if="album.CatalogID">Catalog <span class="strong">{{album.CatalogID}}</span></p>
			<p v-if="album.Discogs" >Discogs <span class="strong">{{album.DiscogsID}}</span></p>
		</div>
		<div v-if="album.Recorded1">
			<p v-if="album.Recorded1">Recorded
				<span class="strong">{{timestampToUserLanguage(album.Recorded1)}}</span><span v-if="album.Recorded2">, </span>
				<span class="strong">{{timestampToUserLanguage(album.Recorded2)}}</span><span v-if="album.Recorded3">, </span>
				<span class="strong">{{timestampToUserLanguage(album.Recorded3)}}</span><span v-if="album.Recorded4">, </span>
				<span class="strong">{{timestampToUserLanguage(album.Recorded5)}}</span>
			</p>
		</div>
		<p v-if="album.Release">Vinyl release date <span class="strong">{{timestampToUserLanguage(album.Release)}}</span></p>
		 <div class="metadata">
			 <p v-if="!album.timeUpdated">Added <span class="metastrong">{{album.timeCreated}}</span> ago</p>
			 <p v-else>Updated <span class="metastrong">{{album.timeUpdated}}</span> ago</p>
		</div>
		<div class="iconbar" v-if="user.id != null">
			<div class="tooltip">
				<span :class="{ icon: true, iconFavorite: user.favorites.includes(album.id)}"
					@click="userFavorite(user, album.id)">
					<span class="material-icons">favorite</span>
					<span v-if="user.favorites.includes(album.id)" class="tooltiptext">Remove Favorite</span>
					<span v-if="!user.favorites.includes(album.id)" class="tooltiptext">Add Favorite</span>
				</span>
			</div>
			<div class="tooltip" @mouseover="hover">
				<span :class="{ icon: true, iconCollect: user.collected.includes(album.id)}" 
					@click="showAddCollection = true">
					<span class="material-icons">album</span>
					<span v-if="!user.collected.includes(album.id)" class="tooltiptext">Add to Collection</span>
					<span v-if="user.collected.includes(album.id)" class="tooltiptext">Edit Collection</span>
				</span>
			</div>
			<div class="tooltip">
				<span :class="{ icon: true, iconReview: user.reviews.includes(album.id)}"
					@click="showAddReview = true">
					<span class="material-icons">reviews</span>
					<span v-if="!user.reviews.includes(album.id)" class="tooltiptext">Add Rating</span>
					<span v-if="user.reviews.includes(album.id)" class="tooltiptext">Edit Review</span>
				</span>
			</div>
			<div class="tooltip">
				<span v-if="user.admin">
					<router-link :to="{ name: 'EditAlbum', params: { id: album.id } }">
						<span class="material-icons icon iconEdit">edit</span>
						<span class="tooltiptext">Edit Album</span>
					</router-link>
				</span>
			</div>
			<div class="tooltip">
				<span :class="{ icon: true, iconPlay: showAlbumPlayer == true }">
					<span @click="$emit('player')" class="material-icons">play_circle</span>
					<span class="tooltiptext">Play Album</span>
				</span>
			</div>
			<div class="tooltip">
				<span :class="{ icon: true, iconPlaylist: true}" id="iconPlaylist" v-if="user">
					<span @click="addToPlaylist(album, user.id)" class="material-icons">playlist_add</span>
					<span class="tooltiptext">Add to Playlist</span>
				</span>
			</div>
			<br>
		</div>
	</div>
	<AddReview
		v-if="showAddReview"
		@close="showAddReview = false"
		@new-review ="(data, user) => newReview(data, user)"
		@update-review ="(data, user) => updateReview(data, user)"
		@delete-review ="(data, user) => deleteReview(data, user)"
		:album="album"
		:user="user"
	/>
	<AddCollection
		v-if="showAddCollection"
		@close="showAddCollection = false"
		@new-collected ="(data, user) => newCollected(data, user)"
		@update-collected ="(data, user) => updateCollected(data, user)"
		@delete-collected ="(data, user) => deleteCollected(data, user)"
		:album="album"
		:user="user"
	/>
</template>

<script>
import { ref } from 'vue';
import StarRating from 'vue-star-rating'

import { timestampToUserLanguage } from '@/composables/timestampConverter';
import { userFavorite } from '@/composables/userFavorite';
import { addToPlaylist } from '@/composables/addToPlaylist';

import AddReview from '@/components/modals/AddReview.vue';
import AddCollection from '@/components/modals/AddCollection.vue';

export default {
	props: ['album','user','userCollected','userReview','showAlbumPlayer'],
	emits: ['new-review','update-review','delete-review','new-collected','update-collected','delete-collected','player'],
	components: { StarRating, AddReview, AddCollection },
	setup(props, context) {
		const showAddReview = ref(false)
		const showAddCollection = ref(false)
		const showCommentsModal = ref(false)
		// EMITS
		const newCollected = (data, user) => {
			context.emit('new-collected', data, user)
		}
		const updateCollected = (data, user) => {
			context.emit('update-collected', data, user)
		}
		const deleteCollected = (data, user) => {
			context.emit('delete-collected', data, user)
		}
		const newReview = (data, user) => {
			context.emit('new-review', data, user)
		}
		const updateReview = (data, user) => {
			context.emit('update-review', data, user)
		}
		const deleteReview = (data, user) => {
			context.emit('delete-review', data, user)
		}

	return { 
		timestampToUserLanguage, userFavorite, addToPlaylist, 
		showAddReview, showAddCollection, showCommentsModal, 
		newReview, updateReview, deleteReview, newCollected, updateCollected, deleteCollected, 
	 }
	}

}
</script>

<style scoped>
	.avgReview{
		font-size: 12px;
		font-weight: 100;
		justify-content: left;
		margin: 15px auto 15px 0px;
	}
	.iconbar{
		text-align: center;
		padding: 10px 0;
	}
</style>