import { db } from '@/firebase/config';
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';

export const userFavorite = async (user, albumId) => {
    const docRef = doc(db, 'users', user.id)
    const include = user.favorites.includes(albumId)
    const index = user.favorites.indexOf(albumId)
    if (include) {
        user.favorites.splice(index, 1)
        await updateDoc(docRef, {
            favorites: arrayRemove(albumId)
        })
    }
    else {
        user.favorites.push(albumId)
        await updateDoc(docRef, {
            favorites: arrayUnion(albumId)
        })
    }
    console.log('%cFavorites', 'color:red;background:white;font-size:1.2em', user.favorites.length, user.favorites)
	return user.favorites
}