<template>
	<div class="moduleContent">
		<p v-if="userCollected.Butikk">Store <span class="strong">{{userCollected.Butikk}}</span></p>
		<p v-if="userCollected.Ordered">Ordered <span class="strong">{{timestampToUserLanguage(userCollected.Ordered)}}</span></p>
		<p v-if="userCollected.Collected">Collected <span class="strong">{{timestampToUserLanguage(userCollected.Collected)}}</span></p>
		<p v-if="userCollected.Sum">Cost <span class="strong">{{userCollected.Sum}}</span></p>
		<div class="metadata">
			<p v-if="userCollected.timeCreated">Added <span class="metastrong">{{ userCollected.timeCreated}}</span> ago</p>
			<p v-if="userCollected.timeUpdated">Updated <span class="metastrong">{{ userCollected.timeUpdated }}</span> ago</p>
		</div>
	</div>
</template>

<script>
import { timestampToLongdate, timestampToUserLanguage } from '@/composables/timestampConverter';

export default {
	props: ['album', 'userCollected'],
	setup(props) {
		console.log('%c AlbumCollection', 'color:dodgerblue', props.userCollected)
		
	return { timestampToLongdate, timestampToUserLanguage }
	}

}
</script>