<template>
	<div class="apple-music">
		<iframe :src="album.Apple" loading="lazy" allow="autoplay *; encrypted-media *;" frameborder="0" height="450" style="width:100%;max-width:660px;overflow:hidden;background:transparent;" 
			sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation">
			{{album.Apple}}
		</iframe>
	</div>
</template>

<script>
export default {
	props: ['album'],
	setup(props) {

	return {}
	}

}
</script>

<style scoped>
	.apple-music{
		display: grid;
		grid-template-columns: 1fr;
	}
	.apple-music iframe{
		width: 100%;
		margin: 0 auto;
	}
</style>