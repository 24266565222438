<template>
	<h1>Userdata</h1>
	<h2>Collection</h2>
	<pre>{{ userData }}</pre>
</template>

<script>
import { auth, db } from '@/firebase/config';
import { onMounted, ref, toRaw } from 'vue';

	export default {
		setup() {

			const userData = ref() 
			const userAuth= ref()

			const getUser = async () => {

			}

			onMounted(() => {
		
			})

			return { getUser, userData, userAuth }
		}
	}
</script>