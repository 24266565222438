// Firebase import
import { auth, db } from '@/firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { collection, doc, getDoc, getDocs, or, orderBy, query, where } from 'firebase/firestore'



export const fetchArtistAlbums = async (name) => {
	console.log('getMusicianAlbums', name)
	if (name.length >= 2){
		let artistOnAlbums = []
		let musicianOnAlbums = []
		const colRef = collection(db, 'Jazz')
		let queryRef = query(colRef,
			or(
				// where('Artist','==', name),
				where('artistArray','array-contains-any', [name]),
				// where('Artist','<=', name + '\uf8ff'),
				where('musicianBassArr','array-contains-any', [name]),
				where('musicianClarinetArr','array-contains-any', [name]),
				where('musicianCongaArr','array-contains-any', [name]),
				where('musicianCornetteArr','array-contains-any', [name]),
				where('musicianDrumsArr','array-contains-any', [name]),
				where('musicianEuphoniumArr','array-contains-any', [name]),
				where('musicianFluteArr','array-contains-any', [name]),
				where('musicianGuitarArr','array-contains-any', [name]),
				where('musicianHarpArr','array-contains-any', [name]),
				where('musicianHornsArr','array-contains-any', [name]),
				where('musicianOrganArr','array-contains-any', [name]),
				where('musicianPercussionArr','array-contains-any', [name]),
				where('musicianPianoArr','array-contains-any', [name]),
				where('musicianSaxAltoArr','array-contains-any', [name]),
				where('musicianSaxBarytonArr','array-contains-any', [name]),
				where('musicianSaxSopranoArr','array-contains-any', [name]),
				where('musicianSaxTenorArr','array-contains-any', [name]),
				where('musicianSitarArr','array-contains-any', [name]),
				where('musicianStringArr','array-contains-any', [name]),
				where('musicianTromboneArr','array-contains-any', [name]),
				where('musicianTrumpetArr','array-contains-any', [name]),
				where('musicianTubaArr','array-contains-any', [name]),
				where('musicianVibraphoneArr','array-contains-any', [name]),
				where('musicianVocalArr','array-contains-any', [name]),
			),
			orderBy('Recorded1', 'asc'),
		)
		const querySnapshot = await getDocs(queryRef);
		querySnapshot.forEach((doc) => {
			let data = {id: doc.id, ...doc.data()}
			if(data.Artist.includes(name) || data.Artist === name){
				artistOnAlbums.push(data)
			} else {
				musicianOnAlbums.push(data)
			}
		})
		console.log('%c artistOnAlbums ', 'color:goldenrod', artistOnAlbums.length, artistOnAlbums)
		console.log('%c musicianOnAlbums ', 'color:gold', musicianOnAlbums.length, musicianOnAlbums)
		return {artistOnAlbums, musicianOnAlbums}
	}
}