<template>
	<div class="moduleContent">
		<p v-if="album.Song01">1 <span class="strong">{{ album.Song01 }}</span></p>
		<p v-if="album.Song02">2 <span class="strong">{{ album.Song02 }}</span></p>
		<p v-if="album.Song03">3 <span class="strong">{{ album.Song03 }}</span></p>
		<p v-if="album.Song04">4 <span class="strong">{{ album.Song04 }}</span></p>
		<p v-if="album.Song05">5 <span class="strong">{{ album.Song05 }}</span></p>
		<p v-if="album.Song06">6 <span class="strong">{{ album.Song06 }}</span></p>
		<p v-if="album.Song07">7 <span class="strong">{{ album.Song07 }}</span></p>
		<p v-if="album.Song08">8 <span class="strong">{{ album.Song08 }}</span></p>
		<p v-if="album.Song09">9 <span class="strong">{{ album.Song09 }}</span></p>
		<p v-if="album.Song10">10 <span class="strong">{{ album.Song10 }}</span></p>
		<p v-if="album.Song11">11 <span class="strong">{{ album.Song11 }}</span></p>
		<p v-if="album.Song12">12 <span class="strong">{{ album.Song12 }}</span></p>
		<p v-if="album.Song13">13 <span class="strong">{{ album.Song13 }}</span></p>
		<p v-if="album.Song14">14 <span class="strong">{{ album.Song14 }}</span></p>
		<p v-if="album.Song15">15 <span class="strong">{{ album.Song15 }}</span></p>
		<p v-if="album.Song16">16 <span class="strong">{{ album.Song16 }}</span></p>
		<p v-if="album.Song17">17 <span class="strong">{{ album.Song17 }}</span></p>
		<p v-if="album.Song18">18 <span class="strong">{{ album.Song18 }}</span></p>
		<p v-if="album.Song19">19 <span class="strong">{{ album.Song19 }}</span></p>
		<p v-if="album.Song20">20 <span class="strong">{{ album.Song20 }}</span></p>
	</div>
</template>

<script>
export default {
	props: ['album']
}
</script>

<style scoped>
	.moduleContent {
		max-height: 220px;
		overflow-y: auto;
	}
	.moduleContent p {
		height: auto;
		/* font-size: 1em; */
		line-height: 1.6;
	}
	.moduleContent p:last-child {
		padding-bottom: 10px;
	}
</style>