<template>
	<div class="moduleContent">
		<h3 v-if="album.Review"><a :href="album.Review" target="blank">Allmusic</a></h3>
		<h3 v-if="album.Amazon"><a :href="album.Amazon" target="blank">Amazon</a></h3>
		<h3 v-if="album.Discogs"><a :href="album.Discogs" target="blank">Discogs</a></h3>
		<h3 v-if="album.Play"><a :href="album.Play" target="blank">Release Links</a></h3>
		<h3 v-if="album.SeriesLink"><a :href="album.SeriesLink" target="blank">Series</a></h3>
		<h3 v-if="album.Wiki"><a :href="album.Wiki" target="blank">Wikipedia</a></h3>
	</div>
</template>

<script>
export default {
	props: ['album'],
	setup(props) {


	return {}
	}

}
</script>

<style scoped>
	.moduleContent h3 {
		line-height: 1em;
		font-size: 18px;
		background: none;
		padding: 5px 0;
	}
	.moduleContent a {
		/* color: #eee; */
	}
</style>