<template>
	<div class="moduleContent">
		<div class="moduleContainer">		
			<template v-for="album in artistAlbums" :key="album.id">
				<div class="box">
					<router-link :to="{ name: 'Album', class: 'albumLink', params: { id: album.id } }">
						<img
						:src="require('@/assets/covers/' + album.albumID + '.jpg')" 
						:alt="album.Album" loading="lazy"
						:class="{ bilde: true }"
						@click="clickImage(album)">
					</router-link>
					<h3>{{ album.Album }} ({{ album.Year }})</h3>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { timestampToYear } from '@/composables/timestampConverter';
import { useRouter } from 'vue-router';

export default {
	emits: ['new-album'],
	props: ['artistAlbums'],
	setup(props, context) {
		const router = useRouter()
		// const toggleModal = () => {
		// 	context.emit('close')
		// 	console.log(props.review)
		// }

		const clickImage = (album) => {
			context.emit('new-album', album)
			// console.log(props.review)
			console.log('album: ', album)	
			// let route = `/album/${album.id}`
			// router.push(route)
			// router.go(`/album/${album.id}`)
			
		}

 	return { router, clickImage, timestampToYear }
	}

}
</script>

<style scoped>
	.moduleContent{
		background: #6d5cc120;
		box-shadow: 3px 3px 8px #333;
	}
	.box img{
		width: 100%;
		/* box-shadow: 3px 3px 8px #333; */
	}
	.moduleContainer{
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		flex-direction: row;
		justify-content: space-around;
	}
	.box{	
		background: transparent;
		/* margin: 10px; */
		max-width: 300px;
		min-width: 150px;
		/* min-height: 200px; */
		flex: 1 0 32%;
		flex-grow: 4;
	}
	.box h3{
		text-align: center;
		font-size: 1em;
		padding: 5px 0;
		/* text-shadow: 2px 1px 2px rgba(0,0,0,0.3); */
	}
	.activeAlbum {
		/* border: 5px solid #dcb; */
		box-shadow: 0 0 10px blanchedalmond;
	}
</style>