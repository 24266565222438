<template>
	<div class="moduleContent">
		<div v-show="artist.birth" class="info">
			<h3>Life</h3>
			<p v-if="artist.birth">Born {{ timestampToLongdate(artist.birth) }}</p>
			<p v-if="artist.death">Died {{ timestampToLongdate(artist.death) }} ({{ timestampToTotalYears(artist.birth, artist.death) }} Years)</p>		
		</div>
		<br>
		<div v-show="artist.instrument" class="instruments">
			<h3 >Instruments</h3>
			<ul v-if="artist.instrument" class="instruments">
				<li v-for="instrument in makeArray(artist.instrument)" :key="instrument">
					<span>{{instrument}}</span>
				</li>
			</ul>
		</div>
		<br>
		<div class="links">
			<h3>Links</h3>
			<a :href="artist.bioAmg" target="blank">Allmusic</a><br>
			<a :href="artist.bioWiki" target="blank">Wikipedia</a><br>
		
			<div class="tooltip">
				<span v-if="user.admin">
					<router-link :to="{ name: 'EditArtist', params: { id: artist.artistID } }">
						<span class="material-icons icon iconEdit">edit</span>
						<span class="tooltiptext">Edit Artist</span>
					</router-link>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { makeArray } from '@/composables/makeArray';
import { timestampToLongdate, timestampToTotalYears, timestampToYear } from '@/composables/timestampConverter';
export default {
	props: ['artist', 'user'],
	setup(props) {
		

	return { timestampToLongdate, timestampToTotalYears, timestampToYear, makeArray }
	}

}
</script>

<style scoped>
	.moduleContent{
		background: #6d5cc120;
		box-shadow: 3px 3px 8px #333;
	}
	.instruments{
		list-style-type: none;
	}
</style>