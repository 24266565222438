<template>
	<div>
		<nav touchmove class="topnav" id="myTopnav">
			<router-link 
				:to="{ name: 'Home'}">Home
				<span class="material-icons">home</span>
			</router-link>
			<router-link
				:to="{ name: 'Artists' }">Artists
				<span class="material-icons">groups</span>
			</router-link>
			<router-link
				v-if="authUser"
				:to="{ name: 'Playlist' }">Playlist
				<span class="material-icons">queue_music</span>
			</router-link>
			<router-link
				:to="{ name: 'Reviews' }">Reviews
				<span class="material-icons">reviews</span>
			</router-link>			
			<a @click="showDonate = true" class="donateButton">Donate
				<span class="material-icons">attach_money</span>
			</a>
			<!-- User Meny -->
			<div class="dropdown userMenu" id="myDropdown" v-if="authUser" @click="toggleDropdown">
				<div class="dropbtn">{{ user.name }}
					<span class="material-icons">person</span>
				</div>
				<div class="dropdown-content" v-if="showDropdown">
					<router-link
						v-if="user.admin && authUser"
						:to="{ name: 'Admin' }">Admin
						<span class="material-icons">admin_panel_settings</span>
					</router-link>
					<router-link
						v-if="authUser"
						:to="{ name: 'User' }">User
						<span class="material-icons">bar_chart</span>
					</router-link>
					<router-link
						v-if="user.admin && authUser"
						:to="{ name: 'AddAlbum' }">Add Album
						<span class="material-icons">add</span>
					</router-link>
					<router-link
						v-if="user.admin && authUser"
						:to="{ name: 'AddArtist' }">Add Artist
						<span class="material-icons">add</span>
					</router-link>
					<router-link
						v-if="authUser"
						:to="{ name: 'Reviews' }">Reviews
						<span class="material-icons">reviews</span>
					</router-link>
					<a @click="$emit('user-logout')" class="logoutUser">Logout<span class="material-icons">logout</span></a>
				</div>
			</div>
			<div v-else>
				<a @click="$emit('user-login')" class="dropdown userMenu loginMenu">Log In &nbsp;<span class="material-icons">login</span></a>
			</div>
			<a class="icon hamburger" @click="responsiveNav">
				<span class="material-icons">menu</span>
			</a>
		</nav>
	</div>
	<ModalDonate 
		v-if="showDonate" 
		@close="showDonate = false" 
	/>
</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import ModalDonate from '@/components/modals/ModalDonate.vue'

export default {
    components: { ModalDonate },
	props: ['user', 'authUser'],
	emits: ['user-login', 'user-logout'],
    setup(props, context){
        const router = useRouter()
        const showDonate = ref(false)
        const showDropdown = ref(false)

		const toggleDropdown = () => {
			if(showDropdown.value){
				showDropdown.value = false
			} else {
				showDropdown.value = true
			}
		}

        // Toggle the responsive class for the topnav
        const responsiveNav = () => {
			var x = document.getElementById("myTopnav");
			if (x.className === "topnav") {
				x.className += " responsive";
			} else {
				x.className = "topnav";
			}
        }
		const toogleDarkmode = () => {
			// If the OS is set to dark mode...
			if (prefersDarkScheme.matches) {
				// ...then apply the .light-theme class to override those styles
				document.body.classList.toggle("light-theme");
				// Otherwise...
			} else {
				// ...apply the .dark-theme class to override the default light styles
				document.body.classList.toggle("dark-theme");
			}
		}

        return { toggleDropdown, showDropdown, showDonate, responsiveNav, toogleDarkmode, router  }
    }
}
</script>

<style scoped>
.donateButton,
.loginMenu{
	cursor: pointer;
}
.modal{
	margin: 10px 30px;
}
.icons{
    text-align: center;
    padding: 0;
}
.icons .icon {
	cursor: pointer;
	transform-origin: 50% 50%;
	width: 50px;
	height: 50px;
	display: block;
}
.icon.hamburger{
	margin-right: 0px;
	margin: auto;
	width: 50px;
	height: fit-content;
	transform-origin: 50% 50%;
}
.icon.hamburger:hover{
		color: #121212;
		background: none;
		font-size: 1.8em;
	}
.hamburger span{
	position: absolute;
	right: 0px;
	top: 0;
}
.darkIcon{
	/* margin-top: 12px; */
	margin: 15px 8px 0 8px;
	color: #eee;
	width: 24px;
	height: 24px;
	text-align: center;
	padding: 0;
	display: inline-block;
	line-height: 1;
	/* float: right; */
}
/* Add a black background color to the top navigation */
.topnav {
	background-color: #333;
	overflow: hidden;
	width: 100vw;
	/* height: 50px; */
}
/* Style the links inside the navigation bar */
.topnav a {
	float: left;
	display: block;
	color: #eee;
	padding: 10px 10px 10px 0px;
	text-align: left;
	text-decoration: none;
	font-size: 1.4em;
	transition: all 0.8s ease-in-out;
	/* height: 100%; */
}
.topnav a span,
.dropbtn span {
	font-size: 1.4em;
	margin-top: 0px;
	float: left;
	margin-left: 10px;
}
/* Add an active class to highlight the current page */
nav a.router-link-exact-active {
	/* background-color: #006bc3aa; */
	/* color: white; */
	background-color: #ddd;
	color: #222;
	font-weight: 900;
}
.material-icons{
	font-size: 24px;
	padding: 0;
	line-height: 1;
	max-height: 30px;
}
/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
	display: none;
	font-size: 26px;
	padding: 10px 3px 6px 10px;
	width: 42px;
}
/* Dropdown container - needed to position the dropdown content */
.dropdown {
	float: left;
	overflow: hidden;
}
/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
	border: none;
	outline: none;
	color: white;
	padding: 10px;
	background-color: inherit;
	font-family: inherit;
	margin: 0;
	/* float: left; */
	display: block;
	color: #eee;
	/* text-align: left; */
	text-decoration: none;
	font-size: 1.4em;
	transition: all 0.8s ease-in-out;
	height: 50px;
}
/* Style the dropdown content (hidden by default) */
.dropdown-content {
	display: none;
	position: absolute;
	background-color: #2d2d2d;
	/* min-width: 180px; */
	width: 100%;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	/* margin-top: 20px; */
}
/* Style the links inside the dropdown */
.dropdown-content a {
	float: none;
	color: #eee;
	/* padding: 12px 16px; */
	text-decoration: none;
	display: block;
	text-align: left;
	font-weight: 300;
}
.dropdown-content span {
	padding: 0 10px;
	text-align: right;
}
/* Add a dark background on topnav links and the dropdown button on hover */
.topnav a:hover {
	background-color: #bbb;
	color: #222;
	font-weight: 700;
}
.dropdown-content a:hover{
	background: none;
	color: #222;
	font-weight: 700;
}
.dropdown.userMenu{
    font-weight: 700;
    float: right;
	margin-right: 20px;
}
.logoutUser,
.dropbtn {
    cursor: pointer;
}
.dropbtn span{
	font-size: 2em;
	padding-top: 8px;
	line-height: 10px;
	margin: 0 auto;
}
/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  	display: block;
}
@media screen and (max-width: 870px){
	.topnav a{
		font-size: 95%;
		height: 95%;
	}
	.dropbtn span{
		margin-top: -4px;
	}
	.dropdown .dropbtn{
		font-size: 95%;
		height: 95%;
	}
}
@media screen and (max-width: 700px){
	.topnav a{
		font-size: 80%;
		height: 80%;
	}
	.dropbtn span{
		margin-top: -6px;
	}
	.dropdown .dropbtn{
		font-size: 80%;
		height: 80%;
	}
}
/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 600px) {
  	.topnav a:not(:first-child), .dropdown .dropbtn, .topnav .userMenu {
    	display: none;
  	}
  	.topnav a.icon {
		float: right;
		display: block;
  	}
	  .topnav a{
		font-size: 100%;
		height: 100%;
	}
	.dropbtn span{
		margin-top: -6px;
	}
	.dropdown .dropbtn{
		font-size: 100%;
		height: 100%;
	}
}
/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 600px) {
  	.topnav.responsive {position: relative;}
  	.topnav.responsive a.icon {
		position: absolute;
		right: 0;
		top: 0;
  	}
	.dropdown.responsive a,
	.topnav.responsive a {
		float: none;
		display: block;
		text-align: left;
		padding-left: 20px;
	}
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-content {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
		display: block;
		width: 100vw;
		text-align: left;
  	}
  .topnav.responsive .userMenu{
		font-weight: 500;
		float: none;
		display: block;
		width: 100%;
		text-align: left;
	}
}
.donate{
    z-index: 99;
}

.fade-enter-active .modal{
	/* opacity: 1; */
	animation: bounce-in 1s;
}
.fade-leave-active .modal{
	animation: bounce-in 10.5s;
}
.fade-enter-from,
.fade-leave-to{
	opacity: 0;
}

</style>