import { db } from "@/firebase/config"
import { doc, getDoc } from "firebase/firestore"

export const getUserCollected = async (albumId, userId) => {
	console.log('%c getUserCollected ','color:#c8c;font-size:1.2em', albumId, userId)
				// 	console.log('%c User favorites ', 'color:gold;font-size:1.2em', favorites)
			// 	console.log('%c User collected ', 'color:gold;font-size:1.2em', collected)
			// 	console.log('%c User reviews ', 'color:gold;font-size:1.2em', reviews)
	try {
		const collectedRef = doc(db, 'users', userId, "collection", albumId)
		const collectDoc = await getDoc(collectedRef)
		if (collectDoc.exists()){
			let data = {...collectDoc.data()}
			data.albumId = collectDoc.id
			console.log('getUserCollected: ', data)
			return data

		} else {
			return {}
		}
	}
	catch(error) {
		console.log('error: ', error)
	}
}