import { ref, unref, watchEffect } from 'vue'
import { db } from '../firebase/config'
import { collection, onSnapshot, orderBy, query, where, limit, doc, getDocs  } from 'firebase/firestore'
import { formatDistanceToNow } from 'date-fns'

export const fetchReviews = async () => {
	let timeCreated, timeUpdated
	const reviews = ref([])
	const reviewRef = query(collection(db, "reviews"), orderBy('albumTitle'), limit(500))
	const reviewsSnap = await getDocs(reviewRef)
	reviewsSnap.forEach(review => {
		if(review.data().created){
			timeCreated = formatDistanceToNow(review.data().created.toDate())
		}
		if(review.data().updated){
			timeUpdated = formatDistanceToNow(review.data().updated.toDate())
		}
		reviews.value.push({
			reviewID: review.id, 
			...review.data(), 
			timeCreated: timeCreated, 
			timeUpdated: timeUpdated
		})
	})
	console.log('%c fetchReviews', 'color:yellow', reviews)

	return { reviews }
}