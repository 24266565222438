<template>
  	<teleport to="#modals">
		<Transition name="fade" appear>
			<div class="modal-overlay" @click.self="toggleModal" aria-label="Close modal">
				<div :class="{ modal: true, shadowHigh: !isHovered, shadowMedium: isHovered}"
					role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false">
					<header class="modal-header" id="modalTitle">
						<!-- <h1 v-if="review.albumData.Artist"> {{ review.albumData.Artist }}</h1>
						<h2 v-if="review.albumData.Album"> {{ review.albumData.Album }} ({{ review.albumData.Year }})</h2> -->
					</header>
					<section class="modal-body" id="modalDescription">
						<div class="modal-preview">
							<star-rating
								:increment="0.5"
								:star-size="30"
								:padding="2"
								:animate="true"
								v-model:rating="review.reviewRating">
							</star-rating>
							<h2 v-if="review.reviewHeader">{{ review.reviewHeader }}</h2>
							<!-- <p class="reviewBody" v-if="review.reviewBody">{{ review.reviewBody }}</p> -->
							<div v-if="review.reviewBody" class="reviewBody">
								<p v-if="!review.readMore && review.reviewBody" class="reviewBody" >{{ review.reviewBody.slice(0, 200) }}</p>
								<a class="readMore" v-if="!review.readMore && review.reviewBody.length > 200" @click="activateReadMore(review)">Read more...</a>
								<p v-if="review.readMore && review.reviewBody" class="reviewBody" v-html="review.reviewBody"></p>
								<a class="readMore" v-if="review.readMore && review.reviewBody.length > 200" @click="activateReadMore(review)">Show less ...</a>
							</div>
							<div class="metadata">
								<p v-if="review.userName">Written by <strong>{{ review.userName }}</strong> {{ timeConverter(review.created) }}</p>
							</div>
						</div>
					</section>
					<section class="allComments">
						<div v-for="comment in commentsCollection" :key="comment.commentID">
							<div class="commentsBox">
								<h3 v-if="comment.reviewComment">{{ comment.reviewComment }}</h3>
								<!-- <p>{{ timeConverter(comment.created) }}</p> -->
								<p v-if="comment.timeCreated">Created <strong>{{ comment.timeCreated }}</strong> ago</p>
								<p v-if="comment.timeUpdated">Updated <strong>{{ comment.timeUpdated }}</strong> ago</p>
								<!-- <p>{{ comment.userID }}</p> -->
								<p v-if="comment.userName">Username <strong>{{ comment.userName }}</strong></p>
								<div class="icons">				
									<!-- <div class="tooltip iconEdit">
										<div @click="editComment(comment)"><span class="material-icons">edit</span><span class="tooltiptext">Edit Review</span></div>
									</div> -->
									<div class="tooltip iconDelete">
										<div @click="deleteComment(comment)"><span class="material-icons">delete</span><span class="tooltiptext">Delete Comment</span></div>
										<!-- <div @click="deleteReview(review)"><span class="material-icons">delete</span><span class="tooltiptext">Delete Review</span></div> -->
									</div>
									<div class="tooltip iconEdit">
										<div @click="openMiniModal(comment)"><span class="material-icons">border_color</span><span class="tooltiptext">Edit Comment</span></div>
										<!-- <div @click="deleteReview(review)"><span class="material-icons">delete</span><span class="tooltiptext">Delete Review</span></div> -->
									</div>
								</div>
							</div>
						</div>
					</section>
					<br>
					<section class="newComment" >
						<form  @submit.prevent="addComment(review)">
							<label for="reviewComment">Add your Comment to the review</label>
							<textarea type="text" id="reviewComment" v-model="review.reviewComment"></textarea>
							<button>Add comment</button>
						</form>
					</section>

					<!-- Close button -->
					<span @click="toggleModal" class="close-btn material-icons" aria-label="Close modal">cancel</span>
					
				</div>
			</div>
		</Transition>
	</teleport>
	<MiniModal
		v-if="showDeleteModal" 
		class="albumModal"
		@close="closeMiniModal"
		:review="activeModalReview"
	/>
</template>

<script>
import { onMounted, ref } from 'vue'
import { auth, db } from '@/firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, collection, addDoc, updateDoc, deleteDoc, serverTimestamp, deleteField, setDoc, query, orderBy, limit, getDocs, arrayUnion, arrayRemove } from 'firebase/firestore'
import StarRating from 'vue-star-rating'
import { formatDistanceToNow } from 'date-fns'

import MiniModal from '@/components/modals/MiniModal.vue'

export default {
	components: { MiniModal, StarRating },
	emits: ['close'],
	props: ['album','review','user'],
	setup(props, context) {
		const isHovered = ref(false)
		const readMore = ref(false)
		const showDeleteModal = ref(false)
		const activeModalReview = ref(null)

		const commentsCollection = ref([])

		const getComments = async () => {
			console.log('Comment review: ', props.review)
			const user = ref(auth.currentUser)
			const uid = user.value.uid
			console.log('Review id: ', props.review.reviewID)
			const commmentRef = query(collection(db, "reviews", props.review.reviewID, "comments"), orderBy('created'), limit(500))
			const reviewsSnap = await getDocs(commmentRef);
			let timeCreated
			let timeUpdated
			reviewsSnap.forEach(element => {
				// console.log('element: ', element.id, "=>", element.data())
				if(element.data().created){
					timeCreated = formatDistanceToNow(element.data().created.toDate())
				}
				if(element.data().updated){
					timeUpdated = formatDistanceToNow(element.data().updated.toDate())
				}
				commentsCollection.value.push({commentID: element.id, ...element.data(), timeCreated, timeUpdated })
				// allReviewsCollection.value.push({reviewID: review.id, ...review.data(), timeCreated: timeCreated, timeUpdated: timeUpdated, averageRating: ave })
			});
			console.log('commentsCollection: ', commentsCollection)
		}
		const openMiniModal = (comment) => {
			console.log('openAlbumModal')
			console.log('Comment:', comment)
			activeModalReview.value = comment
			showDeleteModal.value = true
		}
		const closeMiniModal = () => {
			console.log('closeLogin')
			activeModalReview.value = null
			showDeleteModal.value = false
		}

		const toggleModal = () => {
			context.emit('close')
			console.log(props.review)
		}
		//  record
		const addComment = async(review) => {
			console.log('Edit Review:', review)

			const data = {}
			// if (review.reviewHeader !== undefined && review.reviewHeader != ''){
			// 	data.reviewHeader = review.reviewHeader
			// } else {updateDoc(reviewHeader, {reviewHeader: deleteField()})}
			if (review.reviewComment !== undefined && review.reviewComment != ''){
				data.reviewComment = review.reviewComment
			} else {updateDoc(review.reviewComment, {reviewComment: deleteField()})}
			// if (review.reviewRating !== undefined && review.reviewRating != ''){
			// 	data.reviewRating = review.reviewRating
			// } else {updateDoc(reviewRating, {reviewRating: deleteField()})}
			data.albumID = review.albumID
			data.userID = review.userID
			data.userName = props.user.name
			data.reviewID = review.reviewID
			data.created = serverTimestamp()
			
			console.log('Edit Data:', data)
			
			// const docRef = doc(db, 'reviews', review.id)
			const nydocRef = collection(db, 'reviews', review.reviewID, 'comments')
			const newcomment = await addDoc(nydocRef, data, { merge: true })
			const reviewRef = doc(db, 'reviews', review.reviewID)
			await updateDoc(reviewRef, {
				comments: arrayUnion(newcomment.id)
			}, { merge: true })

			toggleModal()

			// const albumRef = doc(db, 'Jazz', data.albumID)
			// 	await updateDoc(albumRef, {
			// 		reviews: arrayUnion(newReview.id)
			// 	})

            // .then (nydocRef => {
			// 	toggleModal()
            // })
			// .then( async (doc) => {
			// 	console.log('New Comment in firestore with id:', newcomment.id)
			// 	const reviewRef = doc(db, 'reviews', review.reviewID)
			// 	let update = await updateDoc(albumRef, {
			// 		comments: arrayUnion(newcomment.id)
			// 	})
			// 	console.log( 'New comment', newcomment.id, 'added to comments array!')
			// })
            // .catch(error =>{
			// 	console.log(error)
            // })
		}

		const editComment = async (comment) => {
			console.log('comment: ', comment)
			console.log('comment.reviewComment: ', comment.reviewComment)
		}

		const deleteComment = async (comment) => {
			console.log('comment: ', comment)
			console.log('review.id: ', comment.reviewID)
			console.log('comment.id: ', comment.commentID)
			let test = confirm('Really want to delete?')
            if (test){
				const docRef = doc(db, 'reviews', comment.reviewID, 'comments', comment.commentID)
                await deleteDoc(docRef)
				const reviewRef = doc(db, 'reviews', props.review.reviewID)
				await updateDoc(reviewRef, {
					comments: arrayRemove(comment.commentID)
				}, { merge: true })
			}
			toggleModal()
		}

		const makeArray = (string) => {
            let array = string.split(', ')
            return array
        }
		const dateConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit"} )
				return newdate
            }
        } 
		const timeConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit", hour:"numeric", minute:"2-digit", second:"2-digit"} )
				return newdate
            }
        } 
		const activateReadMore = (review) => {
			if(!review.readMore){
				review.readMore = true
			} else {
				review.readMore = false
			}
		}
		onMounted(
			async () => {
				getComments()
			}
		)
		return { makeArray, toggleModal, timeConverter, dateConverter, addComment, readMore, activateReadMore, commentsCollection, 
			deleteComment, editComment, showDeleteModal, openMiniModal, closeMiniModal, activeModalReview, isHovered
		}
	}
}
</script>

<style scoped>
body{
	height: 100vh;
	overflow-y: hidden;
}
.modal {
	min-width: 40vw;
	margin: 30px 20px;
	height: 90vh;
}
.newComment form textarea{
	background: #f0f0f0be;
}
.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}
.modal-preview {
	padding: 25px;
	background-color: #1212122a;
}
.modal-preview p{
	margin: 10px 0;
}
.commentsBox{
	margin: 10px auto;
	background: #111;
	text-align: left;
	padding: 10px;
}
</style>