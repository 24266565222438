<template>
  	<teleport to="#modals">
		<Transition name="fade" appear>
			<div class="modal-overlay" @click.self="toggleModal" aria-label="Close modal">
				<div :class="{ modal: true, loginBox: true, shadowHigh: !isHovered, shadowMedium: isHovered}" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false" >
					<section class="modal-picture">
						<img v-if="item.albumId" :src="require('@/assets/covers/' + item.albumId + '.jpg')" 
							:alt="item.album" loading="lazy"
							class="bilde"
							@click="toggleAlbum(item)"
							:id="item.id">
					</section>

					<h1>Edit Time</h1>
					<header class="modal-header" id="modalTitle">
						<h3 v-if="item.timedate">{{ unixtimeToLongdate(item.timedate) }}</h3>
					</header>
					<section class="modal-body" id="modalDescription">
						<form @submit.prevent="editActiveItem(item)">
							<input type="datetime-local" v-model="item.timedate" step="any">
							
							<button>Update Time</button>
						</form>
						<button @click="deleteFromPlaylist(item)">Delete from playlist</button>
					</section>
					<!-- Close button -->
					<span @click="toggleModal" class="close-btn material-icons" aria-label="Close modal">cancel</span>
					
				</div> <!-- MODAL END -->
			</div>
		</Transition>
  	</teleport>
</template>

<script>
import { db } from '@/firebase/config'
import { doc, deleteDoc, updateDoc, arrayRemove, serverTimestamp, setDoc } from 'firebase/firestore'
// import { user } from '@/composables/getUser';
import { unixtimeToLongdate } from '@/composables/timestampConverter'
import { makeArray } from '@/composables/makeArray'
import { ref } from 'vue';


export default {
	emits: ['close'],
	props: ['item', 'user'],
	setup(props, context) {

		const isHovered = ref(false)
		
		const toggleModal = () => {
			context.emit('close')
			console.log(props.item)
		}
		// Delete Album From Playlist
		const deleteFromPlaylist = async(item) => {
			const docRef = doc(db, 'users', props.user.id, 'playlist', item.id)
			console.log('docRef: ', docRef)
			deleteDoc(docRef)
			console.log( 'Removed album', item.artist,':', item.album, 'from user playlist')
			toggleModal()
		}

		// Edit record
		const editActiveItem = async(item) => {	
			const data = {}
			if (item.timedate !== undefined && item.timedate !== ''){
				data.time = new Date(item.timedate)
			}
			data.updated = serverTimestamp()
			const docRef = doc(db, 'users', props.user.id, 'playlist', item.id)
			await setDoc(docRef, data, { merge: true })
            .then (docRef => {
                console.log('Updated Album',item.artist,':', item.album)
				toggleModal()
            })
            .catch(error =>{
                console.log(error)
            })
		}

		return { makeArray, toggleModal, deleteFromPlaylist, editActiveItem, unixtimeToLongdate, isHovered
		}
	}
}
</script>

<style scoped>
.modal-picture{
	min-height: fit-content;
	width: 350px;
	margin: 10px;
}
.modal-body textarea{
	width: 90%;
	height: 120px;
	font-size: 1.2em;
	padding: 10px;
	margin: auto;
}
</style>