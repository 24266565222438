<template>
	<div class="head">
		<h2>Update Album</h2>
		<h3>{{ artist }}: {{ album }} ({{ year }}) </h3>
	</div>
  <form @submit.prevent="updateRecord">
	<div class="formContainer">
		<div class="inpContainer">
			<h3>Basic Info</h3>
			<div class="inp">
				<label for="title">Title</label>
				<input id="title" type="text" placeholder="Title" v-model="title">
			</div>
			<div class="inp">
				<label for="artist">Artist</label>
				<input id="artist" type="text" placeholder="Artist" v-model="artist">
			</div>
			<div class="inp">
				<label for="album">Album</label>
				<input id="album" type="text" placeholder="Album" v-model="album">
			</div>
			<div class="inp">
				<label for="year">Year</label>
				<input id="year" type="number" placeholder="Year" v-model="year" min="1940" max="2099">
			</div>
			<div class="inp">
				<label for="label">Label</label>
				<input id="label" type="text" placeholder="Label" v-model="label">
			</div>
		</div>	
		<div class="inpContainer">
			<h3>Technical Info</h3>
			<div class="inp">
				<label for="review">Allmusic Review URL</label>
				<input id="review" type="text" placeholder="Review" v-model="review">
			</div>
			<div class="inp">
				<label for="wiki">Wikipedia URL</label>
				<input id="wiki" type="text" placeholder="Wikipedia" v-model="wiki">
			</div>
			<div class="inp">
				<label for="discogs">Discogs URL</label>
				<input id="discogs" type="text" placeholder="Discogs" v-model="discogs">
			</div>
			<div class="inp">
				<label for="discogsID">DiscogsID</label>
				<input id="discogsID" type="text" placeholder="DiscogsID" v-model="discogsID">
			</div>
			<div class="inp">
				<label for="cat">Catalog</label>
				<input id="cat" type="text" placeholder="Cat" v-model="cat">
			</div>
			<div class="inp">
				<label for="catalogID">Catalog ID</label>
				<input id="catalogID" type="text" placeholder="CatalogID" v-model="catalogID">
			</div>
			<div class="inp">
				<label for="cover">Cover URL</label>
				<input id="cover" type="text" placeholder="Cover" v-model="cover">
			</div>
		</div>
		<div class="inpContainer">
			<h3>Sesson & Relese Dates</h3>
			<div class="inp">
				<label for="recorded1">Record Session 1</label>
				<input id="recorded1" type="date" placeholder="1. Session Recorded" v-model="recorded1">
			</div>
			<div class="inp">
				<label for="recorded2">Record Session 2</label>
				<input id="recorded2" type="date" placeholder="2. Session Recorded" v-model="recorded2">
			</div>
			<div class="inp">
				<label for="recorded3">Record Session 3</label>
				<input id="recorded3" type="date" placeholder="3. Session Recorded" v-model="recorded3">
			</div>
			<div class="inp">
				<label for="recorded4">Record Session 4</label>
				<input id="recorded4" type="date" placeholder="4. Session Recorded" v-model="recorded4">
			</div>
			<div class="inp">
				<label for="recorded5">Record Session 5</label>
				<input id="recorded5" type="date" placeholder="5. Session Recorded" v-model="recorded5">
			</div>
			<div class="inp">
				<label for="release">Release Date</label>
				<input id="release" type="date" placeholder="Release Date" v-model="release">
			</div>
		</div>
		<div class="inpContainer">
			<h3>Series Info</h3>
			<div class="inp">	
				<label for="series">Series</label>
				<select id="series" v-model="series">
					<option value="Acoustic Sounds Series">Acoustic Sounds Series</option>
					<option value="Blue Note 75 Vinyl Series">Blue Note 75 Vinyl Series</option>
					<option value="Blue Note 80 Vinyl Series">Blue Note 80 Vinyl Series</option>
					<option value="Blue Note Classic Vinyl Series">Blue Note Classic Vinyl Series</option>
					<option value="Blue Note Tone Poet Series">Blue Note Tone Poet Series</option>
					<option value="Columbia">Columbia</option>
					<option value="Contemporary Records Acoustic Sounds">Contemporary Records Acoustic Sounds</option>
					<option value="ECM Luminessence Series">ECM Luminessence Series</option>
					<option value="Mobile Fidelity Sound Lab">Mobile Fidelity Sound Lab</option>
					<option value="Music On Vinyl">Music On Vinyl</option>
					<option value="Verve By Request">Verve By Request</option>
					<option value="WaxTime In Color">WaxTime In Color</option>
					<option value="Other">Other</option>
				</select>
			</div>
			<div class="inp">
				<label for="theme">Blue Note Theme</label>
				<input id="theme" type="text" placeholder="Theme" v-model="theme">
			</div>
			<div class="inp">
				<label for="play">Play</label>
				<input id="play" type="text" placeholder="play" v-model="play">
			</div>
			<div class="inp">
				<label for="apple">Apple</label>
				<input id="apple" type="text" placeholder="Apple" v-model="apple">
			</div>
		</div>

		<!-- Mine bestillinger -->
		<div class="inpContainer">
			<h3>Collection</h3>
			<div class="inp">
				<label for="ordered">Ordered date</label>
				<input id="ordered" type="date" placeholder="Ordered" v-model="ordered">
			</div>
			<div class="inp">
				<label for="collected">Collected date</label>
				<input id="collected" type="date" placeholder="Collected" v-model="collected">
			</div>
			<div class="inp">
				<label for="sum">Sum</label>
				<input id="sum" type="text" placeholder="Sum" v-model="sum">
			</div>
			<div class="inp">
				<label for="butikk">Butikk</label>
				<input id="butikk" type="text" placeholder="Butikk" v-model="butikk">
			</div>
		</div>

		<div class="inpContainer">
			<h3>Saxophones</h3>
			<div class="inp">
				<label for="musicianSaxTenor">Tenor Saxophone</label>
				<input id="musicianSaxTenor" type="text" placeholder="Tenor Sax" v-model="musicianSaxTenor">
			</div>
			<div class="inp">
				<label for="musicianSaxAlto">Alto Saxophone</label>
				<input id="musicianSaxAlto" type="text" placeholder="Alto Sax" v-model="musicianSaxAlto">
			</div>
			<div class="inp">
				<label for="musicianSaxSoprano">Soprano Saxophone</label>
				<input id="musicianSaxSoprano" type="text" placeholder="Soprano Sax" v-model="musicianSaxSoprano">
			</div>
			<div class="inp">
				<label for="musicianSaxBaryton">Baryton Saxophone</label>
				<input id="musicianSaxBaryton" type="text" placeholder="Baryton Sax" v-model="musicianSaxBaryton">
			</div>
		</div>
		<div class="inpContainer">
			<h3>Small Horns</h3>
			<div class="inp">
				<label for="musicianClarinet">Clarinet</label>
				<input id="musicianClarinet" type="text" placeholder="Clarinet" v-model="musicianClarinet">
			</div>
			<div class="inp">
				<label for="musicianCornette">Cornette</label>
				<input id="musicianCornette" type="text" placeholder="Cornette" v-model="musicianCornette">
			</div>			
			<div class="inp">
				<label for="musicianFlute">Flute</label>
				<input id="musicianFlute" type="text" placeholder="Flute" v-model="musicianFlute">
			</div>
			<div class="inp">
				<label for="musicianTrumpet">Trumpet</label>
				<input id="musicianTrumpet" type="text" placeholder="Trumpet" v-model="musicianTrumpet">
			</div>
		</div>
		<div class="inpContainer">
			<h3>Big Horns</h3>
			<div class="inp">
				<label for="musicianEuphonium">Euphonium</label>
				<input id="musicianEuphonium" type="text" placeholder="Euphonium" v-model="musicianEuphonium">
			</div>
			<div class="inp">
				<label for="musicianHorns">Horns</label>
				<input id="musicianHorns" type="text" placeholder="Horns" v-model="musicianHorns">
			</div>
			<div class="inp">
				<label for="musicianTrombone">Trombone</label>
				<input id="musicianTrombone" type="text" placeholder="Trombone" v-model="musicianTrombone">
			</div>
			<div class="inp">
				<label for="musicianTuba">Tuba</label>
				<input id="musicianTuba" type="text" placeholder="Tuba" v-model="musicianTuba">
			</div>

		</div>
		<div class="inpContainer">
			<h3>Baasic Instruments</h3>
			<div class="inp">
				<label for="musicianPiano">Piano</label>
				<input id="musicianPiano" type="text" placeholder="Piano" v-model="musicianPiano">
			</div>
			<div class="inp">
				<label for="musicianDrums">Drums</label>
				<input id="musicianDrums" type="text" placeholder="Drums" v-model="musicianDrums">
			</div>
			<div class="inp">
				<label for="musicianBass">Bass</label>
				<input id="musicianBass" type="text" placeholder="Bass" v-model="musicianBass">
			</div>
			<div class="inp">
				<label for="musicianGuitar">Guitar</label>
				<input id="musicianGuitar" type="text" placeholder="Guitar" v-model="musicianGuitar">
			</div>
		</div>
		<div class="inpContainer">
			<h3>Instruments</h3>
			<div class="inp">
				<label for="musicianString">String</label>
				<input id="musicianString" type="text" placeholder="String" v-model="musicianString">
			</div>
			<div class="inp">
				<label for="musicianOrgan">Organ</label>
				<input id="musicianOrgan" type="text" placeholder="Organ" v-model="musicianOrgan">
			</div>
			<div class="inp">
				<label for="musicianConga">Conga</label>
				<input id="musicianConga" type="text" placeholder="Conga" v-model="musicianConga">
			</div>
			<div class="inp">
				<label for="musicianPercussion">Percussion</label>
				<input id="musicianPercussion" type="text" placeholder="Percussion" v-model="musicianPercussion">
			</div>
		</div>
		<div class="inpContainer">
			<h3>Instruments</h3>
			<div class="inp">
				<label for="musicianVibraphone">Vibraphone</label>
				<input id="musicianVibraphone" type="text" placeholder="Vibraphone" v-model="musicianVibraphone">
			</div>
			<div class="inp">
				<label for="musicianVocal">Vocal</label>
				<input id="musicianVocal" type="text" placeholder="Vocal" v-model="musicianVocal">
			</div>
			<div class="inp">
				<label for="musicianSitar">Sitar</label>
				<input id="musicianSitar" type="text" placeholder="Sitar" v-model="musicianSitar">
			</div>
			<div class="inp">
				<label for="musicianHarp">Harp</label>
				<input id="musicianHarp" type="text" placeholder="Harp" v-model="musicianHarp">
			</div>
		</div>
			<div class="inpContainer">
			<h3>Song 1-5</h3>
			<div class="inp">
				<label for="song01">Song 1</label>
				<input id="song01" type="text" placeholder="Track 01" v-model="song01">
			</div>
			<div class="inp">
				<label for="song02">Song 2</label>
				<input id="song02" type="text" placeholder="Track 02" v-model="song02">
			</div>
			<div class="inp">
				<label for="song03">Song 3</label>
				<input id="song03" type="text" placeholder="Track 03" v-model="song03">
			</div>
			<div class="inp">
				<label for="song04">Song 4</label>
				<input id="song04" type="text" placeholder="Track 04" v-model="song04">
			</div>
			<div class="inp">
				<label for="song05">Song 5</label>
				<input id="song05" type="text" placeholder="Track 05" v-model="song05">
			</div>
		</div>
		<div class="inpContainer">	
			<h3>Song 6-10</h3>
			<div class="inp">
				<label for="song06">Song 6</label>
				<input id="song06" type="text" placeholder="Track 06" v-model="song06">
			</div>
			<div class="inp">
				<label for="song07">Song 7</label>
				<input id="song07" type="text" placeholder="Track 07" v-model="song07">
			</div>
			<div class="inp">
				<label for="song08">Song 8</label>
				<input id="song08" type="text" placeholder="Track 08" v-model="song08">
			</div>
			<div class="inp">
				<label for="song09">Song 9</label>
				<input id="song09" type="text" placeholder="Track 09" v-model="song09">
			</div>
			<div class="inp">
				<label for="song10">Song10</label>
				<input id="song10" type="text" placeholder="Track 10" v-model="song10">
			</div>
		</div>
		<div class="inpContainer">
			<h3>Song 11-15</h3>
			<div class="inp">
				<label for="song11">Song11</label>
				<input id="song11" type="text" placeholder="Track 11" v-model="song11">
			</div>
			<div class="inp">
				<label for="song12">Song12</label>
				<input id="song12" type="text" placeholder="Track 12" v-model="song12">
			</div>
			<div class="inp">
				<label for="song13">Song13</label>
				<input id="song13" type="text" placeholder="Track 13" v-model="song13">
			</div>
			<div class="inp">
				<label for="song14">Song14</label>
				<input id="song14" type="text" placeholder="Track 14" v-model="song14">
			</div>
			<div class="inp">
				<label for="song15">Song15</label>
				<input id="song15" type="text" placeholder="Track 15" v-model="song15">
			</div>
		</div>
		<div class="inpContainer">
			<h3>Song 16-20</h3>
			<div class="inp">
				<label for="song16">Song16</label>
				<input id="song16" type="text" placeholder="Track 16" v-model="song16">
			</div>
			<div class="inp">
				<label for="song17">Song17</label>
				<input id="song17" type="text" placeholder="Track 17" v-model="song17">
			</div>
			<div class="inp">
				<label for="song18">Song18</label>
				<input id="song18" type="text" placeholder="Track 18" v-model="song18">
			</div>
			<div class="inp">
				<label for="song19">Song19</label>
				<input id="song19" type="text" placeholder="Track 19" v-model="song19">
			</div>
			<div class="inp">
				<label for="song20">Song20</label>
				<input id="song20" type="text" placeholder="Track 20" v-model="song20">
			</div>
		</div>
	</div>
	<button>Update Record</button>

  </form><br><br>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
// Firebase import
import { auth, db } from '@/firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc, updateDoc, setDoc, serverTimestamp , deleteField, arrayUnion, arrayRemove } from 'firebase/firestore'
import { guardRoute } from '@/composables/guardRoute'


export default {
    props: ['id', 'user'],
    setup(props){
        // Collection name
        const collectionName = 'Jazz'
        const router = useRouter()

        // Admin user Collection
        const userCollected = ref([])
        const authUser = ref(auth.currentUser)
        const user = ref({})
        
        // Ref fra inputs
        const title = ref('')
        const artist = ref('')
        const album = ref('')
        const year = ref('') 
        const label = ref('')
        const review = ref('')
        const wiki = ref('')
        const discogs = ref('') 
        const discogsID = ref('')
        const cat = ref('')
        const catalogID = ref('')
        const cover = ref('') 
        const recorded1 = ref('')
        const recorded2 = ref('')
        const recorded3 = ref('')
        const recorded4 = ref('')
        const recorded5 = ref('')
        const series = ref('')
        const release = ref('') 
        const theme = ref('')
        const play = ref('') 
        const apple = ref('') 
        const ordered = ref('')
        const collected = ref('')
        const sum = ref('')
        const butikk = ref('')
        // Musicians
        const musicianSaxTenor = ref('')
        const musicianSaxAlto = ref('')
        const musicianSaxSoprano = ref('')
        const musicianSaxBaryton = ref('')
        const musicianFlute = ref('')
        const musicianClarinet = ref('')
        const musicianTrombone = ref('')
        const musicianTrumpet = ref('')
        const musicianTuba = ref('')
        const musicianEuphonium = ref('')
        const musicianHorns = ref('')
        const musicianCornette = ref('')
        const musicianPiano = ref('')
        const musicianDrums = ref('')
        const musicianBass = ref('')
        const musicianString = ref('')
        const musicianGuitar = ref('')
        const musicianOrgan = ref('')
        const musicianConga = ref('')
        const musicianPercussion = ref('')
        const musicianVibraphone = ref('')
        const musicianVocal = ref('')
        const musicianSitar = ref('')
        const musicianHarp = ref('')
        // Songs
        const SongsArr = ref('')
        const song01 = ref('')
        const song02 = ref('')
        const song03 = ref('')
        const song04 = ref('')
        const song05 = ref('')
        const song06 = ref('')
        const song07 = ref('')
        const song08 = ref('')
        const song09 = ref('')
        const song10 = ref('')
        const song11 = ref('')
        const song12 = ref('')
        const song13 = ref('')
        const song14 = ref('')
        const song15 = ref('')
        const song16 = ref('')
        const song17 = ref('')
        const song18 = ref('')
        const song19 = ref('')
        const song20 = ref('')

        // Firebase docRef:
        const docRef = doc(db, collectionName, props.id)

        const dateConverter = (timeStamp) =>{
            if(!timeStamp){return}
            else{
            let date = new Date(timeStamp.toDate())
            console.log('date: ', date)
            let newdate = date.toLocaleDateString("en-CA", {year:"numeric", month: "2-digit", day:"2-digit"})
            console.log('newdate: ', newdate)
            return newdate
            }
        } 

        // Get values from document
        getDoc(docRef)
        .then((doc) => {
            title.value = doc.data().Title
            artist.value = doc.data().Artist
            album.value = doc.data().Album
            year.value = doc.data().Year
            label.value = doc.data().Label
            review.value = doc.data().Review
            wiki.value = doc.data().Wiki
            discogs.value = doc.data().Discogs
            discogsID.value = doc.data().DiscogsID
            cat.value = doc.data().Cat
            catalogID.value = doc.data().CatalogID
            cover.value = doc.data().Cover
            recorded1.value = dateConverter(doc.data().Recorded1)
            recorded2.value = dateConverter(doc.data().Recorded2)
            recorded3.value = dateConverter(doc.data().Recorded3)
            recorded4.value = dateConverter(doc.data().Recorded4)
            recorded5.value = dateConverter(doc.data().Recorded5)
            series.value = doc.data().Series
            release.value = dateConverter(doc.data().Release)
            theme.value = doc.data().Theme
            play.value = doc.data().Play
            apple.value = doc.data().Apple
            ordered.value = dateConverter(doc.data().Ordered)
            collected.value = dateConverter(doc.data().Collected)
            sum.value = doc.data().Sum
            butikk.value = doc.data().Butikk
            // Musicians
            musicianSaxTenor.value = doc.data().musicianSaxTenor
            musicianSaxAlto.value = doc.data().musicianSaxAlto
            musicianSaxSoprano.value = doc.data().musicianSaxSoprano
            musicianSaxBaryton.value = doc.data().musicianSaxBaryton
            musicianFlute.value = doc.data().musicianFlute
            musicianClarinet.value = doc.data().musicianClarinet
            musicianTrombone.value = doc.data().musicianTrombone
            musicianTrumpet.value = doc.data().musicianTrumpet
            musicianTuba.value = doc.data().musicianTuba
            musicianEuphonium.value = doc.data().musicianEuphonium
            musicianHorns.value = doc.data().musicianHorns
            musicianCornette.value = doc.data().musicianCornette
            musicianPiano.value = doc.data().musicianPiano
            musicianDrums.value = doc.data().musicianDrums
            musicianBass.value = doc.data().musicianBass
            musicianString.value = doc.data().musicianString
            musicianGuitar.value = doc.data().musicianGuitar
            musicianOrgan.value = doc.data().musicianOrgan
            musicianConga.value = doc.data().musicianConga
            musicianPercussion.value = doc.data().musicianPercussion
            musicianVibraphone.value = doc.data().musicianVibraphone
            musicianVocal.value = doc.data().musicianVocal
            musicianSitar.value = doc.data().musicianSitar
            musicianHarp.value = doc.data().musicianHarp
            
            // Songs
            SongsArr.value = doc.data().Songs

            song01.value = doc.data().Song01
            song02.value = doc.data().Song02
            song03.value = doc.data().Song03
            song04.value = doc.data().Song04
            song05.value = doc.data().Song05
            song06.value = doc.data().Song06
            song07.value = doc.data().Song07
            song08.value = doc.data().Song08
            song09.value = doc.data().Song09
            song10.value = doc.data().Song10
            song11.value = doc.data().Song11
            song12.value = doc.data().Song12
            song13.value = doc.data().Song13
            song14.value = doc.data().Song14
            song15.value = doc.data().Song15
            song16.value = doc.data().Song16
            song17.value = doc.data().Song17
            song18.value = doc.data().Song18
            song19.value = doc.data().Song19
            song20.value = doc.data().Song20

        })

        // Auth Changes
        onAuthStateChanged(auth, (_authUser) => {
            authUser.value = _authUser
            if(_authUser){
                getUserDoc(authUser.value.uid)
            }
        })
        // Get User Data
        const getUserDoc = async (uid) => {
            const userRef = doc(db, 'users', uid)
            const docSnap = await getDoc(userRef)
            if (docSnap.exists()) {
                user.value = {...docSnap.data(), id: docSnap.id }
                userCollected.value = docSnap.data().collected
                console.log('userCollected: ', userCollected)
            } else {console.log("No user document found!")}
        }

        const updateRecord = async () => {
            const docRef = doc(db, collectionName, props.id)
            const data = {}
            // Tester om feletene er tomme, hvis ikke legges de inn i data obj
            if (title.value !== undefined && title.value != ''){data.Title = title.value} else {updateDoc(docRef, {title: deleteField()})}
            if (artist.value !== undefined && artist.value != ''){data.Artist = artist.value} else {updateDoc(docRef, {artist: deleteField()})}
            if (album.value !== undefined && album.value != ''){data.Album = album.value} else {updateDoc(docRef, {album: deleteField()})}
            if (year.value !== undefined && year.value != ''){data.Year = year.value} else {updateDoc(docRef, {year: deleteField()})}
            if (label.value !== undefined && label.value != ''){data.Label = label.value} else {updateDoc(docRef, {label: deleteField()})}
            if (review.value !== undefined && review.value != ''){data.Review = review.value} else {updateDoc(docRef, {review: deleteField()})}
            if (wiki.value !== undefined && wiki.value != ''){data.Wiki = wiki.value} else {updateDoc(docRef, {wiki: deleteField()})}
            if (discogs.value !== undefined && discogs.value != ''){data.Discogs = discogs.value} else {updateDoc(docRef, {discogs: deleteField()})}
            if (discogsID.value !== undefined && discogsID.value != ''){data.DiscogsID = discogsID.value} else {updateDoc(docRef, {discogsID: deleteField()})}
            if (cat.value !== undefined && cat.value != ''){data.Cat = cat.value} else {updateDoc(docRef, {cat: deleteField()})}
            if (catalogID.value !== undefined && catalogID.value != ''){data.CatalogID = catalogID.value} else {updateDoc(docRef, {catalogID: deleteField()})}
            if (cover.value !== undefined && cover.value != ''){data.Cover = cover.value} else {updateDoc(docRef, {cover: deleteField()})}
            if (recorded1.value !== undefined && recorded1.value != ''){data.Recorded1 = new Date(recorded1.value)} else {updateDoc(docRef, {recorded1: deleteField()})}
            if (recorded2.value !== undefined && recorded2.value != ''){data.Recorded2 = new Date(recorded2.value)} else {updateDoc(docRef, {recorded2: deleteField()})}
            if (recorded3.value !== undefined && recorded3.value != ''){data.Recorded3 = new Date(recorded3.value)} else {updateDoc(docRef, {recorded3: deleteField()})}
            if (recorded4.value !== undefined && recorded4.value != ''){data.Recorded4 = new Date(recorded4.value)} else {updateDoc(docRef, {recorded4: deleteField()})}
            if (recorded5.value !== undefined && recorded5.value != ''){data.Recorded5 = new Date(recorded5.value)} else {updateDoc(docRef, {recorded5: deleteField()})}
            if (series.value !== undefined && series.value != ''){data.Series = series.value} else {updateDoc(docRef, {series: deleteField()})}
            if (release.value !== undefined && release.value != ''){data.Release = new Date(release.value)} else {updateDoc(docRef, {release: deleteField()})}
            if (theme.value !== undefined && theme.value != ''){data.Theme = theme.value} else {updateDoc(docRef, {theme: deleteField()})}
            if (play.value !== undefined && play.value != ''){data.Play = play.value} else {updateDoc(docRef, {play: deleteField()})}
            if (apple.value !== undefined && apple.value != ''){data.Apple = apple.value} else {updateDoc(docRef, {apple: deleteField()})}
            if (ordered.value !== undefined && ordered.value != ''){data.Ordered = new Date(ordered.value)} else {updateDoc(docRef, {ordered: deleteField()})}
            if (collected.value !== undefined && collected.value != ''){data.Collected = new Date(collected.value)} else {updateDoc(docRef, {collected: deleteField()})}
            if (sum.value !== undefined && sum.value != ''){data.Sum = sum.value} else {updateDoc(docRef, {sum: deleteField()})}
            if (butikk.value !== undefined && butikk.value != ''){data.Butikk = butikk.value} else {updateDoc(docRef, {butikk: deleteField()})}

            const musicians = []
            if(musicianSaxTenor.value !== undefined && musicianSaxTenor.value != '')
            {data.musicianSaxTenor = musicianSaxTenor.value, musicians.push(musicianSaxTenor.value)} else {updateDoc(docRef, {musicianSaxTenor: deleteField()})}
            if(musicianSaxAlto.value !== undefined && musicianSaxAlto.value != '')
            {data.musicianSaxAlto = musicianSaxAlto.value, musicians.push(musicianSaxAlto.value)} else {updateDoc(docRef, {musicianSaxAlto: deleteField()})}
            if(musicianSaxSoprano.value !== undefined && musicianSaxSoprano.value != '')
            {data.musicianSaxSoprano = musicianSaxSoprano.value, musicians.push(musicianSaxSoprano.value)} else {updateDoc(docRef, {musicianSaxSoprano: deleteField()})}
            if(musicianSaxBaryton.value !== undefined && musicianSaxBaryton.value != '')
            {data.musicianSaxBaryton = musicianSaxBaryton.value, musicians.push(musicianSaxBaryton.value)} else {updateDoc(docRef, {musicianSaxBaryton: deleteField()})}
            if(musicianFlute.value !== undefined && musicianFlute.value != '')
            {data.musicianFlute = musicianFlute.value, musicians.push(musicianFlute.value)} else {updateDoc(docRef, {musicianFlute: deleteField()})}
            if(musicianClarinet.value !== undefined && musicianClarinet.value != '')
            {data.musicianClarinet = musicianClarinet.value, musicians.push(musicianClarinet.value)} else {updateDoc(docRef, {musicianClarinet: deleteField()})}
            if(musicianTrombone.value !== undefined && musicianTrombone.value != '')
            {data.musicianTrombone = musicianTrombone.value, musicians.push(musicianTrombone.value)} else {updateDoc(docRef, {musicianTrombone: deleteField()})}
            if(musicianTrumpet.value !== undefined && musicianTrumpet.value != '')
            {data.musicianTrumpet = musicianTrumpet.value, musicians.push(musicianTrumpet.value)} else {updateDoc(docRef, {musicianTrumpet: deleteField()})}
            if(musicianTuba.value !== undefined && musicianTuba.value != '')
            {data.musicianTuba = musicianTuba.value, musicians.push(musicianTuba.value)} else {updateDoc(docRef, {musicianTuba: deleteField()})}
            if(musicianEuphonium.value !== undefined && musicianEuphonium.value != '')
            {data.musicianEuphonium = musicianEuphonium.value, musicians.push(musicianEuphonium.value)} else {updateDoc(docRef, {musicianEuphonium: deleteField()})}
            if(musicianHorns.value !== undefined && musicianHorns.value != '')
            {data.musicianHorns = musicianHorns.value, musicians.push(musicianHorns.value)} else {updateDoc(docRef, {musicianHorns: deleteField()})}
            if(musicianCornette.value !== undefined && musicianCornette.value != '')
            {data.musicianCornette = musicianCornette.value, musicians.push(musicianCornette.value)} else {updateDoc(docRef, {musicianCornette: deleteField()})}
            if(musicianPiano.value !== undefined && musicianPiano.value != '')
            {data.musicianPiano = musicianPiano.value, musicians.push(musicianPiano.value)} else {updateDoc(docRef, {musicianPiano: deleteField()})}
            if(musicianDrums.value !== undefined && musicianDrums.value != '')
            {data.musicianDrums = musicianDrums.value, musicians.push(musicianDrums.value)} else {updateDoc(docRef, {musicianDrums: deleteField()})}
            if(musicianBass.value !== undefined && musicianBass.value != '')
            {data.musicianBass = musicianBass.value, musicians.push(musicianBass.value)} else {updateDoc(docRef, {musicianBass: deleteField()})}
            if(musicianString.value !== undefined && musicianString.value != '')
            {data.musicianString = musicianString.value, musicians.push(musicianString.value)} else {updateDoc(docRef, {musicianString: deleteField()})}
            if(musicianGuitar.value !== undefined && musicianGuitar.value != '')
            {data.musicianGuitar = musicianGuitar.value, musicians.push(musicianGuitar.value)} else {updateDoc(docRef, {musicianGuitar: deleteField()})}
            if(musicianOrgan.value !== undefined && musicianOrgan.value != '')
            {data.musicianOrgan = musicianOrgan.value, musicians.push(musicianOrgan.value)} else {updateDoc(docRef, {musicianOrgan: deleteField()})}
            if(musicianConga.value !== undefined && musicianConga.value != '')
            {data.musicianConga = musicianConga.value, musicians.push(musicianConga.value)} else {updateDoc(docRef, {musicianConga: deleteField()})}
            if(musicianPercussion.value !== undefined && musicianPercussion.value != '')
            {data.musicianPercussion = musicianPercussion.value, musicians.push(musicianPercussion.value)} else {updateDoc(docRef, {musicianPercussion: deleteField()})}
            if(musicianVibraphone.value !== undefined && musicianVibraphone.value != '')
            {data.musicianVibraphone = musicianVibraphone.value, musicians.push(musicianVibraphone.value)} else {updateDoc(docRef, {musicianVibraphone: deleteField()})}
            if(musicianVocal.value !== undefined && musicianVocal.value != '')
            {data.musicianVocal = musicianVocal.value, musicians.push(musicianVocal.value)} else {updateDoc(docRef, {musicianVocal: deleteField()})}
            if(musicianSitar.value !== undefined && musicianSitar.value != '')
            {data.musicianSitar = musicianSitar.value, musicians.push(musicianSitar.value)} else {updateDoc(docRef, {musicianSitar: deleteField()})}
            if(musicianHarp.value !== undefined && musicianHarp.value != '')
            {data.musicianHarp = musicianHarp.value, musicians.push(musicianHarp.value)} else {updateDoc(docRef, {musicianHarp: deleteField()})}

            const songs = []
            if(song01.value !== undefined && song01.value != ''){data.Song01 = song01.value, songs.push(song01.value)}
            else{updateDoc(docRef, {Song01: deleteField()})}
            if(song02.value !== undefined && song02.value != ''){data.Song02 = song02.value, songs.push(song02.value)}
            else{updateDoc(docRef, {Song02: deleteField()})} 
            if(song03.value !== undefined && song03.value != ''){data.Song03 = song03.value, songs.push(song03.value)}
            else{updateDoc(docRef, {Song03: deleteField()})}
            if(song04.value !== undefined && song04.value != ''){data.Song04 = song04.value, songs.push(song04.value)}
            else{updateDoc(docRef, {Song04: deleteField()})} 
            if(song05.value !== undefined && song05.value != ''){data.Song05 = song05.value, songs.push(song05.value)}
            else{updateDoc(docRef, {Song05: deleteField()})} 
            if(song06.value !== undefined && song06.value != ''){data.Song06 = song06.value, songs.push(song06.value)}
            else{updateDoc(docRef, {Song06: deleteField()})}
            if(song07.value !== undefined && song07.value != ''){data.Song07 = song07.value, songs.push(song07.value)}
            else{updateDoc(docRef, {Song07: deleteField()})}
            if(song08.value !== undefined && song08.value != ''){data.Song08 = song08.value, songs.push(song08.value)}
            else{updateDoc(docRef, {Song08: deleteField()})}
            if(song09.value !== undefined && song09.value != ''){data.Song09 = song09.value, songs.push(song09.value)}
            else{updateDoc(docRef, {Song09: deleteField()})} 
            if(song10.value !== undefined && song10.value != ''){data.Song10 = song10.value, songs.push(song10.value)}
            else{updateDoc(docRef, {Song10: deleteField()})}
            if(song11.value !== undefined && song11.value != ''){data.Song11 = song11.value, songs.push(song11.value)}
            else{updateDoc(docRef, {Song11: deleteField()})} 
            if(song12.value !== undefined && song12.value != ''){data.Song12 = song12.value, songs.push(song12.value)}
            else{updateDoc(docRef, {Song12: deleteField()})} 
            if(song13.value !== undefined && song13.value != ''){data.Song13 = song13.value, songs.push(song13.value)}
            else{updateDoc(docRef, {Song13: deleteField()})}
            if(song14.value !== undefined && song14.value != ''){data.Song14 = song14.value, songs.push(song14.value)}
            else{updateDoc(docRef, {Song14: deleteField()})}
            if(song15.value !== undefined && song15.value != ''){data.Song15 = song15.value, songs.push(song15.value)}
            else{updateDoc(docRef, {Song15: deleteField()})}
            if(song16.value !== undefined && song16.value != ''){data.Song16 = song16.value, songs.push(song16.value)}
            else{updateDoc(docRef, {Song16: deleteField()})}
            if(song17.value !== undefined && song17.value != ''){data.Song17 = song17.value, songs.push(song17.value)}
            else{updateDoc(docRef, {Song17: deleteField()})}
            if(song18.value !== undefined && song18.value != ''){data.Song18 = song18.value, songs.push(song18.value)}
            else{updateDoc(docRef, {Song18: deleteField()})}
            if(song19.value !== undefined && song19.value != ''){data.Song19 = song19.value, songs.push(song19.value)}
            else{updateDoc(docRef, {Song19: deleteField()})}
            if(song20.value !== undefined && song20.value != ''){data.Song20 = song20.value, songs.push(song20.value)}
            else{updateDoc(docRef, {Song20: deleteField()})}

            // Add Array
            data.Musicians = musicians
            data.Songs = songs
            // Add Update date
            data.updated = serverTimestamp()

            console.log(data)
            
            await setDoc(docRef, data, { merge: true })
            .then (docRef => {
                console.log('Updated Document')
            })
            .catch(error =>{
                console.log(error)
            })
            // Add to user collection IF
            updateCollection(docRef, user)
            
            router.push('/')
        }
        const updateCollection = async (album, user) => {
            console.log('album.id: ', album.id)
            console.log('album.collected ', collected.value)
            const userRef = doc(db, 'users', user.value.id)
            if(collected.value !== ''){
                userCollected.value.push(album.id)
                await updateDoc(userRef, {
                    collected: arrayUnion(album.id)
                })
                console.log('Album added to user collection:', album.id)
            } else {
                userCollected.value.pop()
                await updateDoc(userRef, {
                    collected: arrayRemove(album.id)
                })
                console.log('Album removed from user collection:', album.id)
            }
        }

		onMounted( async () => {
			const guard = await guardRoute(props.user) // guard: boolean
			console.log('%c props.user', 'color:rgb(76, 201, 88);font-size:1.2em', props.user)
		})



        return { updateRecord, updateCollection, router, guardRoute,
        title, artist, album, year, label, review, wiki, discogs, discogsID, cat, catalogID, cover, recorded1, recorded2, recorded3, recorded4, recorded5, series, release, theme, play, apple, ordered, collected, sum, butikk,
        musicianSaxTenor,musicianSaxAlto,musicianSaxSoprano,musicianSaxBaryton,musicianFlute,musicianClarinet,musicianTrombone,musicianTrumpet,musicianTuba,musicianEuphonium, musicianHorns,musicianCornette,
        musicianPiano,musicianDrums,musicianBass,musicianString,musicianGuitar,musicianOrgan,musicianConga, musicianPercussion,musicianVibraphone,musicianVocal,musicianSitar, musicianHarp,
        song01,song02,song03,song04,song05,song06,song07,song08,song09,song10,song11,song12,song13,song14,song15,song16,song17,song18,song19,song20
        }

    }

}
</script>

<style scoped>
form{
    /* background: #f0e9e822; */
    padding: 20px;
    border-radius: 20px;
}
form label, form input, form select {
    display: block;
}
form input, form select {
	/* width: 100%; */
    padding: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 100%;
    height: 40px;
    font-size: 1em;
}
form select{
	width: 180px;
}
.formContainer{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
.container{
	background: transparent;
}
.inpContainer{
	display: block;
	background: rgba(91, 116, 226, 0.208);
	margin: 20px;
	padding: 10px;
	border-radius: 10px;
	max-width: 100%;
}
.inp input:not(:placeholder-shown) {
	font-weight: 700;
	font-size: 1.2em;
}
.inpContainer h2, 
.inpContainer h3 {
	text-align: center;
	margin-bottom: 15px;
}
.inpContainer label{
	text-align: center;
}
.head{
	display: block;
	text-align: center;
	width: fit-content;
	margin: 10px auto;
}
</style>