// Function to convert a string => kebab-case
export const toKebab = (str) => {
	let string = str.toLowerCase();
	let id = string.replace(/\s+/g, '-')
	// console.log('string: ', id)
	return id
}

// Function to convert a string => Title Case
export const toTitleCase = (str) => {
	return str
	  .toLowerCase() // Convert entire string to lowercase first
	  .split(' ') // Split the string by spaces into an array of words
	  .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
	  .join(' '); // Join the words back into a string
  }

export const getNextString = (str) => {
	// Get the next string in alphabetical order by incrementing the last character
	if (str.length === 0) return str;
	return str.slice(0, -1) + String.fromCharCode(str.charCodeAt(str.length - 1) + 1);
  }