import { ref } from "vue";

// Firebase imports
import { auth } from "../firebase/config";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

const error = ref(null)
const isPending = ref(false)
const UID = ref(null)

const signup = async (email, password, displayname) => {
    error.value = null
    isPending.value = true

    try {
        const res = await createUserWithEmailAndPassword(auth, email, password)
        // const user = auth.currentUser
        updateProfile(auth.currentUser, {
            displayName: displayname
          })
        console.log('User', email, 'signed up!')
        console.log(res)
        const userId = res.user.uid
        console.log('UID: ', userId)
        UID.value = userId

        if(!res) {
            throw new Error('Could not complete signup')
        }
        error.value = null
        isPending.value = false
    } 
    catch (err) {
        console.log(err.message)
        error.value = err.message
        isPending.value = false
    }
}

const useSignup = () => {
    return { error, isPending, UID, signup }
}

export default useSignup