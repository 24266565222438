import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router'

import Home from '@/views/Home.vue'
import Album from '@/views/Album.vue'
import Artist from '@/views/Artist.vue'
import Artists from '@/views/Artists.vue'
import Reviews from '@/views/Reviews.vue'

import Admin from '@/views/Admin/Admin.vue'

import AddAlbum from '@/views/Admin/AddAlbum.vue'
import EditAlbum from '@/views/Admin/EditAlbum.vue'

import AddArtist from '@/views/Admin/AddArtist.vue'
import EditArtist from '@/views/Admin/EditArtist.vue'

import Signup from '@/views/Admin/Signup.vue'
import Login from '@/views/Admin/Login.vue'
import User from '@/views/User.vue'
import Playlist from '@/views/Playlist.vue'

// Route guard
import { auth } from '@/firebase/config'

// Route guard
const requireAuth = (to, from, next) => {
	console.log('auth: ',auth)
	// console.log('user: ', user)
    if(!auth) {
        next({ name: 'Login' })
    } else {
        next()
    }
}

const routes = [
	// ALBUMS
  	{
        path: '/',
        name: 'Home',
        component: Home, 
        meta: {
            title: 'Home',
        },
    },
	{
        path: '/album/:id',
        name: 'Album',
        component: Album,
        props: true,
        meta: {
            title: 'Album',
        },
    },
	// ARTISTS
    {
        path: '/artists',
        name: 'Artists',
        component: Artists,
        props: true,
        meta: {
            title: 'Artists',
        },
    },
    {
        path: '/artist/:id',
        name: 'Artist',
        component: Artist,
        props: true,
        meta: {
            title: 'Artist',
        },
    },
	// REWIEWS
	{
        path: '/reviews',
        name: 'Reviews',
        component: Reviews,
        meta: {
            title: 'Reviews',
        },
    },
	// ADMIN
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
		beforeEnter: requireAuth,
        meta: {
            title: 'Admin',
        },
    },
    {
        path: '/add-album',
        name: 'AddAlbum',
        component: AddAlbum,
		beforeEnter: requireAuth,
        meta: {
            title: 'AddAlbum',
        },
    },
    {
        path: '/add-artist',
        name: 'AddArtist',
        component: AddArtist,
		beforeEnter: requireAuth,
        meta: {
            title: 'AddArtist',
        },
    },
    {
        path: '/edit-album/:id',
        name: 'EditAlbum',
        component: EditAlbum,
		beforeEnter: requireAuth,
        props: true,
        meta: {
            title: 'EditAlbum',
        },
    },
    {
        path: '/edit-artist/:id',
        name: 'EditArtist',
        component: EditArtist,
		beforeEnter: requireAuth,
        props: true,
        meta: {
            title: 'EditArtist',
        },
    },
	// AUTH
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        meta: {
            title: 'Signup',
        },
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			title: 'Login',
		},
	},
	// USER
	{
		path: '/user',
		name: 'User',
		component: User,
		meta: {
			title: 'User',
		},
	},
	{
		path: '/playlist',
		name: 'Playlist',
		component: Playlist,
		meta: {
			title: 'Playlist',
		},
	},
	// ERROR
	{
		path: '/:catchAll(.*)',
		redirect: "/404"
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: "/404"
	},
	{
		path: '/:pathMatch(.*)',
		redirect: "/404"
	},
	{
		path: "/404",
		name: "404",
		component: () => import("@/components/Page404.vue")
	}
]

const router = createRouter({
  	history: createWebHistory(process.env.BASE_URL),
	routes,
	// history: createMemoryHistory(process.env.BASE_URL),
	// history: createWebHistory(process.env.BASE_URL),
	// routes: [{ path: '/:pathMatch(.*)', component: NotFoundComponent }],
	// routes: [{ path: '/:pathMatch(.*)', component: NotFoundComponent }],
})

// router.beforeEach((to, from, next) => {
//     document.title = `Shapeofjazz: ${to.meta.title}`;
// 	console.log('to: ', to)
// 	console.log('from: ', from)
// 	console.log('next: ', next)
//     next();
// })
// router.beforeEach((to, from) => {
// 	document.title = to.meta?.title ?? `Shapeofjazz ${to.meta.title}`;
// })
// router.beforeEach((to, from, next) => {
// 	console.log('to.params: ', to.params)
// 	const titleFromParams = to.params?.title
// 	console.log('titleFromParams: ', titleFromParams)
// 	if (titleFromParams) {
// 	  document.title = `${titleFromParams} - ${document.title}`
// 	} else {
// 	  document.title = to.meta?.title ?? `Shapeofjazz ${to.meta.title}`
// 	}
// 	next();
//   })

// router.beforeEach((to) => {
// 	const { title, description } = to.meta;
// 	const defaultTitle = 'Default Title';
// 	const defaultDescription = 'Default Description';
  
// 	document.title = title || defaultTitle
  
// 	const descriptionElement = document.querySelector('head meta[name="description"]')
  
// 	descriptionElement.setAttribute('content', description || defaultDescription)
//   })

router.beforeEach((to, from, next) => {
	// Get the page title from the route meta data that we have defined
	// See further down below for how we setup this data
	const title = `Shapeofjazz ${to.meta.title}`
	//Take the title from the parameters
	const titleFromParams = to.params.pageTitle;
	// If the route has a title, set it as the page title of the document/page
	if (title) {
	  document.title = title
	}
	// If we have a title from the params, extend the title with the title
	// from our params
	if (titleFromParams) {
	  document.title = `${titleFromParams} - ${document.title}`;
	}
	// Continue resolving the route
	next()
  })
  


export default router
