export function unixtimeToLongdate(unixtimeStamp) { //dateConverterUK
	if(unixtimeStamp){
		let date = new Date(unixtimeStamp);
		let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit"} );
		return newdate; // From Unix timestap => 24 July 2024
	}
} 
export function timestampToTimedate(timeStamp) { //timeConverterUK
	if(timeStamp){
		let date = new Date(timeStamp.toDate());
		let newdate = date.toLocaleDateString("en-UK", {year:"numeric", month: "long", day:"2-digit", hour:"numeric", minute:"2-digit", second:"2-digit"});
		return newdate;// 24 July 2024 at 20:56:03
	}
} 
export function timestampToInput(timeStamp) { //timeConverter
	if(timeStamp){
		let date = new Date(timeStamp.toDate());
		let newdate = date.toLocaleDateString("fr-CA", {year:"numeric", month: "2-digit", day:"2-digit"});
		return newdate; // 2024-07-24
	}
}
export const timestampToInputTime = (timeStamp) => {
	if(timeStamp){
		let date = new Date(timeStamp.toDate())
		let newdate = date.toLocaleString('sv-SE')
		return newdate // 2024-07-24 20:56:03
	}
} 
export function timestampToLongdate(timeStamp) { //timeConverter2
	if(timeStamp){
		let date = new Date(timeStamp.toDate());
		let newdate = date.toLocaleDateString("en-UK", {year:"numeric", month: "long", day:"2-digit"});
		return newdate; // 24 July 2024
	}
} 
export function timestampToUserLanguage(timeStamp) { //timeConverter2
	if(timeStamp){
		let date = new Date(timeStamp.toDate());
		let newdate = date.toLocaleDateString(undefined, {year:"numeric", month: "long", day:"2-digit"});
		return newdate; // 24 July 2024
	}
} 
export function timestampToYear(timeStamp) { 
	if(timeStamp){
		let date = new Date(timeStamp.toDate());
		let newdate = date.toLocaleDateString("en-UK", {year:"numeric"});
		return newdate; // 2024
	}
}
// Calculate total years betweeen dates
export function timestampToTotalYears(starttime, stoptime) {
	if(starttime){
		let start = new Date(starttime.toDate());
		let stop = new Date(stoptime.toDate());
		let diff = (stop - start) / 1000;
		diff /= (60 * 60 * 24);
		return Math.abs(Math.round(diff/365.25)); // 74
	}
}