<template>
	<div class="moduleImage">
		<img
			:src="require('@/assets/profiles/' + artist.id + '.jpg')" 
			:alt="artist.name" loading="lazy"
			class="bilde"
			@click="coverSize($event)">
	</div>
</template>

<script>
export default {
	props: ['artist'],
	setup(props) {
		const coverSize = (event) => {
			console.log('event: ', event)
			console.log('naturalHeight: ', event.target.naturalHeight)
			console.log('naturalWidth: ', event.target.naturalWidth)
		}


	return { coverSize }
	}

}
</script>

<style scoped>
	.moduleImage{
		display: block;
		margin: 0 auto;
	}
	.moduleImage img{
		border: 7px solid #ccc;
		border-radius: 5px;
		box-shadow: 3px 3px 8px #333;
		margin: 0 auto;
		display: block;
		width: 100%;
		max-width: 400px;
		margin: 0 auto;

		max-width: 500px;
		display: block;
		border-radius: 50%;
		--crop-focus-x: 0.5;
		--crop-focus-y: 0.1;
		aspect-ratio: 1 / 1;
		object-fit: cover;
		object-position: calc(var(--crop-focus-x) * 100%) calc(var(--crop-focus-y) * 100%);
		margin: 0 auto;
		box-shadow: 2px 2px 20px rgba(12,12,12,0.9);
		
	}
	.artistContainer{
		display: block;
		/* background: rgb(27, 18, 32); */
		max-width: 960px;
		margin: 0 auto;
		padding: 10px;
		/* border: 10px solid #ccc; */
	}
	.error {
		text-align: center;
	}
	header.navButtons,
	footer.navButtons{
		margin: 10px 30px;
	}
	header{
		max-width: 960px;
		margin: 0 auto;
	}
	footer{
		width: 100%;
		padding: 10px;
	}
	.instruments{
		list-style-type: none;
	}

	/* Float buttons */
	#prev{
		float: left;
	}
	#next{
		float: right;
	}

</style>