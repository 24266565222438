export function groupMusiciansByInstruments(array) {
	// Create a map to keep track of musicians and their instruments
	const musicianMap = new Map();
  
	array.forEach(musician => {
	  const { name, instrument } = musician;
	  
	  // If the musician already exists in the map, add the instrument to their list
	  if (musicianMap.has(name)) {
		musicianMap.get(name).push(instrument);
	  } else {
		// Otherwise, create a new entry in the map with the instrument in an array
		musicianMap.set(name, [instrument]);
	  }
	});
	// Convert the map back to an array of objects and sort by name
	return Array.from(musicianMap, ([name, instruments]) => ({ name, instruments }))
		.sort((a, b) => a.name.localeCompare(b.name));
  }