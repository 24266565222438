<template>
  	<teleport to="#modals">
		  <Transition name="fade" type="transition" appear>
			  <div class="modal-overlay" @click.self="toggleModal" aria-label="Close modal">
				<div :class="{ modal: true, loginBox: true, shadowHigh: !isHovered, shadowMedium: isHovered}"
					role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" 
					v-if="showLogin"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false">
					<h2>Log In User</h2>
					<form @submit.prevent="handleLogin" autocomplete="on">
						<label for="email">Email</label>
						<input id="email" type="email" name="email" placeholder="e-mail" v-model="email" autocomplete="username" required>
						
						<label for="password">Password</label>
						<input id="password" type="password" name="password" placeholder="password" v-model="password" autocomplete="current-password" required>
						
						<button>Log In</button>
						<a id="link" @click="showLogin = false">Sign Up instead?</a>
						<div v-if="error">{{ error }}</div>
					</form>
					<!-- Close button -->
					<span @click="toggleModal" class="close-btn material-icons" aria-label="Close modal">cancel</span>
				</div>		
				<div :class="{ modal: true, loginBox: true, shadowHigh: !isHovered, shadowMedium: isHovered}"
					role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" v-else
					@mouseenter="isHovered = true" @mouseleave="isHovered = false">
					<form @submit.prevent="handleSignup">
						<h2>Sign Up User</h2>

						<label for="email">Email:</label>
						<input id="email" type="email" name="email" v-model="email" autocomplete="off" required>
						
						<label for="displayname">Name:</label>
						<input id="displayname" type="text" name="displayname" v-model="displayname" autocomplete="off" required>

						<label for="password">Password:</label>
						<input id="password" type="password" name="password" v-model="password" autocomplete="off" required>

						<button>Sign Up</button>
						<a id="link" @click="showLogin = true">Log In instead?</a>
						<div v-if="err">{{ err }}</div>
					</form>
					<!-- Close button -->
					<span @click="toggleModal" class="close-btn material-icons" aria-label="Close modal">cancel</span>
				</div>
			</div>
		</Transition>
  	</teleport>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useLogin from '@/composables/useLogin'
import useSignup from '@/composables/useSignup'
import { db } from '@/firebase/config'
import { doc, serverTimestamp, updateDoc, setDoc, arrayUnion, Timestamp } from 'firebase/firestore'
import { timestamp } from '@vueuse/core'

export default {
	emits: ['close'],
	setup(props, context) {
		const email = ref('')
		const password = ref('')
		const displayname = ref('') // If signUp
		const router = useRouter()
		const { login, error, UID } = useLogin()
		const { signup, error: err, UID: userID } = useSignup()
		
		const showLogin = ref(true)
		const isHovered = ref(false)

		const toggleModal = () => {
			context.emit('close')
		}
		const handleLogin = async () => {
			await login(email.value, password.value)
			const docRef = doc(db, 'users', UID.value )
			const firestoreTimestamp = Timestamp.fromDate(new Date())
			await updateDoc(docRef, {
				login: arrayUnion(firestoreTimestamp)
			})

			if (!error.value) {
				toggleModal()
				router.push('/')
			}
		}

		const handleSignup = async () => {
			console.log('handleSignup:')
			await signup(email.value, password.value, displayname.value)

			// Lag bruker (med Auth ID) i users collection
			const docRef = doc(db, 'users', userID.value )
			await setDoc(docRef, {
				admin: false,
				collected: [],
				created: serverTimestamp(),
				email: email.value,
				favorites: [],
				login: [],
				name: displayname.value,
				password: password.value,
				reviews: [],
				reviewIDs: []
			})

			if (!err.value) {
				toggleModal()
				router.push('/')
			}
		}

		return { email, password, displayname, handleLogin, handleSignup, error, toggleModal, showLogin, err, userID, isHovered }
	}
}
</script>

<style scoped>
.modal {
	height: fit-content;
	min-width: 300px;
	overflow: hidden;
}
.modal:hover input, button {
	box-shadow: 5px 5px 3px #121212;
}
.modal h2{
	text-shadow: 2px 2px 2px #121212;
    font-size: 2em;
	text-align: left;
	margin: 25px auto 0 10px;
	padding: 0;
}
.modal #link{
	display: block;
	margin-top: 15px;
	/* margin-left: 30px; */
	color: #88b;
	font-size: 1.2em;
	transition: all 2s ease;
	cursor: pointer;
}
.modal #link:hover{
	color: #8eb8f3;
	text-decoration: underline;
}
form label, form input, form select {
  display: block;
}
form label{
    color: #fff;
    font-size: 18px;
}
form {
	margin-top: 30px;
}
form input,
form select {
	max-width: 280px;
	width: 100%;
	padding: 6px;
	margin-top: 10px;
	font-size: 1.2em;
	font-weight: 500;
	border-radius: 8px;
	text-align: center;
	box-shadow: 2px 2px 2px #121212;
	height: 45px;
}
form input:focus, 
form input:active{
    outline: 3px solid #121212;
}
button {
	display: block;
	background: #bbb;
	color: #333;
	border: 0;
	border-radius: 6px;
	/* padding: 6px 10px; */
	cursor: pointer;
	/* font-size: 20px; */
	width: 100%;
	margin: 20px auto;
	box-shadow: 2px 2px 2px #121212;
}
form input[type="password"]:not(:placeholder-shown){
	font-size: 1.4em;
	font-weight: 700;
	letter-spacing: 0.125em;
	margin-bottom: 20px;
}
</style>