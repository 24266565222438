<template>
	<div class="adminModule">
		<!-- <button @click="makeUserCollectedArray" class="tooltip">Sync Admin Collection
			<span class="tooltiptext"></span>
		</button> -->
		<button @click="copyCollectedFromAlbumToUserCollection" class="tooltip">Copy to User Collection
			<span class="tooltiptext">Copy Collected From Album To User Collection</span>
		</button>
		<button @click="copyReviewsFromAlbumToUserCollection" class="tooltip">Copy Reviews
			<span class="tooltiptext">Copy Reviews From Album To User reviews</span>
		</button>
		<button @click="updateAlbumReviews" class="tooltip">Update Reviews
			<span class="tooltiptext">Copy Reviews From Album 'reviews' array</span>
		</button>
		<button @click="clearReviews" class="tooltip">Clear Reviews
			<span class="tooltiptext">Warning!<br>This will delete every review and all refs to reviews</span>
			<!-- <span class="tooltiptext"></span> -->
		</button>
		<button @click="syncPlaylist(user.id)" class="tooltip">Update Playlist data
			<span class="tooltiptext">Update users Playlist data in db</span>
		</button>
		<button @click="syncAverage" class="tooltip">Update Average
			<span class="tooltiptext">Force new avg rating calculation of every review and update Album data in db</span>
		</button>
		<button @click="copyArtistsToConfig" class="tooltip">Copy artists to config
			<span class="tooltiptext">
				Do this after import of new 'artists' collection in firestore:<br>
				Update 'config' in firestore with all artists from firestore
			</span>
		</button>
		
		<button class="tooltip" @click="makeAlbumArrays">Album to Arrays
			<span class="tooltiptext">
				Do this after import of 'Jazz' collection in firestore:<br>
				Update every album in 'Jazz' in firestore with arrays for artist & musiscians 
			</span>
		</button>
	</div>
  
</template>

<script>
import { onMounted } from 'vue'

// Firebase import
import { db } from '@/firebase/config'
import { addDoc, arrayUnion, collection, deleteDoc, deleteField, doc, getDocs, limit, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore'
import { guardRoute } from '@/composables/guardRoute'
import { makeArray } from '@/composables/makeArray'
import { syncPlaylist } from '@/composables/syncPlaylist'

export default {
	props:['user'],
    setup(props){
		// Button
        const makeUserCollectedArray = async () => {
            const userRef = doc(db, 'users', props.user.id)
            const snapshot = await getDocs(collection(db, "Jazz"));
            snapshot.forEach((doc) => {
                if(doc.data().Collected){
                    updateDoc(userRef, {
                        collected: arrayUnion(doc.id)
                })
                }
            })
            console.log('Admin Collection updated!')
        }
		// Button 
        const copyCollectedFromAlbumToUserCollection = async () => {
			console.log('copyCollectedFromAlbumToUserCollection')
			const userID = props.user.id
            const snapshot = await getDocs(collection(db, "Jazz"));
            snapshot.forEach(async (album) => {
				if(album.data().Collected){
					const ref = doc(db, 'users', userID, 'collection', album.id)
					const data = {}
					if (album.data().Ordered !== undefined && album.data().Ordered != ''){data.Ordered =  album.data().Ordered} 
					else {updateDoc(ref, {Ordered: deleteField()})}
					if (album.data().Collected !== undefined && album.data().Collected != ''){data.Collected = album.data().Collected} 
					else {updateDoc(ref, {Collected: deleteField()})}
					if (album.data().Sum !== undefined && album.data().Sum != ''){data.Sum = album.data().Sum} 
					else {updateDoc(ref, {Sum: deleteField()})}
					if (album.data().Butikk !== undefined && album.data().Butikk != ''){data.Butikk = album.data().Butikk} 
					else {updateDoc(ref, {Butikk: deleteField()})}
					data.albumId = album.id
					data.created = serverTimestamp()
					console.log('Data to firestore: ', data)
					
					const writeDoc = await setDoc(ref, data)
					console.log('Album: '+ album.data().Artist + ': ' + album.data().Album)
					
					// Update user array
					const userRef = doc(db, 'users', props.user.id)
						await updateDoc(userRef, {
							collected: arrayUnion(album.id)
						})
						console.log( 'Updated', props.user.name, 's collected array!')

						}
						// console.log('Firestore updated!')
					})

				// console.log('User Collection updated!')
        }
		// Button
		const clearReviews = async () => {
			let test = confirm('Really want to delete all reviews?')
            if (test) {
				console.log('clearReviews!')
				const snapshot = await getDocs(collection(db, "Jazz"));
				snapshot.forEach( async (album) => {
					// Update album 
					const albumRef = doc(db, 'Jazz', album.id)
					// reviews: arrayRemove(album.reviewID) // Or delete array instead
					await updateDoc(albumRef, {
						reviews: []
					})
					console.log( 'Removed', album.albumTitle, 'from album albumReviews')
					
				})
				// Reset user arrays
				const userRef = doc(db, 'users', props.user.id)
				await updateDoc(userRef, {
					reviews: [],
					reviewIDs: []
				})
				// Delete every review in the reviews collection
				const querySnapshot = await getDocs(collection(db, "reviews"))
				querySnapshot.forEach(async (review) => {
					await deleteDoc(doc(db, "reviews", review.id))
					console.log("Review Deleted ", review.data().albumTitle);
				});
				console.log('%c All Reviews deleted!', 'color:red')
			}
		}
		const updateAlbumReviews = async () => {
			const q = query(collection(db, "Jazz"), limit(1000))
            const snapshot = await getDocs(q)
			snapshot.forEach( async (album) => {
				const albumRef = doc(db, 'Jazz', album.id)
				const reviewQuery = query(collection(db, "reviews"), where("albumID", "==", album.id));
				const albumReviews = await getDocs(reviewQuery);
				albumReviews.forEach( async ( review ) => {
					await updateDoc(albumRef, {
						reviews: arrayUnion(review.id)
					})
					console.log( 'Added the review id', review.id, 'to album reviews array!')
				})
			})
		}
		// Button
        const copyReviewsFromAlbumToUserCollection = async () => {
            const userID = props.user.id
            const userName = props.user.name
            const userRef = doc(db, "users", userID)
            const q = query(collection(db, "Jazz"), limit(1000))
            const snapshot = await getDocs(q)
			console.log('snapshot: ', snapshot)
            snapshot.forEach( async (album) => {
                if(album.data().AllmusicRating){
					const data = {}
                    if (album.data().AllmusicHeader !== undefined && album.data().AllmusicHeader != ''){data.reviewHeader =  album.data().AllmusicHeader}
                    if (album.data().AllmusicBody !== undefined && album.data().AllmusicBody != ''){data.reviewBody =  album.data().AllmusicBody}
                    if (album.data().AllmusicRating !== undefined && album.data().AllmusicRating != ''){data.reviewRating =  album.data().AllmusicRating}
                    data.created = serverTimestamp()
                    // Linked data
                    data.albumTitle = album.data().Title
                    data.albumID = album.id
                    data.userID = userID
                    data.userName = userName
                    // Update
                    console.log('Upload data: ', data)
					const reviewsRef = collection(db, "reviews")
                    const newReview = await addDoc(reviewsRef, data, {merge: true})
					console.log('newReview: ', newReview.id)
                    console.log('Firestore updated!')

                    // Update album 
                    const albumRef = doc(db, 'Jazz', album.id)
                    await updateDoc(albumRef, {
                        reviews: arrayUnion(newReview.id)
                    })
                    console.log( 'Added the review id', newReview.id, 'to album reviews array!')
					
                    // Update user array
                    const userRef = doc(db, 'users', userID)
					await updateDoc(userRef, {
						reviews: arrayUnion(album.id),
						reviewIDs: arrayUnion(newReview.id)
					})
					console.log( 'Added the review id', newReview.id, 'to user reviews array!')
                }
            })
            console.log('Review Collection updated!')
        }
		// Used by updateAverage()
		const arrayToAvg = (ratings) => {
			if (ratings == null || ratings.isEmpty) {
				return null
			}
			let sum = 0;
			ratings.forEach((rate) => {
				sum += rate;
			})
			let avg =  sum / ratings.length
			return Number(avg.toFixed(2));
		}
		// Used by syncAverage()
		const updateAverage = async (albumID) => {
			let ratings = []
			const reviewQuery = query(collection(db, "reviews"), where("albumID", "==", albumID));
			const albumReviews = await getDocs(reviewQuery);
			albumReviews.forEach((review)=>{
				let rate = review.data().reviewRating
				if(rate){
					ratings.push(rate)
				}
			})
			let average = arrayToAvg(ratings)
			let ratingCount = ratings.length

			// Update album
			const albumRef = doc(db, 'Jazz', albumID)
				await updateDoc(albumRef, {
					ratingAverage: average,
					ratingCount: ratingCount
				}, { merge: true })
		}
		// Button
		const syncAverage = async () => {
			const ref = collection(db, 'Jazz')
			const albumSnap = await getDocs(ref)
			albumSnap.forEach(async(album) => {
				let update = await updateAverage(album.id)
				console.log('Updated avg rating set to', album.data().Artist, album.data().Album)
			})
		}
		const makeArtistArray = async () => {
			const collectionRef = collection(db, 'Jazz')
			const albumSnap = await getDocs(collectionRef)
			albumSnap.forEach(async(album) => {
				const albumRef = doc(db, 'Jazz', album.id)
				let artist = album.data().Artist
				if(artist.includes(' & ')) {
					let split = artist.split(' & ')
					await updateDoc(albumRef, { artistArray: split })
				}
				else if(artist.includes(', ')) {
					let split = artist.split(', ')
					await updateDoc(albumRef, { artistArray: split })
				}
				else if(artist.includes(' with ')) {
					let split = artist.split(' with ')
					await updateDoc(albumRef, { artistArray: split })
				}
				else if(artist.includes(' and ')) {
					let split = artist.split(' and ')
					await updateDoc(albumRef, { artistArray: split })
				}
				else { // If only One Artist found
					let normal = []
					normal.push(artist)
					await updateDoc(albumRef, { artistArray: normal })
				}
			})
		}
		// Button
		const makeAlbumArrays = async () => {
			const collectionRef = collection(db, 'Jazz')
			const albumSnap = await getDocs(collectionRef)
			albumSnap.forEach(async(album) => {
				// Ref for this album
				const albumRef = doc(db, 'Jazz', album.id)

				console.log('%c Update album array', 'color:yellow', album.id, album.data().Album)

				// Artist => artistArray
				let artist = album.data().Artist
				if(artist.includes(' & ')) {
					let split = artist.split(' & ')
					await updateDoc(albumRef, { artistArray: split })
				}
				else if(artist.includes(', ')) {
					let split = artist.split(', ')
					await updateDoc(albumRef, { artistArray: split })
				}
				else if(artist.includes(' with ')) {
					let split = artist.split(' with ')
					await updateDoc(albumRef, { artistArray: split })
				}
				else if(artist.includes(' and ')) {
					let split = artist.split(' and ')
					await updateDoc(albumRef, { artistArray: split })
				}
				else { // If only One Artist found
					let normal = []
					normal.push(artist)
					await updateDoc(albumRef, { artistArray: normal })
				}

				// Update album
				if(album.data().musicianBass !== undefined && album.data().musicianBass != ''){
					let musicianBass = makeArray(album.data().musicianBass)
					await updateDoc(albumRef, {musicianBassArr: musicianBass})
				}
				if(album.data().musicianClarinet !== undefined && album.data().musicianClarinet != ''){
					let musicianClarinet = makeArray(album.data().musicianClarinet)
					await updateDoc(albumRef, {musicianClarinetArr: musicianClarinet})
				}
				if(album.data().musicianConga !== undefined && album.data().musicianConga != ''){
					let musicianConga = makeArray(album.data().musicianConga)
					await updateDoc(albumRef, {musicianCongaArr: musicianConga})
				}
				if(album.data().musicianCornette !== undefined && album.data().musicianCornette != ''){
					let musicianCornette = makeArray(album.data().musicianCornette)
					await updateDoc(albumRef, {musicianCornetteArr: musicianCornette})
				}
				if(album.data().musicianDrums !== undefined && album.data().musicianDrums != ''){
					let musicianDrums = makeArray(album.data().musicianDrums)
					await updateDoc(albumRef, {musicianDrumsArr: musicianDrums})
				}
				if(album.data().musicianEuphonium !== undefined && album.data().musicianEuphonium != ''){
					let musicianEuphonium = makeArray(album.data().musicianEuphonium)
					await updateDoc(albumRef, {musicianEuphoniumArr: musicianEuphonium})
				}
				if(album.data().musicianFlute !== undefined && album.data().musicianFlute != ''){
					let musicianFlute = makeArray(album.data().musicianFlute)
					await updateDoc(albumRef, {musicianFluteArr: musicianFlute})
				}
				if(album.data().musicianGuitar !== undefined && album.data().musicianGuitar != ''){
					let musicianGuitar = makeArray(album.data().musicianGuitar)
					await updateDoc(albumRef, {musicianGuitarArr: musicianGuitar})
				}
				if(album.data().musicianHarp !== undefined && album.data().musicianHarp != ''){
					let musicianHarp = makeArray(album.data().musicianHarp)
					await updateDoc(albumRef, {musicianHarpArr: musicianHarp})
				}
				if(album.data().musicianHorns !== undefined && album.data().musicianHorns != ''){
					let musicianHorns = makeArray(album.data().musicianHorns)
					await updateDoc(albumRef, {musicianHornsArr: musicianHorns})
				}
				if(album.data().musicianOrgan !== undefined && album.data().musicianOrgan != ''){
					let musicianOrgan = makeArray(album.data().musicianOrgan)
					await updateDoc(albumRef, {musicianOrganArr: musicianOrgan})
				}
				if(album.data().musicianPercussion !== undefined && album.data().musicianPercussion != ''){
					let musicianPercussion = makeArray(album.data().musicianPercussion)
					await updateDoc(albumRef, {musicianPercussionArr: musicianPercussion})
				}
				if(album.data().musicianPiano !== undefined && album.data().musicianPiano != ''){
					let musicianPiano = makeArray(album.data().musicianPiano)
					await updateDoc(albumRef, {musicianPianoArr: musicianPiano})
				}
				if(album.data().musicianSaxAlto !== undefined && album.data().musicianSaxAlto != ''){
					let musicianSaxAlto = makeArray(album.data().musicianSaxAlto)
					await updateDoc(albumRef, {musicianSaxAltoArr: musicianSaxAlto})
				}
				if(album.data().musicianSaxBaryton !== undefined && album.data().musicianSaxBaryton != ''){
					let musicianSaxBaryton = makeArray(album.data().musicianSaxBaryton)
					await updateDoc(albumRef, {musicianSaxBarytonArr: musicianSaxBaryton})
				}
				if(album.data().musicianSaxSoprano !== undefined && album.data().musicianSaxSoprano != ''){
					let musicianSaxSoprano = makeArray(album.data().musicianSaxSoprano)
					await updateDoc(albumRef, {musicianSaxSopranoArr: musicianSaxSoprano})
				}
				if(album.data().musicianSaxTenor !== undefined && album.data().musicianSaxTenor != ''){
					let musicianSaxTenor = makeArray(album.data().musicianSaxTenor)
					await updateDoc(albumRef, {musicianSaxTenorArr: musicianSaxTenor})
				}
				if(album.data().musicianString !== undefined && album.data().musicianString != ''){
					let musicianString = makeArray(album.data().musicianString)
					await updateDoc(albumRef, {musicianStringArr: musicianString})
				}
				if(album.data().musicianSitar !== undefined && album.data().musicianSitar != ''){
					let musicianSitar = makeArray(album.data().musicianSitar)
					await updateDoc(albumRef, {musicianSitarArr: musicianSitar})
				}
				if(album.data().musicianTrombone !== undefined && album.data().musicianTrombone != ''){
					let musicianTrombone = makeArray(album.data().musicianTrombone)
					await updateDoc(albumRef, {musicianTromboneArr: musicianTrombone})
				}
				if(album.data().musicianTrumpet !== undefined && album.data().musicianTrumpet != ''){
					let musicianTrumpet = makeArray(album.data().musicianTrumpet)
					await updateDoc(albumRef, {musicianTrumpetArr: musicianTrumpet})
				}
				if(album.data().musicianTuba !== undefined && album.data().musicianTuba != ''){
					let musicianTuba = makeArray(album.data().musicianTuba)
					await updateDoc(albumRef, {musicianTubaArr: musicianTuba})
				}
				if(album.data().musicianVibraphone !== undefined && album.data().musicianVibraphone != ''){
					let musicianVibraphone = makeArray(album.data().musicianVibraphone)
					await updateDoc(albumRef, {musicianVibraphoneArr: musicianVibraphone})
				}
				if(album.data().musicianVocal !== undefined && album.data().musicianVocal != ''){
					let musicianVocal = makeArray(album.data().musicianVocal)
					await updateDoc(albumRef, {musicianVocalArr: musicianVocal})
				}
				else {console.log(album.data().Artist + ' - ' + album.data().Album, 'mangler data!')}
			})
			console.log('%c Updates in arrays is complete for every album', 'color:yellow')
		}
		// Button
		const copyArtistsToConfig = async () => {
			const ref = collection(db, 'artist')
			const snap = await getDocs(ref)
			let artistNameArr = []
			let artistAliasArr = []
			let artistIDArr = []
			snap.forEach((doc) => {
				artistNameArr.push(doc.data().name)
				artistAliasArr.push(doc.data().alias)
				artistIDArr.push(doc.id)
			})
			console.log("Config collection updated");
			console.log('artistNameArr: ', artistNameArr)
			console.log('artistAliasArr: ', artistAliasArr)
			console.log('artistIDArr: ', artistIDArr)
			
			const update = await setDoc(doc(db, "config", "allArtists"),{
				artistID: artistIDArr,
				artistAlias: artistAliasArr,
				artistName: artistNameArr

			})
		}
		onMounted( async () => {
			console.log('%c user', 'color:rgb(21, 189, 81);font-size:1.2em', props.user)
			const guard = await guardRoute(props.user) // guard: boolean
		})
		
        return { syncPlaylist, guardRoute,
			makeUserCollectedArray, copyCollectedFromAlbumToUserCollection, copyReviewsFromAlbumToUserCollection, clearReviews,        
			arrayToAvg, updateAverage, syncAverage, copyArtistsToConfig, makeAlbumArrays, makeArtistArray, updateAlbumReviews
        }
    }
}
</script>

<style scoped>
@media (prefers-color-scheme: dark) {
	.container form,
	.adminModule{
		background: #2d2d2d;
	}
}
.container form, 
.container .adminModule{
    padding: 20px;
    border-radius: 20px;
	margin-bottom: 20px;
}
form label, form input, form select {
    display: block;
}
form input, form select {
    padding: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 250px;
    height: 40px;
    font-size: 1em;
}
.tooltiptext {
	width: 250px;
	left: 15%;
	height: fit-content;
	z-index: 1000;
}

.adminModule {
	margin: 100px auto;
	display: grid;
	grid-template-columns: repeat( auto-fit, minmax(250px, 1fr));
}
form select{
    width: 262px;
}
</style>