<template>
	<!-- <button @click="toggleModal">Toggle Modal</button> -->
	<teleport to="#modals">
		<Transition name="fade" appear>
		<div class="modal-overlay" @click.self="toggleModal">
				<div :class="{ modal: true, loginBox: true, shadowHigh: !isHovered, shadowMedium: isHovered}" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false" >
					<!-- PayPal -->
					<h1>MODAL</h1>
					<a href="https://www.paypal.com/donate/?business=VT7JURACNWSA6&no_recurring=0&item_name=Please+support+my+work+to+further+develop+this+web+site.+Dedicated+to+the+Masters+of+Jazz+and+your+Vinyl+Collection&currency_code=USD">Paypal Link</a>
					
					<div class="donate_wrapper">
						<div>
							<div>
								<div class="donate_sub_header donate-main-content donate-main-content-with-no-margin css-1gyuotj-grid_container"
									data-ppui-info="grid_3.2.9">
									<div class="donate-to css-1v26bvb-row-justify_content_center" data-ppui-info="grid_3.2.9"
										style="margin-top: 1.5rem;">
										<div class="title css-13xeeyi-col_12" data-ppui="true">
											<div class="title-primary no-margins text-center css-16jt5za-text_body" data-ppui-info="body-text_6.5.6">Doner til</div>
										</div>
									</div>
									<div class="css-s8u2ug-row" data-ppui-info="grid_3.2.9">
										<div class="css-13xeeyi-col_12" data-ppui="true">
											<div class="text-center"
												style="display: flex; justify-content: center; align-items: center;">
												<div class="name no-margins org-name css-ttr2i5-text_heading_sm" data-ppui-info="heading-text_6.5.6" id="donation-name">Sune Magnus Fjell</div>
											</div>
										</div>
									</div>
									<div class="landing-purpose css-16jt5za-text_body" data-ppui-info="body-text_6.5.6">Please support my work to further develop this web site. Dedicated to the Masters of Jazz and your Vinyl Collection</div>
								</div>
							</div>
						</div>
						<div class="container">
							<div class="amount-wrapper">
								<div class="amount no-error">
									<div dir="ltr" tabindex="0">
										<div class=" css-a3d16k-base-center_aligned" id="myHeroCurrency"
											data-ppui-info="hero-currency_2.4.6">
											<div class="css-1n54md7-text_input_base-text_body-text_input"
												data-ppui-info="text-input_5.1.13">
												<div data-ppui="true" class="css-5ar10o-prefix_wrapper">
													<input
														class="css-mn3gu8-text_input_control-text_body-label_placeholder_shown_and_not_focused-text_body-prefix_wrapper_control-prefix_wrapper_control_with_prefix"
														name="myHeroCurrency" id="text-input-myHeroCurrency"
														aria-invalid="false" placeholder=" "
														aria-labelledby="text-input-myHeroCurrency-label text-input-myHeroCurrency-prefix"
														data-ppui-info="currency-input_2.0.28" inputmode="decimal"
														autocomplete="off" tabindex="0" data-ppui="true" value="">
													<label
														for="text-input-myHeroCurrency" id="text-input-myHeroCurrency-label"
														class="css-7nxsij-label-text_field_label_sm" data-ppui="true">Enter
														amount
													</label>
													<div id="text-input-myHeroCurrency-prefix"
														class="css-1g7s8lo-prefix-prefix_suffix-prefix_suffix" data-ppui="true">USD
													</div>
												</div>
											</div>
											<div data-component="hero_currency--view" aria-hidden="true" data-ppui="true"
												class="css-2m8vzz-view-center_view">
												<span data-ppui="true" class="css-53ylvx-symbol">USD</span>
												<span data-ppui="true" class="css-td18kz-entered"></span>
												<span data-ppui="true" class="css-xyvth4-implied_default">0</span>
											</div>
										</div>
										<div class="styles-module_currencyAlign__4IMaI css-1yo2lxy-text_body_strong"
											data-ppui-info="body-text_6.5.6" tabindex="0">USD
										</div>
									</div>
								</div>
							</div>
							<div class="css-s8u2ug-row" data-ppui-info="grid_3.2.9">
								<div class="checkbox-bottom-margin " id="recurringCheckboxContainer">
									<div class="css-1nhh7rf-col" data-ppui="true">
										<div class="padding-0">
											<div>
												<div class="_aivvkc">
													<div class="_1rl56dw" id="animationWrapper" style="display: none;">
														<div class="_v0vxb8" id="animationContainer">
														</div>
													</div>
													<div class="_5kaapu">
														<div class="inline-block css-11m2iee-checkbox_base" data-id="checkbox"
															data-ppui-info="checkbox_4.6.3">
															<input type="checkbox" id="recurringCheckbox"
																name="recurringCheckbox" data-ppui="true"
																class="css-161q2ca-checkbox_input" value="false">
															<label for="recurringCheckbox" data-ppui="true"
																class="css-12v4qsp-label-text_body">
																<span data-ppui="true" class="css-bzuo57-checkbox_items">
																	<span data-ppui="true"
																		class="css-109pikv-checkmark_container">
																		<span class="css-1bt5b0y-svg-size_xs-check_icon"
																			data-ppui-info="icons_8.17.0" data-ppui="true">
																			<svg xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor" viewBox="0 0 18 18"
																				width="1em" height="1em" data-ppui="true">
																				<path fill-rule="evenodd"
																					d="M7.69 11.474l5.517-7.19a1 1 0 0 1 1.586 1.218l-6.22 8.107a1 1 0 0 1-1.51.089l-3.35-3.44a1 1 0 1 1 1.433-1.396l2.544 2.612z"
																					clip-rule="evenodd" data-ppui="true"></path>
																			</svg>
																		</span>
																	</span>
																	<span data-ppui="true" class="css-bzuo57-checkbox_items">
																		<span data-ppui="true" class="css-1nvqybl-item_text">
																			<span class="css-2f2zb4-text_body"
																				data-ppui-info="body-text_6.5.6"
																				data-ppui="true">Doner dette beløpet månedlig.
																			</span>
																		</span>
																	</span>
																</span>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div class="mb-16">
									<button class="btn-wide next-button css-6uv1f6-button_base-text_button_lg-branded"
										data-ppui-info="buttons_7.7.4" id="payWithPP" pa-marked="1">
										<img src="https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg"
											style="height: 24px; width: 24px; margin-right: 8px;">Doner med PayPal
									</button>
								</div>
								<button class="btn-wide css-5jli6g-button_base-text_button_lg-btn_full_width-secondary"
									data-ppui-info="buttons_7.7.4" id="payWithGuest" pa-marked="1">
									Doner med betalingskort
								</button>
							</div>
						</div>
					</div>


					<span @click="toggleModal" class="close-btn material-icons">cancel</span>
				</div>
			</div>
		</Transition>
	</teleport>
</template>
  
<script>
import { ref } from 'vue'
export default {
	emits: ['close'],
	setup(props, context) {
		const isHovered = ref(false)
		// const showModal = ref(false)

		const toggleModal = () => {
			// console.log('showModal:', showModal.value)
			// showModal.value = !showModal.value
			// context.emit('close')
			context.emit('close')
			// console.log('emit: ', context.emit)
		}
		// const close = () => {
		// 	this.$emit('close');
		// }

		return { toggleModal, isHovered }
	},
}
</script>

<style scoped>
	.modal {
		/* box-shadow: 2px 2px 4px 2px; */
		height: 500px;
		width: 500px;
		margin-top: 10%;
		margin: auto 20px;
		padding: 20px;
	}


	.fade-enter-active .modal{
		animation: bounce-in 1s;
	}
	.fade-leave-active .modal{
		animation: bounce-in 10.5s;
	}
	.fade-enter-from,
	.fade-leave-to{
		opacity: 0;
	}

	@keyframes bounce-in {
		0% {
			transform: scale(0);
		}
		50% {
			transform: scale(1.2);
		}
		100% {
			transform: scale(1);
		}
	}

	.donate_wrapper {
		padding-top: 24px;
		padding-bottom: 48px;
		margin-left: auto;
		margin-right: auto;
		background-color: transparent;
		width: 100%;
		max-width: 618px
	}

	@media only screen and (max-width: 576px) {
		.donate_wrapper {
			padding: 0
		}
	}

	.donate_wrapper .container {
		width: 100%;
		padding: 0 80px;
		margin-top: 12px
	}

	@media only screen and (max-width: 576px) {
		.donate_wrapper .container {
			padding: 0 16px
		}
	}

	.donate_wrapper.campaigns.is-landing {
		max-width: 1152px;
		padding: 0
	}

	.donate_wrapper.campaigns.is-landing .container {
		padding: 0 24px
	}

	.donate_wrapper .landing-programs {
		margin-bottom: 33px
	}

	.donate_wrapper .gn_donate_wrapper {
		padding-bottom: 56px;
		background-color: #fff;
		border-radius: .75rem;
		border: 1px solid #dbd8d0;
		position: relative;
		overflow: inherit
	}

	.donate_wrapper .gn_donate_wrapper .banner-image {
		border-top-right-radius: .75rem;
		border-top-left-radius: .75rem
	}

	@media only screen and (max-width: 576px) {
		.donate_wrapper .gn_donate_wrapper {
			border: 0
		}
	}

	.donate_wrapper .gn_donate_wrapper .user-details-banner {
		margin-bottom: 0
	}

	.donate_wrapper .gn_donate_wrapper .success_content {
		margin: 0 80px;
		word-break: break-word
	}

	@media (max-width: 420px) {
		.donate_wrapper .gn_donate_wrapper .success_content {
			padding: 0 12px
		}
	}

	@media only screen and (max-width: 576px) {
		.donate_wrapper .gn_donate_wrapper .success_content {
			margin: 0 16px
		}
	}

	.donate_wrapper .gn_donate_wrapper .success_subheader {
		margin-bottom: 24px;
		font-size: 1.1rem;
		color: #0c0c0d
	}

	.donate_wrapper .gn_donate_wrapper .done-link {
		margin-top: 24px
	}

	.donate_wrapper .gn_donate_wrapper .client_note_wrapper>label {
		padding-left: 0
	}

	.donate_wrapper .gn_donate_wrapper fieldset {
		margin-left: 0
	}

	.donate_wrapper .gn_donate_wrapper .text-padding-top {
		padding-top: 24px
	}

	.donate_wrapper .gn_donate_wrapper .donate_banner_wrapper .banner_img_holder img {
		margin-top: 37px;
		margin-bottom: 24px
	}

	.donate_wrapper .gn_donate_wrapper .landing-programs button {
		border: 1px solid #c6c6c6
	}

	.donate_wrapper .donate_wrapper {
		padding: 48px 0;
		background-color: #fff;
		border-radius: .75rem;
		border: 1px solid #dbd8d0;
		position: relative
	}
</style>