<template>
  	<teleport to="#modals">
		<Transition name="fade" appear>
			<div class="modal-overlay" @click.self="$emit('close')" aria-label="Close modal">
				<div :class="{ modal: true, loginBox: true, shadowHigh: !isHovered, shadowMedium: isHovered}" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false" >
					<h3>{{ album.Artist }}: {{ album.Album }} ({{ album.Year }})</h3>
					<section class="modal-body" id="modalDescription">
						<h2 v-if="!userCollected.albumId && !userCollected.updated">Add to Collection</h2>
						<h2 v-else>Update Collection</h2>
						<h3>{{ album.Artist }}</h3>
						<h3>{{ album.Album }} ({{ album.Year }})</h3>
						<br>
						<form @submit.prevent="newCollection(userCollected)">
							<label for="ordered">Date Ordered</label>
							<input id="ordered" type="date" placeholder="Ordered" v-model="userCollected.collectionOrdered" @input="syncDates($event)" onload="getToday()">
							<label for="collected">Date Collected</label>
							<input id="collected" type="date" placeholder="Collected" v-model="userCollected.collectionCollected" @input="syncDates($event)">
							<label for="sum">Price you paid</label>
							<input id="sum" type="number" placeholder="Price" v-model="userCollected.Sum">
							<label for="butikk">Store Name</label>
							<input id="butikk" type="text" placeholder="Store" v-model="userCollected.Butikk">
							<button v-if="!userCollected.albumId">Add to {{ user.name }}'s Collection</button>
						</form>
						<div v-if="userCollected.albumId" class="buttons">
							<button @click="updateCollection(userCollected)">Update Collection</button>
							<button @click="deleteCollection(userCollected)">Remove from Collection</button>
						</div>
					</section>
					<span @click="$emit('close')" class="close-btn material-icons" aria-label="Close modal">cancel</span>	
				</div>
			</div>
		</Transition>
  	</teleport>
</template>

<script>
import { onMounted, ref } from 'vue'
import { timestampToInput, unixtimeToLongdate } from '@/composables/timestampConverter'
import { formatDistanceToNow } from 'date-fns'
import { arrayRemove, arrayUnion, deleteDoc, doc, getDoc, serverTimestamp, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '@/firebase/config';


export default {
	emits: ['close','new-collected', 'update-collected', 'delete-collected'],
	props: ['album', 'user'],
	setup(props, context) {
		// ref to take care of converting timestamp to/from input-date (2024-11-25)
		// const collectionOrdered = ref(null)
		// const collectionCollected = ref(null)
		const isHovered = ref(false)

		// This file only
		const userCollected = ref({})

		const getToday = () => {
			let today = new Date()
			let inpDate = timestampToInput(today)
			console.log('inpDate: ', inpDate)
			return inpDate
		}
		const syncDates = (evt) => {
			const targetId = evt.target.id
			const val = evt.target.value
			if(targetId === 'ordered'){
				// make custom date for faking firestore timestamp
				const firestoreTimestamp = Timestamp.fromDate(new Date(val))
				// Update time with fake firestore timestamp
				userCollected.value.Ordered = firestoreTimestamp
			}
			if(targetId === 'collected'){
				// make custom date for faking firestore timestamp
				const firestoreTimestamp = Timestamp.fromDate(new Date(val))
				// Update time with fake firestore timestamp
				userCollected.value.Collected = firestoreTimestamp
			}
		}
		
		const updateCollection = async (userCollected) => {
			console.log('updateCollection()', userCollected)
			// Update userReview
			let updatedData = {...userCollected }
			delete updatedData.timeUpdated
			delete updatedData.timeCreated
			updatedData.updated = serverTimestamp()
			
			// Update Firestore Review
			const collectionRef = doc(db, 'users', props.user.id, 'collection', props.album.id)
			const updatedCollect = await updateDoc(collectionRef, updatedData, { merge: true })

			// make custom date for faking firestore timestamp
			updatedData.updated = Timestamp.fromDate(new Date())

			// Emit data to grandparent (Album.vue)
			context.emit('update-collected', updatedData, props.user)
			
			// Close Modal
			context.emit('close')
			
			// Log results
			console.log('%c Updated Review ','color:white;font-size:1.2em;background:orange', updatedData)
		}

		const deleteCollection = async (userCollected) => {
			console.log('deleteCollection', userCollected)
			// Delete Firestore Review
			const collectionRef = doc(db, 'users', props.user.id, 'collection', props.album.id)
			await deleteDoc(collectionRef)
			
			// Update Firestore User
			const userRef = doc(db, 'users', props.user.id)
			await updateDoc(userRef, {
				collected: arrayRemove(props.album.id)
			})
			// // Remove review from reviews array
			// let objIndex = props.reviews.map((temp) => temp['reviewID']).indexOf(review.reviewID)
			// props.reviews.splice(objIndex, 1)

			// Update review icon 
			let pos = props.user.collected.indexOf(props.album.id)
			// console.log('pos: ', pos)
			// console.log('props.user.collected before delete: ', props.user.collected)
			let updatedUser = {...props.user}
			updatedUser.collected.splice(pos, 1)
			// console.log('props.user.collected after delete: ', props.user.collected)

			
			// Emit changes to userReview
			context.emit('delete-collected', userCollected, updatedUser)
			
			// Log Results & Close Modal 
			// console.log('%c Deleted from Collection ', 'color:yellow;font-size:1.2em;background: red', userCollected)
			context.emit('close')
		}

		const newCollection = async (userCollected) =>{
			// console.log('%c userCollected ', 'color:blue', userCollected)
			// Build data
			const data = {}
			if (userCollected.collectionOrdered !== undefined && userCollected.collectionOrdered !== ''){ 
				data.Ordered = new Date (userCollected.collectionOrdered)
			}
			if (userCollected.collectionCollected !== undefined && userCollected.collectionCollected !== ''){ 
				data.Collected = new Date (userCollected.collectionCollected) 
			}
			if (userCollected.Butikk !== undefined && userCollected.Butikk !== ''){ data.Butikk = userCollected.Butikk }
			if (userCollected.Sum !== undefined && userCollected.Sum !== ''){ data.Sum = Number(userCollected.Sum) }
			data.created = serverTimestamp()
			data.Album = props.album.Album
            data.albumId = props.album.id
			// console.log('%c newCollection ', 'color:green', data)

			// Create Firestore Doc in collected
			const collectionRef = doc(db, 'users', props.user.id, 'collection', props.album.id)
			const newAlbumCollect = await setDoc(collectionRef, data)
			data.collectedID = props.album.id
            // console.log(props.album.Artist + ': ' + props.album.Album + ' written to user collection')
			
			// Update Firestore User
			const userRef = doc(db, 'users', props.user.id)
			await updateDoc(userRef, {
				collected: arrayUnion(data.albumId)
			})

			// make custom date for faking firestore timestamp
			if(userCollected.collectionOrdered){ data.Ordered = Timestamp.fromDate(new Date(userCollected.collectionOrdered))}
			if(userCollected.collectionCollected){ data.Collected = Timestamp.fromDate(new Date(userCollected.collectionCollected))}
			data.created = Timestamp.fromDate(new Date())
			// console.log('data.created: ', data.created)

			// Modify User Obj
			let updatedUser = {...props.user}
			updatedUser.collected.push(data.albumId)
	
			// Emit data to grandparent (Album.vue)
			context.emit('new-collected', data, updatedUser)
			
			// Log Results & Close Modal
			// console.log('%c New Collected ','color:white;font-size:1.2em;background: green', data)
			context.emit('close')
		}

		const getCollectedAlbum = async () => {
			// Check for user, even though only users can click button
			if(props.user.id != null) {
				let collected = props.user.collected
				// console.log('%c user collected', 'color:purple;font-size:1.2em', collected)
				const collectedRef = doc(db, 'users', props.user.id, "collection", props.album.id)
				const collectDoc = await getDoc(collectedRef)
				if (collectDoc.exists()){
					// Build data
					let data = {}
					data.albumId = props.album.id
					data.Album = props.album.Album  // Optional
					// If Orderd exists
					if (collectDoc.data().Ordered !== undefined && collectDoc.data().Ordered !== ''){
						data.collectionOrdered = timestampToInput(collectDoc.data().Ordered)
						data.Ordered = collectDoc.data().Ordered
					// Orderd doesn't exists
					} else {
						const firestoreTimestamp = Timestamp.fromDate(new Date())
						const inputDate = timestampToInput(firestoreTimestamp)
						data.collectionOrdered = inputDate
					}
					// If Collected exists
					if (collectDoc.data().Collected !== undefined && collectDoc.data().Collected !== ''){
						data.collectionCollected = timestampToInput(collectDoc.data().Collected)
						data.Collected = collectDoc.data().Collected
					// Collected doesn't exists
					} else {
						const firestoreTimestamp = Timestamp.fromDate(new Date())
						const inputDate = timestampToInput(firestoreTimestamp)
						data.collectionCollected = inputDate
					}
					// If Butikk exists
					if (collectDoc.data().Butikk !== undefined && collectDoc.data().Butikk !== ''){ data.Butikk = collectDoc.data().Butikk }
					// If Sum exists
					if (collectDoc.data().Sum !== undefined && collectDoc.data().Sum !== ''){ data.Sum = Number(collectDoc.data().Sum) }

					if(collectDoc.data().created){ 
						data.timeCreated = formatDistanceToNow(collectDoc.data().created.toDate()) 
						data.created = collectDoc.data().created
					}
					if(collectDoc.data().updated){ 
						data.timeUpdated = formatDistanceToNow(collectDoc.data().updated.toDate()) 
						data.updated = collectDoc.data().updated
					}
					// Update userCollected array
					userCollected.value = data
					console.log('%c User has this album in his collection', 'color:lime;font-size:1.2em', userCollected)
				// Not in user collection => reset userCollected
				} else { // No Document found!!!
					console.log('%c Not found in user collection!', 'color: red')
					userCollected.value = {}
					const today = timestampToInput(Timestamp.fromDate(new Date()))
					userCollected.value.collectionOrdered = today
					userCollected.value.collectionCollected = today
				}
			// No active user
			} else {
				console.log('%c No active user!', 'color: red')
				userCollected.value = {}
			}
		}

		onMounted(()=> {
			getCollectedAlbum()
		})
		
		return { newCollection, timestampToInput, 
			syncDates, newCollection, updateCollection, deleteCollection, getToday, unixtimeToLongdate, 
			isHovered, getCollectedAlbum, userCollected
			
		}
	}
}
</script>

<style scoped>
.modal-body label {
	text-align: center;
}
.modal-body input {
	width: 100%;
	margin: 0 auto 10px;
	height: 40px;
}
.modal-body button {
	display: block;
	font-size: 0.9em;
	width: 100%;
	margin: 20px auto;
}
</style>