<template>
  	<teleport to="#modals">
		<Transition name="fade" appear>
			<div class="modal-overlay" @click.self="toggleModal" aria-label="Close modal">
				<div :class="{ modal: true, shadowHigh: !isHovered, shadowMedium: isHovered}"
					role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false">
					<!-- <section class="modal-picture">
						<img v-if="review.albumData.DiscogsID" :src="require('../assets/covers/' + review.albumData.albumID + '.jpg')" 
							:alt="review.albumData.Album" loading="lazy"
							class="bilde"
							@click="toggleAlbum(review)"
							:id="review.albumData.id">
					</section> -->

					<h1>Edit Comment</h1>

					<header class="modal-header" id="modalTitle">
						<h3 v-if="review.reviewComment">{{review.reviewComment}}</h3>
					</header>
					
					<section class="modal-body" id="modalDescription">
						<form @submit.prevent="editActiveComment(review)">
							<textarea v-if="review.reviewComment" v-model="review.reviewComment"></textarea> 
							<button>Update Comment</button>
						</form>
						<button @click="deleteComment(review)">Delete Comment</button>
						<!-- <input v-if="review.reviewComment">{{ review.reviewComment }} </input> -->
						<!-- <h3 v-if="review.albumTitle">{{ review.albumTitle }}</h3> -->
					</section>
					<!-- <section class="modal-body" id="modalDescription">
						<h3 v-if="review.albumTitle">{{ review.albumTitle }}</h3>
						<p class="reviewBody" v-if="review.reviewBody">{{ review.reviewBody }}</p>
						<div class="metadata">
							<p v-if="review.userName">Written by <strong>{{ review.userName }}</strong> {{ timeConverter(review.created) }}</p>
						</div>
					</section>

					<footer class="modal-footer">
						<button @click="deleteReview(review)">Delete Review?</button>
						<slot name="footer"></slot>
					</footer> -->
					<!-- Close button -->
					<span @click="toggleModal" class="close-btn material-icons" aria-label="Close modal">cancel</span>
					
				</div> <!-- MODAL END -->
			</div>
		</Transition>
  	</teleport>
</template>

<script>
import { ref } from 'vue'
import { auth, db } from '@/firebase/config'
import { doc, deleteDoc, updateDoc, serverTimestamp, setDoc } from 'firebase/firestore'

export default {
	emits: ['close'],
	props: ['review'],
	setup(props, context) {
		const isHovered = ref(false)

		const toggleModal = () => {
			context.emit('close')
			console.log(props.review)
		}
		// Delete record
		const deleteComment = async(review) => {
			console.log('review: ', review)
			// Delete review
			const docRef = doc(db, 'reviews', review.reviewID, 'comments', review.commentID)
			deleteDoc(docRef)
			console.log( 'Removed comment', review.commentID, 'from album albumReviews')
			toggleModal()
			// const docRef = doc(db, 'reviews', review.id)
			// deleteDoc(docRef)
			// // Update album
			// const albumRef = doc(db, 'Jazz', review.albumID)
			// await updateDoc(albumRef, {
			// 	reviews: arrayRemove(review.id)
			// })
			// console.log( 'Removed review', review.id, 'from album albumReviews')
			// // Update User 
			// const userRef = doc(db, 'users', review.userID)
			// await updateDoc(userRef, {
			// 	reviews: arrayRemove(review.albumID),
			// 	reviewIDs: arrayRemove(review.id)
			// })
			// console.log( 'Removed review', review.id, 'from user reviews')
			// toggleModal()
			// console.log(review.albumTitle, 'deleted from reviews collection')
		}

		// Edit record
		const editActiveComment = async(review) => {
			console.log('Edit Comment:', review)

			const data = {}
			// review.reviewComment
			if (review.reviewComment !== undefined && review.reviewComment != ''){
				data.reviewComment = review.reviewComment
			} else {updateDoc(reviewComment, {reviewComment: deleteField()})}
			// if (review.reviewBody !== undefined && review.reviewBody != ''){
			// 	data.reviewBody = review.reviewBody
			// } else {updateDoc(reviewBody, {reviewBody: deleteField()})}
			// if (review.reviewRating !== undefined && review.reviewRating != ''){
			// 	data.reviewRating = review.reviewRating
			// } else {updateDoc(reviewRating, {reviewRating: deleteField()})}

			data.updated = serverTimestamp()
			
			console.log('Edit Data:', data)
			const user = ref(auth.currentUser)
			console.log('USER:', user)


			
			const docRef = doc(db, 'reviews', review.reviewID, 'comments', review.commentID)
			await setDoc(docRef, data, { merge: true })
            .then (docRef => {
                console.log('Updated Document')
				toggleModal()
            })
            .catch(error =>{
                console.log(error)
            })
		}

		const makeArray = (string) => {
            let array = string.split(', ')
            return array
        }
		const dateConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit"} )
				return newdate
            }
        } 
		const timeConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit", hour:"numeric", minute:"2-digit", second:"2-digit"} )
				return newdate
            }
        } 

		return { makeArray, toggleModal, timeConverter, dateConverter, deleteComment, editActiveComment, isHovered
		}
	}
}
</script>

<style scoped>
	.modal {
		height: 50vh;
	}
	.modal-header,
	.modal-footer {
		padding: 15px;
		display: flex;
	}
	.modal-picture{
		height: fit-content;
		width: 300px;
		margin: 10px;
	}
	.modal-body {
		text-align: center;
	}
	.modal-body textarea{
		width: 90%;
		height: 120px;
		font-size: 1.2em;
		padding: 10px;
		margin: auto;
	}
</style>