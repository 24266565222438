import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import InstantSearch from 'vue-instantsearch/vue3/es';

// Fungerende versjon
const app = createApp(App);
app.use(router);
app.use(InstantSearch);
app.mount('#app');

// Ny Versjon
// import { auth } from './firebase/config';
// let app
// auth.onAuthStateChanged(() => {
// 	if(!app){
// 		app = createApp(App);
// 		app.use(router);
// 		app.use(InstantSearch);
// 		app.mount('#app');
// 	}
// })