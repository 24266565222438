import { db } from "@/firebase/config"
import { collection, doc, getDoc, getDocs, serverTimestamp, updateDoc } from "firebase/firestore"

export const syncPlaylist = async (userId) => {
	const docRef = doc(db, 'users', userId)
	const subColRef = collection(docRef, 'playlist')
	const snapshot = await getDocs(subColRef);
	snapshot.forEach( async ( playlistItem ) => {
		const albumRef = doc(db, 'Jazz', playlistItem.data().albumId)
		const albumSnap = await getDoc( albumRef )
		if (albumSnap.exists()) {
			const playRef = doc(db, 'users', userId, 'playlist', playlistItem.id)

			const updateFirestore = await updateDoc(playRef, {
				artist: albumSnap.data().Artist,
				album: albumSnap.data().Album,
				year: albumSnap.data().Year,
				updated: serverTimestamp()
			})
			console.log('%c playlistItem updated: ', 'color:DarkSlateBlue;font-size:1.4em', playlistItem.id, albumSnap.data().Album)
		}
	});
}