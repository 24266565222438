<template>
  	<teleport to="#modals">
		<Transition name="fade" appear>
			<div class="modal-overlay" @click.self="toggleModal" aria-label="Close modal">
				<div :class="{ modal: true, shadowHigh: !isHovered, shadowMedium: isHovered}" 
					role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false">
					<section class="modal-picture">
						<img v-if="review.albumData.DiscogsID" :src="require('@/assets/covers/' + review.albumData.albumID + '.jpg')" 
							:alt="review.albumData.Album" loading="lazy"
							class="bilde"
							@click="toggleAlbum(review)"
							:id="review.albumData.id">
					</section>

					<header class="modal-header" id="modalTitle">
						<h3 v-if="review.reviewHeader">{{review.reviewHeader}}</h3>
					</header>

					<section class="modal-body" id="modalDescription">
						<h3 v-if="review.albumTitle">{{ review.albumTitle }}</h3>
						<p class="reviewBody" v-if="review.reviewBody">{{ review.reviewBody }}</p>
						<div class="metadata">
							<p v-if="review.userName">Written by <strong>{{ review.userName }}</strong> {{ timeConverter(review.created) }}</p>
						</div>
					</section>

					<footer class="modal-footer">
						<button @click="deleteReview(review)">Delete Review?</button>
						<slot name="footer"></slot>
					</footer>
					<!-- Close button -->
					<span @click="toggleModal" class="close-btn material-icons" aria-label="Close modal">cancel</span>
					
				</div> <!-- MODAL END -->
			</div>
		</Transition>
  	</teleport>
</template>

<script>
import { db } from '@/firebase/config'
import { doc, deleteDoc, updateDoc, arrayRemove } from 'firebase/firestore'
import { ref } from 'vue'

export default {
	emits: ['close'],
	props: ['review'],
	setup(props, context) {
		const isHovered = ref(false)

		const toggleModal = () => {
			context.emit('close')
			console.log(props.review)
		}
		// Delete record
		const deleteReview = async(review) => {
			// Delete review
			const docRef = doc(db, 'reviews', review.id)
			deleteDoc(docRef)
			// Update album
			const albumRef = doc(db, 'Jazz', review.albumID)
			await updateDoc(albumRef, {
				reviews: arrayRemove(review.id)
			})
			console.log( 'Removed review', review.id, 'from album albumReviews')
			// Update User 
			const userRef = doc(db, 'users', review.userID)
			await updateDoc(userRef, {
				reviews: arrayRemove(review.albumID),
				reviewIDs: arrayRemove(review.id)
			})
			console.log( 'Removed review', review.id, 'from user reviews')
			toggleModal()
			console.log(review.albumTitle, 'deleted from reviews collection')
		}

		const makeArray = (string) => {
            let array = string.split(', ')
            return array
        }
		const dateConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit"} )
				return newdate
            }
        } 
		const timeConverter = (timeStamp) => {
            if(timeStamp){
				let date = new Date(timeStamp.toDate())
				let newdate = date.toLocaleString("en-UK", {year:"numeric", month: "long", day:"2-digit", hour:"numeric", minute:"2-digit", second:"2-digit"} )
				return newdate
            }
        } 

		return { makeArray, toggleModal, timeConverter, dateConverter, deleteReview, isHovered
		}
	}
}
</script>

<style scoped>
.modal {
	max-height: 80vh;
	min-width: 40vw;
	max-width: 50vw;
	margin: 30px 20px;
}
.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}
.modal-picture{
	height: fit-content;
	width: 300px;
	margin: 10px;
}
</style>