export function checkTimestamp(timestamp) { //dateConverterUK
	// console.log('%c timestamp', 'color:gold;font-size:1.2em',  timestamp)
	const testdate = new Date(timestamp);
	// 1. Sjekke om det er en Unix-tid (13 tegn og bare tall)
	 if ((typeof timestamp === 'number') && 
	 timestamp.toString().length === 10 || timestamp.toString().length === 12 || timestamp.toString().length === 13) { // Nanoseconds format
	//  if ((typeof timestamp === 'number' && timestamp.toString().length <= 10) || // Seconds format
	//  (typeof timestamp === 'number' && timestamp.toString().length >= 13)) { // Nanoseconds format
		// console.log('%c UNIX Timestamp', 'color:RebeccaPurple;font-size:1.2em', timestamp.toString().length, '=>',timestamp)
		let date = new Date(timestamp);
		let newdate = date.toLocaleDateString(undefined, {year:"numeric", month: "long", day:"2-digit"});
		return newdate; // 24 July 2024

	}
    // 2. Sjekke om det er en Firestore Timestamp (et objekt med 'seconds' og 'nanoseconds')
	else if (typeof timestamp === 'object' && 'seconds' in timestamp && 'nanoseconds' in timestamp) {
		// console.log('%c Firestore Timestamp', 'color:orange;font-size:1.2em', {...timestamp})
		let date = new Date(timestamp.toDate());
		let newdate = date.toLocaleDateString(undefined, {year:"numeric", month: "long", day:"2-digit"});
		return newdate; // 24 July 2024

	}
	// 3. Sjekke om det er en datoformat (ISO 8601 string eller en gyldig Date-string)
	else if(!isNaN(testdate.getTime())) {
		// console.log('%c Legal Date Defined', 'color:RosyBrown;font-size:1.2em', timestamp, timestamp.toString().length, typeof timestamp)

	}
	else {
		// 4. Hvis det ikke passer noen av de ovennevnte
		// console.log('%c Unknown format', 'color:red;font-size:1.2em', timestamp, timestamp.toString().length, typeof timestamp, )
	}
}