<template>
	<div class="moduleContent">
		<div v-if="album.Musicians" class="musicianInfo">
			<div class="musicians">
				<ul v-if="album.musicianSaxAlto" id="SaxAlto">
					<li v-for="SaxAlto in makeArray(album.musicianSaxAlto)" :key="SaxAlto">
						Alto Saxophone <span class="strong" @click="goToArtist($event)">{{SaxAlto}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianSaxBaryton" id="SaxBaryton">
					<li v-for="SaxBaryton in makeArray(album.musicianSaxBaryton)" :key="SaxBaryton">
						Baryton Saxophone <span class="strong" @click="goToArtist($event)">{{SaxBaryton}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianBass" id="Bass">
					<li v-for="Bass in makeArray(album.musicianBass)" :key="Bass">
						Bass <span class="strong" @click="goToArtist($event)">{{Bass}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianClarinet" id="Clarinet">
					<li v-for="Clarinet in makeArray(album.musicianClarinet)" :key="Clarinet">
						Clarinet <span class="strong" @click="goToArtist($event)">{{Clarinet}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianConga" id="Conga">
					<li v-for="Conga in makeArray(album.musicianConga)" :key="Conga">
						Conga <span class="strong" @click="goToArtist($event)">{{Conga}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianCornette" id="Cornette">
					<li v-for="Cornette in makeArray(album.musicianCornette)" :key="Cornette">
						Cornette <span class="strong" @click="goToArtist($event)">{{Cornette}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianDrums" id="Drums">
					<li v-for="Drums in makeArray(album.musicianDrums)" :key="Drums">
						Drums <span class="strong" @click="goToArtist($event)">{{Drums}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianEuphonium" id="Euphonium">
					<li v-for="Euphonium in makeArray(album.musicianEuphonium)" :key="Euphonium">
						Euphonium <span class="strong" @click="goToArtist($event)">{{Euphonium}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianFlute" id="Flute">
					<li v-for="Flute in makeArray(album.musicianFlute)" :key="Flute">
						Flute <span class="strong" @click="goToArtist($event)">{{Flute}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianGuitar" id="Guitar">
					<li v-for="Guitar in makeArray(album.musicianGuitar)" :key="Guitar">
						Guitar <span class="strong" @click="goToArtist($event)">{{Guitar}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianHarp" id="Harp">
					<li v-for="Harp in makeArray(album.musicianHarp)" :key="Harp">
						Harp <span class="strong" @click="goToArtist($event)">{{Harp}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianHorns" id="Horns">
					<li v-for="Horns in makeArray(album.musicianHorns)" :key="Horns">
						Horns <span class="strong" @click="goToArtist($event)">{{Horns}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianOrgan" id="Organ">
					<li v-for="Organ in makeArray(album.musicianOrgan)" :key="Organ">
						Organ <span class="strong" @click="goToArtist($event)">{{Organ}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianPercussion" id="Percussion">
					<li v-for="Percussion in makeArray(album.musicianPercussion)" :key="Percussion">
						Percussion <span class="strong" @click="goToArtist($event)">{{Percussion}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianPiano" id="Piano">
					<li v-for="Piano in makeArray(album.musicianPiano)" :key="Piano">
						Piano <span class="strong" @click="goToArtist($event)">{{Piano}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianSitar" id="Sitar">
					<li v-for="Sitar in makeArray(album.musicianSitar)" :key="Sitar">
						Sitar <span class="strong" @click="goToArtist($event)">{{Sitar}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianSaxSoprano" id="SaxSoprano">
					<li v-for="SaxSoprano in makeArray(album.musicianSaxSoprano)" :key="SaxSoprano">
						Soprano Saxophone <span class="strong" @click="goToArtist($event)">{{SaxSoprano}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianString" id="String">
					<li v-for="String in makeArray(album.musicianString)" :key="String">
						Strings <span class="strong" @click="goToArtist($event)">{{String}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianSaxTenor" id="SaxTenor">
					<li v-for="SaxTenor in makeArray(album.musicianSaxTenor)" :key="SaxTenor">
						Tenor Saxophone <span class="strong" @click="goToArtist($event)">{{SaxTenor}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianTrombone" id="Trombone">
					<li v-for="Trombone in makeArray(album.musicianTrombone)" :key="Trombone">
						Trombone <span class="strong" @click="goToArtist($event)">{{Trombone}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianTrumpet" id="Trumpet">
					<li v-for="Trumpet in makeArray(album.musicianTrumpet)" :key="Trumpet">
						Trumpet <span class="strong" @click="goToArtist($event)">{{Trumpet}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianTuba" id="Tuba">
					<li v-for="Tuba in makeArray(album.musicianTuba)" :key="Tuba">
						Tuba <span class="strong" @click="goToArtist($event)">{{Tuba}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianVibraphone" id="Vibraphone">
					<li v-for="Vibraphone in makeArray(album.musicianVibraphone)" :key="Vibraphone">
						Vibraphone <span class="strong" @click="goToArtist($event)">{{Vibraphone}}</span>
					</li>
				</ul>
				<ul v-if="album.musicianVocal" id="Vocal">
					<li v-for="Vocal in makeArray(album.musicianVocal)" :key="Vocal">
						Vocal <span class="strong" @click="goToArtist($event)">{{Vocal}}</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { makeArray } from '@/composables/makeArray'
import { useRouter } from 'vue-router';
import { toKebab } from '@/composables/stringConverter'
export default {
	props: ['album'],
	setup(props) {
		const router = useRouter()

		const goToArtist = (evt) => {
			let clickedValue =  evt.target.textContent
			// let value = clickedValue.replace(' ', '')
			let value = toKebab(clickedValue)
			console.log('clickedValue: ', clickedValue)
			console.log('value: ', value)
			router.push(`/artist/${value}`) 
		}


	return { makeArray, goToArtist, router, toKebab}
	}

}
</script>

<style scoped>
	.musicians ul li {
		margin-bottom: 10px;
		list-style-type: none;
	}
	/* .musicians span{
		font-weight: bold;
	} */
</style>