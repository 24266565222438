<template>
    <div class="loginBox">
    <form @submit.prevent="handleSubmit" autocomplete="on">
        <h2>Log In</h2>

        <label for="email">Email:</label>
        <input id="email" type="email" name="email" v-model="email" autocomplete="username" required>

        <label for="password">Password:</label>
        <input id="password" type="password" name="password" v-model="password" autocomplete="current-password" required>

        <button>Log In</button>
        <div v-if="error">{{ error }}</div>
    </form>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useLogin from '@/composables/useLogin'
import { db } from '@/firebase/config'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'

export default {
  setup() {
    const email = ref('')
    const password = ref('')
    serverTimestamp
    const router = useRouter()
    const { login, error, UID } = useLogin()

    const handleSubmit = async () => {
        await login(email.value, password.value)

        const docRef = doc(db, 'users', UID.value )
         await updateDoc(docRef, {
                    login: serverTimestamp()
                })

        if (!error.value) {
            router.push('/')
        }
    }

    return { email, password, handleSubmit, error }
  }
}
</script>

<style scoped>
form label, form input, form select {
  display: block;
}
form label{
    color: #fff;
    font-size: 18px;
}
form input,
form select {
  padding: 6px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 8px;
}
form input:focus, 
form input:active{
    outline: 3px solid #bbb;
}
/* button {
  background: #bbb;
  color: #333;
  border: 0;
  border-radius: 6px;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 20px;
} */
.loginBox{
    background: #333;
    padding: 30px;
    margin: 30px;
    box-shadow: 0 0 20px;
    border-radius: 10px;
    min-width: 250px;
}
.loginBox h2{
    color: #fff;
    margin-bottom: 10px;
    font-size: 24px;
}
</style>