<template>
	<div class="moduleContent">
		<div class="moduleContainer">
			<template v-for="review in reviews" :key="review.id">
				<div class="reviewBox">
					<div @click="openReview(review)">
						<h3 v-if="review.reviewHeader && review.userName == 'Allmusic'">Review by {{ review.reviewHeader }}</h3>
						<h3 v-else-if="review.reviewHeader">{{ review.reviewHeader }}</h3>
						<star-rating v-if="review.reviewRating"
							:increment="0.5"
							:star-size="20"
							:padding="2"
							:animate="false"
							class="rating"
							:glow="2" 
							glow-color="#ffd055"
							text-class="star-text"
							:read-only="true"
							:show-rating="true"
							inactive-color="#212121"
							:rating="review.reviewRating">
						</star-rating>
						<!-- <pre>{{ review.reviewBody.split('. ')[4] }}</pre> -->

						<div class="reviewContent">
							<div v-if="review.reviewBody" class="reviewBody">
								<p v-if="!review.readMore" class="reviewBody" v-html="getFirstPara(review.reviewBody)"></p>
								<!-- <p v-if="!review.readMore" class="reviewBody" v-html="review.reviewBody.slice(0, 160)"></p> -->
								<!-- <a class="readMore" v-if="!review.readMore && review.reviewBody.length > 100" @click="review.readMore = true">Read more...</a> -->
								<!-- <p v-if="review.readMore && review.reviewBody" class="reviewBody" v-html="getFirstParagraph(review.reviewBody)"></p> -->
								<!-- <a class="readMore" v-if="review.readMore" @click="review.readMore = false">Show less ...</a> -->
							</div>
							<div class="author">
								<p v-if="review.userName">Written by <strong>{{ review.userName }}</strong> <span v-if="!review.timeCreated">{{ review.timeCreated}} ago</span></p>
							</div>
							<div class="metadata">
								<!-- <p v-if="review.created">{{ timestampToTimedate(review.created) }}</p>  -->
								<p v-if="review.timeCreated">Added <span class="metastrong">{{ review.timeCreated}}</span> ago</p>
								<p v-if="review.timeUpdated">Updated <span class="metastrong">{{ review.timeUpdated }}</span> ago</p>
								<!-- <br><p><em>{{ review.reviewID }}</em></p> -->
							</div>
						</div>
					</div>
					<!-- <div class="test">
						{{ review.comments }}
					</div> -->
					<div class="icons">
						<div class="tooltip iconComment">
							<span  :class="{ icon: true, iconReview: review.comments }"
							@click="openReviewComments(review)">
							<!-- <span  :class="{ icon: true, iconReview: review.comments.includes(review.reviewID)}"
							@click="openReviewComments(review)"> -->
								
								<span class="material-icons">comment</span>
								<span class="tooltiptext">See Comments</span>
							</span>
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
	<ReviewModal
		v-if="showReview"
		@close="showReview = false"
		:review="activeReview" 
	/>
	<ReviewComments 
		v-if="showCommentsModal" 
		@close="showCommentsModal = false"
		:review="activeReview"
		:user="user"

	/>
</template>

<script>
import { timestampToTimedate } from '@/composables/timestampConverter';
import { ref } from 'vue';
import StarRating from 'vue-star-rating'
import ReviewModal from '@/components/modals/ReviewModal.vue'
import ReviewComments from '@/components/modals/ReviewComments.vue'
import { getFirstPara } from '@/composables/splitBody';

export default {
	props: ['album','reviews', 'user'],
	components: { StarRating, ReviewModal, ReviewComments  },
	setup(props) {
		const readMore = ref(false)
		const showReview = ref(false)
		const showCommentsModal = ref(false)
		const activeReview = ref(null)

		const openReview = (review) => {
			showReview.value = true
			activeReview.value = review
		}		

		const openReviewComments = (review) => {
			showCommentsModal.value = true
			activeReview.value = review
		}		
		
	return { readMore, timestampToTimedate, showCommentsModal, showReview, openReview, activeReview, openReviewComments, getFirstPara }
	}

}
</script>

<style scoped>
	.moduleContainer {
		display: grid;
		gap: 20px;
		grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
		justify-content: space-around;
	}
	.reviewBox{
		background: rgba(142, 142, 142, 0.15);
		margin: 0 0 10px;
		cursor: pointer;
		/* display: grid;
		grid-template-columns: repeat(3, 1fr); */
		position: relative;
		/* max-width: 500px; */
	}
	.reviewBox:last-child {
		margin: 0;
	}
	.reviewBox h2 {
		font-size: 1.2em;
	}
	.reviewBox .reviewContent {
		padding: 0 10px;
	}
	.star-text{
		font-size: 12px;
	}
	.icons {
		position: absolute;
		bottom: 10px;
		right: -100px;
	}
	.rating{
		justify-content: center;
		margin: 5px auto;
	}
	.reviewBox .reviewBody{
		margin: 20px auto 0;
		font-size: 1em;
	}
	.reviewBox .author{
		margin-top: 10px;
	}

	.reviewBox .showMore{
		position: absolute;
		bottom: 10px;
		right: 10px;
		padding-top: 20px;
	}
</style>