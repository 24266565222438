// Split to HTML
export const splitBody = (body) => {
	let newbody = ''
	let bodyArray = body.split('. ')
	bodyArray.forEach((para) => {
		newbody += para + '.<br><br>'
	})
	return newbody
}
// Return First paragraph
export const getFirstPara = (body) => {
	let bodyArray = body.split('. ')
	return bodyArray[0] + '.'
}