<template>
  <form @submit.prevent="addRecord">
    <h1>Add New Record:</h1>
    <h3>Basic info:</h3>
	<label for="title">Title:</label>
    <input id="title" type="text" placeholder="Title" v-model="title">
    <label for="artist">Artist:</label>
    <input id="artist" type="text" placeholder="Artist" v-model="artist">
    <label for="album">Album:</label>
    <input id="album" type="text" placeholder="Album" v-model="album">
    <label for="year">Year:</label>
    <input id="year" type="number" placeholder="Year" v-model="year" min="1940" max="2099">
	
    <label for="label">Label:</label>
    <input id="label" type="text" placeholder="Label" v-model="label">
    <label for="review">Allmusic Review URL:</label>
    <input id="review" type="text" placeholder="Review" v-model="review">
    <label for="wiki">Wikipedia URL:</label>
    <input id="wiki" type="text" placeholder="Wikipedia" v-model="wiki">
    <label for="discogs">Discogs URL:</label>
    <input id="discogs" type="text" placeholder="Discogs" v-model="discogs">
    <label for="discogsID">DiscogsID:</label>
    <input id="discogsID" type="text" placeholder="DiscogsID" v-model="discogsID">
    <label for="cat">Release ID:</label>
    <input id="cat" type="text" placeholder="Cat" v-model="cat">
    <label for="catalogID">Cat ID:</label>
    <input id="catalogID" type="text" placeholder="Cat ID" v-model="catalogID">
    <label for="cover">Cover:</label>
    <input id="cover" type="text" placeholder="Cover" v-model="cover">
    <label for="recorded1">Record Session 1:</label>
    <input id="recorded1" type="date" placeholder="1. Session Recorded" v-model="recorded1">
    <label for="recorded2">Record Session 2:</label>
    <input id="recorded2" type="date" placeholder="2. Session Recorded" v-model="recorded2">
    <label for="recorded3">Record Session 3:</label>
    <input id="recorded3" type="date" placeholder="3. Session Recorded" v-model="recorded3">
    <label for="recorded4">Record Session 4:</label>
    <input id="recorded4" type="date" placeholder="4. Session Recorded" v-model="recorded4">
    <label for="recorded5">Record Session 5:</label>
    <input id="recorded5" type="date" placeholder="5. Session Recorded" v-model="recorded5">

    <label for="series">Series:</label>
    <select id="series" v-model="series">
        <option value="Acoustic Sounds Series">Acoustic Sounds Series</option>
        <option value="Blue Note 75 Vinyl Series">Blue Note 75 Vinyl Series</option>
        <option value="Blue Note 80 Vinyl Series">Blue Note 80 Vinyl Series</option>
        <option value="Blue Note Classic Vinyl Series">Blue Note Classic Vinyl Series</option>
        <option value="Blue Note Tone Poet Series">Blue Note Tone Poet Series</option>
        <option value="Columbia">Columbia</option>
        <option value="Contemporary Records Acoustic Sounds">Contemporary Records Acoustic Sounds</option>
		<option value="ECM Luminessence Series">ECM Luminessence Series</option>
        <option value="Mobile Fidelity Sound Lab">Mobile Fidelity Sound Lab</option>
        <option value="Music On Vinyl">Music On Vinyl</option>
        <option value="Verve By Request">Verve By Request</option>
        <option value="WaxTime In Color">WaxTime In Color</option>
        <option value="Other">Other</option>
    </select>

    <label for="release">Release Date:</label>
    <input id="release" type="date" placeholder="Release Date" v-model="release">
    <label for="theme">Blue Note Theme:</label>
    <input id="theme" type="text" placeholder="Theme" v-model="theme">
    <label for="musicLink">MusicLink:</label>
    <input id="musicLink" type="text" placeholder="MusicLink" v-model="musicLink">
    <label for="apple">Apple:</label>
    <input id="apple" type="text" placeholder="Apple" v-model="apple">

    <!-- Mine bestillinger -->
    <h3>Collection:</h3>
    <label for="ordered">Ordered date:</label>
    <input id="ordered" type="date" placeholder="Ordered" v-model="ordered">
    <label for="collected">Collected date:</label>
    <input id="collected" type="date" placeholder="Collected" v-model="collected">
    <label for="sum">Sum:</label>
    <input id="sum" type="text" placeholder="Sum" v-model="sum">
    <label for="butikk">Butikk:</label>
    <input id="butikk" type="text" placeholder="Butikk" v-model="butikk">


    <!-- Musicians -->
    <h3>Musicians</h3>

    <h3>Horns:</h3>
    <label for="musicianSaxTenor">Tenor Sax:</label>
    <input id="musicianSaxTenor" type="text" placeholder="Tenor Sax" v-model="musicianSaxTenor">
    <label for="musicianSaxAlto">Alto Sax:</label>
    <input id="musicianSaxAlto" type="text" placeholder="Alto Sax" v-model="musicianSaxAlto">
    <label for="musicianSaxSoprano">Soprano Sax:</label>
    <input id="musicianSaxSoprano" type="text" placeholder="Soprano Sax" v-model="musicianSaxSoprano">
    <label for="musicianSaxBaryton">Baryton Sax:</label>
    <input id="musicianSaxBaryton" type="text" placeholder="Baryton Sax" v-model="musicianSaxBaryton">
    <label for="musicianFlute">Flute:</label>
    <input id="musicianFlute" type="text" placeholder="Flute" v-model="musicianFlute">
    <label for="musicianClarinet">Clarinet:</label>
    <input id="musicianClarinet" type="text" placeholder="Clarinet" v-model="musicianClarinet">
    <label for="musicianTrombone">Trombone:</label>
    <input id="musicianTrombone" type="text" placeholder="Trombone" v-model="musicianTrombone">
    <label for="musicianTrumpet">Trumpet:</label>
    <input id="musicianTrumpet" type="text" placeholder="Trumpet" v-model="musicianTrumpet">
    <label for="musicianTuba">Tuba:</label>
    <input id="musicianTuba" type="text" placeholder="Tuba" v-model="musicianTuba">
    <label for="musicianEuphonium">Euphonium:</label>
    <input id="musicianEuphonium" type="text" placeholder="Euphonium" v-model="musicianEuphonium">
    <label for="musicianHorns">Horns:</label>
    <input id="musicianHorns" type="text" placeholder="Horns" v-model="musicianHorns">
    <label for="musicianCornette">Cornette:</label>
    <input id="musicianCornette" type="text" placeholder="Cornette" v-model="musicianCornette">

    <h3>Instruments:</h3>
    <label for="musicianPiano">Piano:</label>
    <input id="musicianPiano" type="text" placeholder="Piano" v-model="musicianPiano">
    <label for="musicianDrums">Drums:</label>
    <input id="musicianDrums" type="text" placeholder="Drums" v-model="musicianDrums">
    <label for="musicianBass">Bass:</label>
    <input id="musicianBass" type="text" placeholder="Bass" v-model="musicianBass">
    <label for="musicianString">String:</label>
    <input id="musicianString" type="text" placeholder="String" v-model="musicianString">
    <label for="musicianGuitar">Guitar:</label>
    <input id="musicianGuitar" type="text" placeholder="Guitar" v-model="musicianGuitar">
    <label for="musicianOrgan">Organ:</label>
    <input id="musicianOrgan" type="text" placeholder="Organ" v-model="musicianOrgan">
    <label for="musicianConga">Conga:</label>
    <input id="musicianConga" type="text" placeholder="Conga" v-model="musicianConga">
    <label for="musicianPercussion">Percussion:</label>
    <input id="musicianPercussion" type="text" placeholder="Percussion" v-model="musicianPercussion">
    <label for="musicianVibraphone">Vibraphone:</label>
    <input id="musicianVibraphone" type="text" placeholder="Vibraphone" v-model="musicianVibraphone">
    <label for="musicianVocal">Vocal:</label>
    <input id="musicianVocal" type="text" placeholder="Vocal" v-model="musicianVocal">
    <label for="musicianSitar">Sitar:</label>
    <input id="musicianSitar" type="text" placeholder="Sitar" v-model="musicianSitar">
    <label for="musicianHarp">Harp:</label>
    <input id="musicianHarp" type="text" placeholder="Harp" v-model="musicianHarp">

    <h3>Songs:</h3>
    <label for="song01">Song 1:</label>
    <input id="song01" type="text" placeholder="Track 01" v-model="song01">
    <label for="song02">Song 2:</label>
    <input id="song02" type="text" placeholder="Track 02" v-model="song02">
    <label for="song03">Song 3:</label>
    <input id="song03" type="text" placeholder="Track 03" v-model="song03">
    <label for="song04">Song 4:</label>
    <input id="song04" type="text" placeholder="Track 04" v-model="song04">
    <label for="song05">Song 5:</label>
    <input id="song05" type="text" placeholder="Track 05" v-model="song05">
    <label for="song06">Song 6:</label>
    <input id="song06" type="text" placeholder="Track 06" v-model="song06">
    <label for="song07">Song 7:</label>
    <input id="song07" type="text" placeholder="Track 07" v-model="song07">
    <label for="song08">Song 8:</label>
    <input id="song08" type="text" placeholder="Track 08" v-model="song08">
    <label for="song09">Song 9:</label>
    <input id="song09" type="text" placeholder="Track 09" v-model="song09">
    <label for="song10">Song10:</label>
    <input id="song10" type="text" placeholder="Track 10" v-model="song10">
    <label for="song11">Song11:</label>
    <input id="song11" type="text" placeholder="Track 11" v-model="song11">
    <label for="song12">Song12:</label>
    <input id="song12" type="text" placeholder="Track 12" v-model="song12">
    <label for="song13">Song13:</label>
    <input id="song13" type="text" placeholder="Track 13" v-model="song13">
    <label for="song14">Song14:</label>
    <input id="song14" type="text" placeholder="Track 14" v-model="song14">
    <label for="song15">Song15:</label>
    <input id="song15" type="text" placeholder="Track 15" v-model="song15">
    <label for="song16">Song16:</label>
    <input id="song16" type="text" placeholder="Track 16" v-model="song16">
    <label for="song17">Song17:</label>
    <input id="song17" type="text" placeholder="Track 17" v-model="song17">
    <label for="song18">Song18:</label>
    <input id="song18" type="text" placeholder="Track 18" v-model="song18">
    <label for="song19">Song19:</label>
    <input id="song19" type="text" placeholder="Track 19" v-model="song19">
    <label for="song20">Song20:</label>
    <input id="song20" type="text" placeholder="Track 20" v-model="song20">

    <button>Add Record</button>

  </form>
  
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'

// Firebase import
import { auth, db } from '@/firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { addDoc, collection, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { guardRoute } from '@/composables/guardRoute'

export default {
	props: ['user'],
    setup(props){
        // Collection name
        const colName = 'Jazz'
        const router = useRouter()
        
        // Admin user Collection
        const userCollected = ref([])
        const authUser = ref(auth.currentUser)
        const user = ref({})

        // Ref fra inputs
        const title = ref('')
        const artist = ref('')
        const album = ref('')
        const year = ref('') 
        const label = ref('')
        const review = ref('')
        const wiki = ref('')
        const discogs = ref('') 
        const discogsID = ref('')
        const cat = ref('')
        const catalogID = ref('')
        const cover = ref('') 
        const recorded1 = ref('')
        const recorded2 = ref('')
        const recorded3 = ref('')
        const recorded4 = ref('')
        const recorded5 = ref('')
        const series = ref('')
        const release = ref('') 
        const theme = ref('')
        const musicLink = ref('')
        const apple = ref('') 
        const ordered = ref('')
        const collected = ref('')
        const sum = ref('')
        const butikk = ref('') 
        // Musicians
        const musicianSaxTenor = ref('')
        const musicianSaxAlto = ref('')
        const musicianSaxSoprano = ref('')
        const musicianSaxBaryton = ref('')
        const musicianFlute = ref('')
        const musicianClarinet = ref('')
        const musicianTrombone = ref('')
        const musicianTrumpet = ref('')
        const musicianTuba = ref('')
        const musicianEuphonium = ref('')
        const musicianHorns = ref('')
        const musicianCornette = ref('')
        const musicianPiano = ref('')
        const musicianDrums = ref('')
        const musicianBass = ref('')
        const musicianString = ref('')
        const musicianGuitar = ref('')
        const musicianOrgan = ref('')
        const musicianConga = ref('')
        const musicianPercussion = ref('')
        const musicianVibraphone = ref('')
        const musicianVocal = ref('')
        const musicianSitar = ref('')
        const musicianHarp = ref('')
        // Songs
        const song01 = ref('')
        const song02 = ref('')
        const song03 = ref('')
        const song04 = ref('')
        const song05 = ref('')
        const song06 = ref('')
        const song07 = ref('')
        const song08 = ref('')
        const song09 = ref('')
        const song10 = ref('')
        const song11 = ref('')
        const song12 = ref('')
        const song13 = ref('')
        const song14 = ref('')
        const song15 = ref('')
        const song16 = ref('')
        const song17 = ref('')
        const song18 = ref('')
        const song19 = ref('')
        const song20 = ref('')

        // Auth Changes
        onAuthStateChanged(auth, (_authUser) => {
            authUser.value = _authUser
            if(_authUser){
                getUserDoc(authUser.value.uid)
            }
        })
        // Get User Data
        const getUserDoc = async (uid) => {
            const userRef = doc(db, 'users', uid)
            const docSnap = await getDoc(userRef)
            if (docSnap.exists()) {
                user.value = {...docSnap.data(), id: docSnap.id }
                userCollected.value = docSnap.data().collected
                // console.log('userCollected: ', userCollected)
                // console.log('user.value: ', user.value.id)
            } else {console.log("No user document found!")}
        }

		const resetForm = () => {     
                // reset form
                title.value = ''
                artist.value = ''
                album.value = ''
                year.value = ''
                label.value = ''
                review.value = ''
                wiki.value = ''
                discogs.value = ''
                discogsID.value = ''
                cat.value = ''
                catalogID.value = ''
                cover.value = ''
                recorded1.value = ''
                recorded2.value = ''
                recorded3.value = ''
                recorded4.value = ''
                recorded5.value = ''
                series.value = ''
                release.value = ''
                theme.value = ''
                musicLink.value = ''
                apple.value = ''
                ordered.value = ''
                collected.value = ''
                sum.value = ''
                butikk.value = ''
                musicianSaxTenor.value = ''
                musicianSaxAlto.value = ''
                musicianSaxSoprano.value = ''
                musicianSaxBaryton.value = ''
                musicianFlute.value  = ''
                musicianClarinet.value = ''
                musicianTrombone.value = ''
                musicianTrumpet.value = ''
                musicianTuba.value = ''
                musicianEuphonium.value = ''
                musicianHorns.value = ''
                musicianCornette.value = ''
                musicianPiano.value = ''
                musicianDrums.value = ''
                musicianBass.value = ''
                musicianString.value = ''
                musicianGuitar.value = ''
                musicianOrgan.value = ''
                musicianConga.value = ''
                musicianPercussion.value = ''
                musicianVibraphone.value = ''
                musicianVocal.value = ''
                musicianSitar.value = ''
                musicianHarp.value = ''
                song01.value = ''
                song02.value = ''
                song03.value = ''
                song04.value = ''
                song05.value = ''
                song06.value = ''
                song07.value = ''
                song08.value = ''
                song09.value = ''
                song10.value = ''
                song11.value = ''
                song12.value = ''
                song13.value = ''
                song14.value = ''
                song15.value = ''
                song16.value = ''
                song17.value = ''
                song18.value = ''
                song19.value = ''
                song20.value = ''
            }

        const addRecord = async () => {
            const colRef = collection(db, colName)

            const data = {}
            // Tester om feletene er tomme
            if (title.value !== ''){data.Title = title.value}
            if (artist.value !== ''){data.Artist = artist.value}
            if (album.value !== ''){data.Album = album.value}
            if (year.value !== ''){data.Year = year.value}
            if (label.value !== ''){data.Label = label.value}
            if (review.value !== ''){data.Review = review.value}
            if (wiki.value !== ''){data.Wiki = wiki.value}
            if (discogs.value !== ''){data.Discogs = discogs.value}
            if (discogsID.value !== ''){data.DiscogsID = discogsID.value}
            if (cat.value !== ''){data.Cat = cat.value}
            if (catalogID.value !== ''){data.CatalogID = catalogID.value}
            if (cover.value !== ''){data.Cover = cover.value}
            if (recorded1.value !== ''){data.Recorded1 = new Date(recorded1.value)}
            if (recorded2.value !== ''){data.Recorded2 = new Date(recorded2.value)}
            if (recorded3.value !== ''){data.Recorded3 = new Date(recorded3.value)}
            if (recorded4.value !== ''){data.Recorded4 = new Date(recorded4.value)}
            if (recorded5.value !== ''){data.Recorded5 = new Date(recorded5.value)}
            if (series.value !== ''){data.Series = series.value}
            if (release.value !== ''){data.Release = new Date(release.value)}
            if (theme.value !== ''){data.Theme = theme.value}
            if (musicLink.value !== ''){data.MusicLink = musicLink.value}
            if (apple.value !== ''){data.Apple = apple.value}
            if (ordered.value !== ''){data.Ordered = new Date(ordered.value)}
            if (collected.value !== ''){data.Collected = new Date(collected.value)}
            if (sum.value !== ''){data.Sum = sum.value}
            if (butikk.value !== ''){data.Butikk = butikk.value}
            
            const musicians = []
            if(musicianSaxTenor.value !== ''){data.musicianSaxTenor = musicianSaxTenor.value, musicians.push(musicianSaxTenor.value)}
            if(musicianSaxAlto.value !== ''){data.musicianSaxAlto = musicianSaxAlto.value, musicians.push(musicianSaxAlto.value)}
            if(musicianSaxSoprano.value !== ''){data.musicianSaxSoprano = musicianSaxSoprano.value, musicians.push(musicianSaxSoprano.value)}
            if(musicianSaxBaryton.value !== ''){data.musicianSaxBaryton = musicianSaxBaryton.value, musicians.push(musicianSaxBaryton.value)}
            if(musicianFlute.value !== ''){data.musicianFlute = musicianFlute.value, musicians.push(musicianFlute.value)}
            if(musicianClarinet.value !== ''){data.musicianClarinet = musicianClarinet.value, musicians.push(musicianClarinet.value)}
            if(musicianTrombone.value !== ''){data.musicianTrombone = musicianTrombone.value, musicians.push(musicianTrombone.value)}
            if(musicianTrumpet.value !== ''){data.musicianTrumpet = musicianTrumpet.value, musicians.push(musicianTrumpet.value)}
            if(musicianTuba.value !== ''){data.musicianTuba = musicianTuba.value, musicians.push(musicianTuba.value)}
            if(musicianEuphonium.value !== ''){data.musicianEuphonium = musicianEuphonium.value, musicians.push(musicianEuphonium.value)}
            if(musicianHorns.value !== ''){data.musicianHorns = musicianHorns.value, musicians.push(musicianHorns.value)}
            if(musicianCornette.value !== ''){data.musicianCornette = musicianCornette.value, musicians.push(musicianCornette.value)}
            if(musicianPiano.value !== ''){data.musicianPiano = musicianPiano.value, musicians.push(musicianPiano.value)}
            if(musicianDrums.value !== ''){data.musicianDrums = musicianDrums.value, musicians.push(musicianDrums.value)}
            if(musicianBass.value !== ''){data.musicianBass = musicianBass.value, musicians.push(musicianBass.value)}
            if(musicianString.value !== ''){data.musicianString = musicianString.value, musicians.push(musicianString.value)}
            if(musicianGuitar.value !== ''){data.musicianGuitar = musicianGuitar.value, musicians.push(musicianGuitar.value)}
            if(musicianOrgan.value !== ''){data.musicianOrgan = musicianOrgan.value, musicians.push(musicianOrgan.value)}
            if(musicianConga.value !== ''){data.musicianConga = musicianConga.value, musicians.push(musicianConga.value)}
            if(musicianPercussion.value !== ''){data.musicianPercussion = musicianPercussion.value, musicians.push(musicianPercussion.value)}
            if(musicianVibraphone.value !== ''){data.musicianVibraphone = musicianVibraphone.value, musicians.push(musicianVibraphone.value)}
            if(musicianVocal.value !== ''){data.musicianVocal = musicianVocal.value, musicians.push(musicianVocal.value)}
            if(musicianSitar.value !== ''){data.musicianSitar = musicianSitar.value, musicians.push(musicianSitar.value)}
            if(musicianHarp.value !== ''){data.musicianHarp = musicianHarp.value, musicians.push(musicianHarp.value)}
            
            const songs = []
            if(song01.value !== ''){data.Song01 = song01.value, songs.push(song01.value)}
            if(song02.value !== ''){data.Song02 = song02.value, songs.push(song02.value)}
            if(song03.value !== ''){data.Song03 = song03.value, songs.push(song03.value)}
            if(song04.value !== ''){data.Song04 = song04.value, songs.push(song04.value)}
            if(song05.value !== ''){data.Song05 = song05.value, songs.push(song05.value)}
            if(song06.value !== ''){data.Song06 = song06.value, songs.push(song06.value)}
            if(song07.value !== ''){data.Song07 = song07.value, songs.push(song07.value)}
            if(song08.value !== ''){data.Song08 = song08.value, songs.push(song08.value)}
            if(song09.value !== ''){data.Song09 = song09.value, songs.push(song09.value)}
            if(song10.value !== ''){data.Song10 = song10.value, songs.push(song10.value)}
            if(song11.value !== ''){data.Song11 = song11.value, songs.push(song11.value)}
            if(song12.value !== ''){data.Song12 = song12.value, songs.push(song12.value)}
            if(song13.value !== ''){data.Song13 = song13.value, songs.push(song13.value)}
            if(song14.value !== ''){data.Song14 = song14.value, songs.push(song14.value)}
            if(song15.value !== ''){data.Song15 = song15.value, songs.push(song15.value)}
            if(song16.value !== ''){data.Song16 = song16.value, songs.push(song16.value)}
            if(song17.value !== ''){data.Song17 = song17.value, songs.push(song17.value)}
            if(song18.value !== ''){data.Song18 = song18.value, songs.push(song18.value)}
            if(song19.value !== ''){data.Song19 = song19.value, songs.push(song19.value)}
            if(song20.value !== ''){data.Song20 = song20.value, songs.push(song20.value)}

            // Add Array
            data.Musicians = musicians
            data.Songs = songs
            // Add Created date
            data.created = serverTimestamp()

            const docRef = await addDoc(colRef, data, {merge: true})
            console.log('Album written with ID:', docRef.id)
            if(collected.value !== ''){
                updateCollection(docRef, user)
            }

            resetForm()
            // Send back to root
            router.push('/')
        }
        const updateCollection = async (album, user) => {
            const userRef = doc(db, 'users', user.value.id)
            userCollected.value.push(album.id)
                await updateDoc(userRef, {
                    collected: userCollected.value
                })
                console.log('Album added to user collection:', album.id)
        }

		onMounted( async () => {
			const guard = await guardRoute(props.user) // guard: boolean
		})

        return { addRecord, getUserDoc, userCollected, authUser, user, updateCollection, guardRoute,
		title, artist, album, year, label, review, wiki, discogs, discogsID, cat, catalogID, cover, recorded1, recorded2, recorded3, recorded4, recorded5, series, release, theme, musicLink, apple, ordered, collected, sum, butikk,
        musicianSaxTenor,musicianSaxAlto,musicianSaxSoprano,musicianSaxBaryton,musicianFlute,musicianClarinet,musicianTrombone,musicianTrumpet,musicianTuba,musicianEuphonium,musicianHorns,musicianCornette,
        musicianPiano,musicianDrums,musicianBass,musicianString,musicianGuitar,musicianOrgan,musicianConga,musicianPercussion,musicianVibraphone,musicianVocal,musicianSitar, musicianHarp,
        song01,song02,song03,song04,song05,song06,song07,song08,song09,song10,song11,song12,song13,song14,song15,song16,song17,song18,song19,song20,
			
        }
    }
}
</script>

<style scoped>
@media (prefers-color-scheme: dark) {
	.container form,
	.adminModule{
		background: #2d2d2d;
	}
}
.container form, 
.container .adminModule{
    padding: 20px;
    border-radius: 20px;
	margin-bottom: 20px;
}
form label, form input, form select {
    display: block;
}
form input, form select {
    padding: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 250px;
    height: 40px;
    font-size: 1em;
}
/* button {
	background: #151515;
	color: #c4c4c4;
    height: 40px;
    border: none;
    min-width: 250px;
   	max-width: 45%;
	margin-right: 20px;
    padding: 10px;
    border-radius: 5px; 
} */
form select{
    width: 262px;
}
</style>