import { useRouter } from "vue-router"
export const guardRoute = async (user) => {
	const router = useRouter()
	if(user !== null){
		if(user.admin){ // admin = true
			console.log('%c Admin', 'color:rgb(238, 111, 183);font-size:1.2em', user.admin)
			return true
		} else { // admin = false
			router.push({name: '404'})
			return false
		}
	} else { // No user
		router.push({name: '404'})
		return false
	}
}