import { ref } from "vue";

// Firebase imports
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";

const error = ref(null)
const isPending = ref(false)
const UID = ref(null)

const login = async (email, password) => {
    error.value = null
    isPending.value = true
    try {
        const res = await signInWithEmailAndPassword(auth, email, password)
            console.log('User', email, 'signed in!')
            const userId = res.user.uid
            UID.value = userId
        if(!res) {
            throw new Error('Could not login')
        }
        error.value = null
        isPending.value = false
    } 
    catch (err) {
        console.log(err.message)
        error.value = err.message
        isPending.value = false
    }
}

const useLogin = () => {
    return { error, isPending, login, UID }
}

export default useLogin