import { db } from '@/firebase/config'
import { doc, getDoc } from 'firebase/firestore'

export const getAlbum = async (id) => { 
	const albumRef = doc(db, 'Jazz', id)
	const albumSnap = await getDoc(albumRef)
	if (albumSnap.exists()){
		const data = {...albumSnap.data(), id: albumSnap.id}
		return data
	}
}
export const getArtist = async (id) => { 
	const artistRef = doc(db, 'artist', id)
	const artistSnap = await getDoc(artistRef)
	if (artistSnap.exists()){
		const data = {...artistSnap.data(), id: artistSnap.id}
		return data
	}
}