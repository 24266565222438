<template>
	<teleport to="#modals">
		<Transition name="fade" appear>
			<div class="modal-overlay" @click.self="$emit('close')" aria-label="Close modal">
				<div :class="{ modal: true, loginBox: true, shadowHigh: !isHovered, shadowMedium: isHovered }"
					role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false">
					<section>
						<h2 v-if="album.Artist">{{ album.Artist }}</h2>
						<h2 v-if="album.Album">{{ album.Album }} ({{ album.Year }})</h2>
					</section>
					<router-link :to="{ name: 'Album', params: { id: album.albumID } }">
						<img :src="require('@/assets/covers/' + album.albumID + '.jpg')" 
						:alt="album.album" loading="lazy"
						class="bilde">
					</router-link>
					<section>
						<h3 v-if="album.Series">{{ album.Series }}</h3>
						<h3 v-if="album.Label">{{ album.Label }}: {{ album.CatalogID }}</h3>
					</section>
					<section>
						<h3 @click="toggleContent($event)" class="subheading">Tracks</h3>
						<div v-show="showTracks" class="subcontent">
							<p v-for="(song, index) in album.Songs" :key="song">
								{{ index + 1 }}: {{ song }}
							</p>
						</div>
					</section>
					<section>
						<h3 @click="toggleContent($event)" class="subheading">Musicians</h3>
						<div v-show="showMusicians" class="musicianInfo subcontent">
							<div class="musicians">
								<ul v-if="album.musicianSaxAlto" id="SaxAlto">
									<li v-for="SaxAlto in makeArray(album.musicianSaxAlto)" :key="SaxAlto">
										Alto Saxophone: <span>{{ SaxAlto }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianSaxBaryton" id="SaxBaryton">
									<li v-for="SaxBaryton in makeArray(album.musicianSaxBaryton)" :key="SaxBaryton">
										Baryton Saxophone: <span>{{ SaxBaryton }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianBass" id="Bass">
									<li v-for="Bass in makeArray(album.musicianBass)" :key="Bass">
										Bass: <span>{{ Bass }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianClarinet" id="Clarinet">
									<li v-for="Clarinet in makeArray(album.musicianClarinet)" :key="Clarinet">
										Clarinet: <span>{{ Clarinet }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianConga" id="Conga">
									<li v-for="Conga in makeArray(album.musicianConga)" :key="Conga">
										Conga: <span>{{ Conga }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianCornette" id="Cornette">
									<li v-for="Cornette in makeArray(album.musicianCornette)" :key="Cornette">
										Cornette: <span>{{ Cornette }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianDrums" id="Drums">
									<li v-for="Drums in makeArray(album.musicianDrums)" :key="Drums">
										Drums: <span>{{ Drums }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianEuphonium" id="Euphonium">
									<li v-for="Euphonium in makeArray(album.musicianEuphonium)" :key="Euphonium">
										Euphonium: <span>{{ Euphonium }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianFlute" id="Flute">
									<li v-for="Flute in makeArray(album.musicianFlute)" :key="Flute">
										Flute: <span>{{ Flute }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianGuitar" id="Guitar">
									<li v-for="Guitar in makeArray(album.musicianGuitar)" :key="Guitar">
										Guitar: <span>{{ Guitar }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianHarp" id="Harp">
									<li v-for="Harp in makeArray(album.musicianHarp)" :key="Harp">
										Harp: <span>{{ Harp }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianHorns" id="Horns">
									<li v-for="Horns in makeArray(album.musicianHorns)" :key="Horns">
										Horns: <span>{{ Horns }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianOrgan" id="Organ">
									<li v-for="Organ in makeArray(album.musicianOrgan)" :key="Organ">
										Organ: <span>{{ Organ }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianPercussion" id="Percussion">
									<li v-for="Percussion in makeArray(album.musicianPercussion)" :key="Percussion">
										Percussion: <span>{{ Percussion }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianPiano" id="Piano">
									<li v-for="Piano in makeArray(album.musicianPiano)" :key="Piano">
										Piano: <span>{{ Piano }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianSitar" id="Sitar">
									<li v-for="Sitar in makeArray(album.musicianSitar)" :key="Sitar">
										Sitar: <span>{{ Sitar }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianSaxSoprano" id="SaxSoprano">
									<li v-for="SaxSoprano in makeArray(album.musicianSaxSoprano)" :key="SaxSoprano">
										Soprano Saxophone: <span>{{ SaxSoprano }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianString" id="String">
									<li v-for="String in makeArray(album.musicianString)" :key="String">
										Strings: <span>{{ String }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianSaxTenor" id="SaxTenor">
									<li v-for="SaxTenor in makeArray(album.musicianSaxTenor)" :key="SaxTenor">
										Tenor Saxophone: <span>{{ SaxTenor }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianTrombone" id="Trombone">
									<li v-for="Trombone in makeArray(album.musicianTrombone)" :key="Trombone">
										Trombone: <span>{{ Trombone }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianTrumpet" id="Trumpet">
									<li v-for="Trumpet in makeArray(album.musicianTrumpet)" :key="Trumpet">
										Trumpet: <span>{{ Trumpet }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianTuba" id="Tuba">
									<li v-for="Tuba in makeArray(album.musicianTuba)" :key="Tuba">
										Tuba: <span>{{ Tuba }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianVibraphone" id="Vibraphone">
									<li v-for="Vibraphone in makeArray(album.musicianVibraphone)" :key="Vibraphone">
										Vibraphone: <span>{{ Vibraphone }}</span>
									</li>
								</ul>
								<ul v-if="album.musicianVocal" id="Vocal">
									<li v-for="Vocal in makeArray(album.musicianVocal)" :key="Vocal">
										Vocal: <span>{{ Vocal }}</span>
									</li>
								</ul>
							</div>
						</div>
					</section>
					
					<section>
						<h3 @click="toggleContent($event)" class="subheading">Links</h3>
						<div v-show="showLinks" class="subcontent">
							<p><a v-if="album.Review" :href="album.Review" target="blank">Allmusic</a></p>
							<p><a v-if="album.Amazon" :href="album.Amazon" target="blank">Amazon</a></p>
							<p><a v-if="album.Discogs" :href="album.Discogs" target="blank">Discogs</a></p>
							<p><a v-if="album.Play" :href="album.Play" target="blank">Release Links</a></p>
							<p><a v-if="album.SeriesLink" :href="album.SeriesLink" target="blank">Series</a></p>
							<p><a v-if="album.Wiki" :href="album.Wiki" target="blank">Wikipedia</a></p>
						</div>
					</section>
					<section>		
						<h3 @click="toggleContent($event)" class="subheading">Dates</h3>
						<div v-show="showDates" class="subcontent">
							<p v-if="album.Recorded1">
								<span v-if="album.Recorded1">Recorded: {{ checkTimestamp(album.Recorded1) }}</span>
								<span v-if="album.Recorded2">, {{ checkTimestamp(album.Recorded2) }}</span>
								<span v-if="album.Recorded3">, {{ checkTimestamp(album.Recorded3) }}</span>
								<span v-if="album.Recorded4">, {{ checkTimestamp(album.Recorded4) }}</span>
								<span v-if="album.Recorded5">, {{ checkTimestamp(album.Recorded5) }}</span>
							</p>
							<p v-if="album.RecordedDates">RecordedDates: {{ album.Recorded1 }}</p>

							<p v-if="album.Release">Release {{ checkTimestamp(album.Release, 'release') }}</p>
							<p v-if="album.Release">Release: {{ checkTimestamp(album.Release) }}</p>
							<p v-if="album.Collected">Collected: {{ checkTimestamp(album.Collected) }}</p>
							<p v-if="album.Created">Created: {{ checkTimestamp(album.Created) }}</p>
							<p v-if="album.Update">Update: {{ checkTimestamp(album.Update) }}</p>
							<p v-if="album.DiscogsID">DiscogsID: {{ album.DiscogsID }}</p>
						</div>
					</section>
					<section>
						<h3 @click="toggleContent($event)" v-if="user.collected.includes(userCollected.albumId)" class="subheading">User Collection</h3>
						<div v-if="showCollection" class="subcontent">
							<p v-if="userCollected.Butikk">Butikk: {{ userCollected.Butikk }}</p>
							<p v-if="userCollected.Sum">Sum: {{ userCollected.Sum }}</p>
							<p v-if="userCollected.Ordered">Ordered: {{ timestampToLongdate(userCollected.Ordered) }}</p>
							<p v-if="userCollected.Collected">Collected:
								{{ timestampToLongdate(userCollected.Collected) }}</p>
							<p v-if="userCollected.Updated">Update: {{ timestampToTimedate(userCollected.Updated) }}</p>
						</div>
					</section>
					<section>
						<h3 @click="toggleContent($event)" class="subheading">Player</h3>
						<div v-if="showPlayer" class="apple-music">
							<iframe :src="album.Apple" loading="lazy" allow="autoplay *; encrypted-media *;"
								frameborder="0" height="450"
								style="width:100%;max-width:660px;overflow:hidden;background:transparent;"
								sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation">
							</iframe>
						</div>
					</section>
					<!-- Close button -->
					<span @click="$emit('close')" class="close-btn material-icons" aria-label="Close modal">cancel</span>
				</div>
			</div>
		</Transition>
	</teleport>
</template>

<script>
import { onMounted, ref } from 'vue'
import { unixtimeToLongdate, timestampToInput, timestampToTimedate, timestampToLongdate } from '@/composables/timestampConverter'
import { getUserCollected } from '@/composables/getUserCollected';
import { checkTimestamp } from '@/composables/checkTimestamp';
import { getAlbum } from '@/composables/getDoc';

export default {
	emits: ['close'],
	props: ['album', 'user', 'albumCollection'],
	setup(props, context) {
		const showTracks = ref(false)
		const showMusicians = ref(false)
		const showCollection = ref(false)
		const showLinks = ref(false)
		const showPlayer = ref(false)
		const showDates = ref(false)
		const isHovered = ref(false)

		const userCollected = ref({})
		
		const toggleContent = (event) => {
			let clickText = event.target.innerText
			// Reset all to false
			showTracks.value = false
			showMusicians.value = false
			showCollection.value = false
			showLinks.value = false
			showDates.value = false
			showPlayer.value = false
			if(clickText == 'Tracks'){showTracks.value = true}
			if(clickText == 'Musicians'){showMusicians.value = true}
			if(clickText == 'Collection'){showCollection.value = true}
			if(clickText == 'Links'){showLinks.value = true}
			if(clickText == 'Dates'){showDates.value = true}
			if(clickText == 'Player'){showPlayer.value = true}
		}
		const toggleTracks = (event) => {
			showTracks.value = true
			showMusicians.value = false
			showCollection.value = false
			showLinks.value = false
			showDates.value = false
			showPlayer.value = false
		}
		const toggleMusicians = () => {
			showTracks.value = false
			showMusicians.value = true
			showCollection.value = false
			showLinks.value = false
			showDates.value = false
			showPlayer.value = false
		}
		const toggleLinks = () => {
			showTracks.value = false
			showMusicians.value = false
			showCollection.value = false
			showLinks.value = true
			showDates.value = false
			showPlayer.value = false
		}
		const toggleCollection = () => {
			showTracks.value = false
			showMusicians.value = false
			showCollection.value = true
			showLinks.value = false
			showDates.value = false
			showPlayer.value = false
		}
		const toggleDates = () => {
			showTracks.value = false
			showMusicians.value = false
			showCollection.value = false
			showLinks.value = false
			showDates.value = true
			showPlayer.value = false
		}
		const togglePlayer = () => {
			showTracks.value = false
			showMusicians.value = false
			showCollection.value = false
			showLinks.value = false
			showDates.value = false
			showPlayer.value = true
		}

		const makeArray = (string) => {
			let array = string.split(', ')
			return array
		}
		// ALGOLIA SEARCH
		const filterSearch = (event, refine, currentRefinement) => {
			// If Click on musician
			console.log('event: ', event)
			event.preventDefault();
			if (event.type === 'click') {
				queryMusician.value = event.target.innerText
				refine(event.currentTarget.innerText)
			}
			// If Search input
			if (event.type === 'input') {
				currentRefinement = event.currentTarget.value
				queryMusician.value = currentRefinement
				refine(event.currentTarget.value)
			}
		}		
		const getCollectedAlbum = async () => {
			if (props.user.collected.includes(props.album.albumID)) {
				userCollected.value = await getUserCollected(props.album.albumID, props.user.id)
				console.log('%c collect ', 'color:gold;font-size:1.2em', userCollected)
			} else {
				userCollected.value = {}
			}
		}

		onMounted( async () => {
			console.log('Mounted', props.album.albumID, props.user.id)
			getCollectedAlbum()
			if(props.album == null) {
				console.log('%c album mangler props', 'color:rgb(155, 223, 125);font-size:1.2em')
			}

		})
		return {
			makeArray, showTracks, showMusicians, showCollection, showLinks, showDates, showPlayer, getAlbum,
			toggleTracks, toggleMusicians, toggleLinks, toggleCollection, toggleDates, togglePlayer, toggleContent, filterSearch, checkTimestamp,
			unixtimeToLongdate, timestampToInput, timestampToTimedate, timestampToLongdate, isHovered, getUserCollected, userCollected, getCollectedAlbum

		}
	}
}
</script>

<style scoped>
	.modal img {
		max-width: 100%;
	}
	.modal {
		max-width: 500px;
	}
	.modal::-webkit-scrollbar { /* Hide scrollbar for Chrome, Safari and Opera */
		display: none;
	}
	.modal { /* Hide scrollbar for IE, Edge and Firefox */
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
</style>