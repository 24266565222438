<template>
  	<teleport to="#modals">
		<Transition name="fade" appear>
			<div class="modal-overlay" @click.self="toggleModal" aria-label="Close modal">
				<div :class="{ modal: true, shadowHigh: !isHovered, shadowMedium: isHovered}"
					role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false">
					<section class="modal-body" id="modalDescription">
						<star-rating
							v-if="review.reviewRating"
							class="rating"
							v-bind:star-size="30"
							:increment="0.5"
							:glow="2" 
							glow-color="#ffd055"
							text-class="avg-star-text"
							:padding="0"
							:animate="false"
							:read-only="true"
							:show-rating="true"
							inactive-color="#212121"
							v-model:rating="review.reviewRating">
						</star-rating>
						<!-- text size -->
						<div class="textZoom">
							<span @click="textBigger" class="textSmaller material-icons textIcon ">text_decrease</span>
							<span @click="textBigger" class="textBigger material-icons textIcon">text_increase</span>
						</div>
						<h2 v-if="review.reviewHeader && review.userName == 'Allmusic'">Review by {{ review.reviewHeader }}</h2>
						<h2 v-else-if="review.reviewHeader">{{ review.reviewHeader }}</h2>
						<slot name="body">
							<p v-if="review.reviewBody" class="reviewBody" id="reviewBody" v-html="newBody" :style="{ fontSize: fontSize + 'em' }"></p>
						</slot>
					</section>
					<footer class="modal-footer">
						<slot name="footer">
							<div class="metadata">
								<p v-if="review.userName">Written by <strong>{{ review.userName }}</strong> {{ timestampToTimedate(review.created) }}</p>
								<p v-if="review.created">Created {{ review.timeCreated}} ago</p>
								<p v-if="review.updated">Updated {{ review.timeUpdated }} ago</p>
							</div>
						</slot>
					</footer>
					<!-- Close button -->
					<span @click="toggleModal" class="close-btn material-icons" aria-label="Close modal">cancel</span>
					
				</div>
			</div>
		</Transition>
  	</teleport>
</template>

<script>
import { onMounted, ref } from 'vue'
import StarRating from 'vue-star-rating'
// Sunes composables
import { timestampToTimedate } from '@/composables/timestampConverter'
import { splitBody } from '@/composables/splitBody';
import { makeArray } from '@/composables/makeArray';

export default {
	emits: ['close'],
	props: ['review'],
	components: { StarRating },
	setup(props, context) {
		const isHovered = ref(false)
		const fontSize = ref(1.0) // standard font in em
		const newBody = ref(null)

		const showTracks = ref(false)
		const showMusicians = ref(false)
		const showCollection = ref(false)
		const showLinks = ref(false)
		const showDates = ref(false)

		const toggleTracks = () => {
			showTracks.value = true
			showMusicians.value = false
			showCollection.value = false
			showLinks.value = false
			showDates.value = false
		}
		const toggleMusicians = () => {
			showTracks.value = false
			showMusicians.value = true
			showCollection.value = false
			showLinks.value = false
			showDates.value = false
		}
		const toggleLinks = () => {
			showTracks.value = false
			showMusicians.value = false
			showCollection.value = false
			showLinks.value = true
			showDates.value = false
		}
		const toggleCollection = () => {
			showTracks.value = false
			showMusicians.value = false
			showCollection.value = true
			showLinks.value = false
			showDates.value = false
		}
		const toggleDates = () => {
			showTracks.value = false
			showMusicians.value = false
			showCollection.value = false
			showLinks.value = false
			showDates.value = true
		}

		const textBigger = (event) => {
			console.log('event: ', event)
			console.log('class: ', event.target)
			const classes = event.target.classList
			// let textbox = document.getElementsByClassName("reviewBody")
			const textbox = document.getElementById('reviewBody')
			console.log('textbox: ', textbox)
			console.log('textbox: ', textbox)
			console.log('fontSize: ', fontSize)
			classes.forEach(element => {
				if(element == 'textBigger'){
					console.log('textBigger element: ', element)
					// textbox.innerHTML.style.fontSize += 2
					fontSize.value += 0.1
					console.log('fontSize: ', fontSize)
					console.log('textbox: ', textbox.style.fontSize)
					
				} else if(element == 'textSmaller') {
					fontSize.value -= 0.1
					console.log('textSmaller element: ', element)
				}
			});
		}
		// ALGOLIA SEARCH
		const filterSearch = (event, refine, currentRefinement) => {
            // If Click on musician
			console.log('event: ', event)
            event.preventDefault();
            if(event.type === 'click'){
                queryMusician.value = event.target.innerText
                refine(event.currentTarget.innerText)
            }
            // If Search input
            if(event.type === 'input'){
                currentRefinement = event.currentTarget.value
                queryMusician.value = currentRefinement
                refine(event.currentTarget.value)
            }
        }

		const toggleModal = () => {
			context.emit('close')
		}

		onMounted(()=> {
			newBody.value = splitBody(props.review.reviewBody) //<br> for Each '. '
		})

		return { makeArray, toggleModal, timestampToTimedate, splitBody,
			showTracks, showMusicians, showCollection, showLinks, showDates,
			toggleTracks, toggleMusicians, toggleLinks, toggleCollection, 
			toggleDates, filterSearch, textBigger, fontSize, newBody, isHovered
			
		}
	}
}
</script>

<style scoped>
.textZoom {
	position: absolute;
	top: -40px;
	right: 70px;
}

</style>