<template>
	<TransitionGroup tag="div" name="playlistAnime" class="playlistModule">
		<div v-for="item in playlist" :key="item.id" :class="{playlistContainer: true, listView: !gridView}">
			<!-- <pre>{{ item }}</pre> -->
			<!-- <h2>{{ item.albumData.Album }}</h2> -->
			<h3>{{ item.artist }}</h3>
			<h4>{{ item.album }} ({{ item.year }})</h4>
			<div class="album-cover">
				<img v-if="item.albumId" :src="require('../assets/covers/' + item.albumId + '.jpg')" 
				:alt="item.album" loading="lazy"
				class="bilde"
				@click="openAlbumModal(item)"
				:id="item.id">
			</div>
			<h4>{{ item.timeCreated }} ago</h4>
			<!-- <h4>{{ timestampToInput(item.time) }}</h4> -->
			<!-- <p v-if="item.updated"><em>{{ timestampToTimedate(item.updated) }}</em></p> -->
			<div class="icons">
				<div class="tooltip">
					<span :class="{ icon: true, iconInfo: true}">
						<span @click="openAlbumModal(item)" class="material-icons">info</span>
						<span class="tooltiptext">Album Details</span>
					</span>
				</div>
				<div class="tooltip iconEdit">
					<div @click="openTimestampModal(item)">
						<span class="material-icons">edit</span>
						<span class="tooltiptext">Edit</span></div>
					<!-- <div @click="deleteReview(review)"><span class="material-icons">delete</span><span class="tooltiptext">Delete Review</span></div> -->
				</div>
				<div class="tooltip iconDelete">
					<div @click="deleteFromPlaylist(item)">
						<span class="material-icons">delete</span>
						<span class="tooltiptext">Delete</span></div>
					<!-- <div @click="deleteReview(review)"><span class="material-icons">delete</span><span class="tooltiptext">Delete Review</span></div> -->
				</div>
			</div>
		</div>
	</TransitionGroup>

	<div class="load-more">
		<button @click="handleClick">Load More...</button>
	</div>

	<!-- </div> -->
	<AlbumModal
		v-if="showAlbumModal" 
		class="albumModal"
		@close="closeAlbumModal"
		:album="activeAlbum"
		:albumCollection="albumCollection"
		:user="user"
	>
	</AlbumModal>
	<TimestampModal 
		v-if="showTimestampModal" 
		class="albumModal"
		@close="closeTimestampModal"
		:item="activeItem"
		:user="user"
	/>
</template>

<script>
import { auth, db } from '@/firebase/config'
import { doc, deleteDoc } from 'firebase/firestore'
import { ref } from 'vue';
// Composables
import { unixtimeToLongdate, timestampToInput, timestampToInputTime, timestampToTimedate, timestampToLongdate } from '@/composables/timestampConverter'
// Components
import AlbumModal from '@/components/modals/AlbumModal.vue'
import TimestampModal from '@/components/modals/TimestampModal.vue'
import { getAlbum } from '@/composables/getDoc';

export default {
	emits: ['close','get-more'],
	props: ['playlist', 'gridView', 'user'],
	components: { AlbumModal, TimestampModal },
	setup(props, context) {
		const showAlbumModal = ref(false)
		const showTimestampModal = ref(false)
		const activeAlbum = ref()
		const activeItem = ref()

		const handleClick = () => {
			console.log('handleClick()')
			console.log('emit dette til parent, som igjen henter mer data')
			context.emit('get-more')
			console.log(props)

		}

		const openAlbumModal = async (item) => {
			console.log('item: ', {...item})
			console.log('item: ', item.albumId)
			const fetchData = await getAlbum(item.albumId)
			
			showAlbumModal.value = true
			activeAlbum.value = fetchData
			console.log('activeAlbum: ', activeAlbum)
		}
		const closeAlbumModal = () => {
			console.log('closeAlbumModal')
			showAlbumModal.value = false
		}
		const openTimestampModal = (item) => {
			item.timedate = timestampToInputTime(item.time)
			showTimestampModal.value = true
			// activeAlbum.value = item
			activeItem.value = item
			console.log('item.time: ', item.time)
			console.log('item.timedate: ', item.timedate)
			console.log('openTimestampModal', activeAlbum)
			
		}
		const closeTimestampModal = () => {
			console.log('closeLogin')
			showTimestampModal.value = false
			activeAlbum.value = null
		}
		const deleteFromPlaylist = async(item) => {
			// console.log('user.value.id: ', user.value.id)
			// console.log('item: ', item)
			// console.log('user: ', user)
			const docRef = doc(db, 'users', props.user.id, 'playlist', item.id)
			console.log('docRef: ', docRef)
			deleteDoc(docRef)
			console.log( 'Removed album', item.artist,':', item.album, 'from user playlist')
			// toggleModal()
		}
		
		return { openAlbumModal, getAlbum,
			showAlbumModal, closeAlbumModal, activeAlbum, activeItem, handleClick,
			showTimestampModal, openTimestampModal, closeTimestampModal, deleteFromPlaylist,
			unixtimeToLongdate, timestampToInput, timestampToTimedate, timestampToLongdate
		 }
	}
}
</script>

<style scoped>
.album-cover{
    display: block;
}
.album-cover img{
	width: 100%;
    cursor: pointer;
	border-radius: 10px;
	padding: 0 5px;
}
.album-cover img:last-child{
    margin: 0px;
}
.playlistModule{
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(auto-fit, clamp(150px, 200px, 500px));
	justify-content: space-around;
	/* Scroll */
	/* max-height: 80%;
	box-sizing: border-box;
	overflow: auto;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 10px; */

}
.playlistContainer{
	/* margin: 15px auto; */
	background: #90909055;
	/* padding: 10px; */
	display: grid;
	grid-template-columns: 1fr;
	/* grid-template-rows: repeat(auto-fit, auto); */
}
.playlistModule.listView{
	grid-template-columns: repeat(2, 1fr);
}
.playlistContainer.listView {

}
/* .playlistContainer{
	background: #90909055;
	padding: 10px;
	flex: 0 1 12%;
	flex-grow: 1;
	min-width: 200px;
} */
/* .playlistContainer.listView{
	flex: 0 1 100%;
	text-align: center;
} */
.playlistContainer.listView .album-cover{
	width: 80%;
	margin: 0 auto;
}
.playlistContainer.listView {
	font-size: 1.4em;
}
.playlistContainer h3,
.playlistContainer h4{
	text-align: center;
	/* padding: 10px; */
}
.playlistContainer h4 {
	padding-top: -5px;
}
.iconInfo{
	transition: color 3s ease-in-out;
}
.iconDelete{
	transition: color 1s ease-in-out;
}
.iconEdit{
	transition: color 1s ease-in-out;
}

.material-icons:hover{
	animation: glow 0.8s ease-in-out infinite alternate;
}
@keyframes glow {
  from {
    text-shadow: 0 0 2px #fff,  0 0 6px #f1dd93;
}
to {
	  text-shadow: 0 0 4px #fff,  0 0 8px #dbf14f;
    /* text-shadow: 0 0 2px #fff, 0 0 3px #ff4da6, 0 0 4px #ff4da6, 0 0 5px #ff4da6, 0 0 6px #ff4da6, 0 0 7px #ff4da6, 0 0 8px #ff4da6; */
  }
}
.load-more{
	text-align: center;
	margin: 20px 0;
}
.playlistAnime{
	position: absolute;
	top: 50vh;
}
/* 1. declare transition */
.playlistAnime-move,
.playlistAnime-enter-active {
  /* transition: all 0.5s; */
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  /* transition: all 5s ease; */
}

/* 2. declare enter from and leave to state */
.playlistAnime-enter-from,
.playlistAnime-leave-to {
  opacity: 0;
  /* transform: scale(0.1) translate(-150px, 0); */
  transform: translate(300px);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.playlistAnime-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
	position: absolute;
}

/* @media only screen and (max-width: 1450px){
	.playlistModule{
		flex: 0 1 24%;;
	}
}
@media only screen and (max-width: 1090px){
	.playlistModule{
		flex: 0 1 32%;
	}
}
@media only screen and (max-width: 720px) {
    .playlistModule{
        flex: 0 1 49%;
    }
}
@media only screen and (max-width: 400px) {
    .playlistModule{
        flex: 0 1 99%;
    }
} */
/* @media only screen and (max-width: 1450px){
	.playlistModule{
		grid-template-columns:  1fr 1fr 1fr 1fr;
	}
}
@media only screen and (max-width: 1090px){
	.playlistModule{
		grid-template-columns:  1fr 1fr 1fr;
	}
}
@media only screen and (max-width: 720px) {
    .playlistModule{
        grid-template-columns:  1fr 1fr;
    }
}
@media only screen and (max-width: 400px) {
    .playlistModule{
        grid-template-columns:  1fr;
    }
} */



</style>