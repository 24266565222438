import { collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase/config';

const arrayToAvg = (rates) => {
	if (rates == null || rates.isEmpty) {
		return null
	}
	let sum = 0;
	rates.forEach((rate) => {
		sum += rate;
	})
	let avg =  sum / rates.length
	return Number(avg.toFixed(2));
}
async function updateAverage (album) {
	let ratings = []
	const reviewQuery = query(collection(db, "reviews"), where("albumID", "==", album.id));
	const albumReviews = await getDocs(reviewQuery);
	albumReviews.forEach((review)=>{
		// console.log('review', review.id, " => Rating", review.data().reviewRating, review.data());
		let rate = review.data().reviewRating
		// console.log('rate: ', rate)
		if(rate){
			ratings.push(rate)
		}
	})
	let average = arrayToAvg(ratings)
	let ratingCount = ratings.length

	// Update album
	const albumRef = doc(db, 'Jazz', album.id)
	await updateDoc(albumRef, {
		ratingAverage: average,
		ratingCount: ratingCount,
		Update: serverTimestamp()
		}, { merge: true }
	)
	// console.log('Album Updated with avg rating of', Number(average))
	return average
}

export { updateAverage };
