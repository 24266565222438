<template>
  	<teleport to="#modals">
		<Transition name="fade" appear>
			<div class="modal-overlay" @click.self="$emit('close')" aria-label="Close modal">
				<div :class="{ modal: true, loginBox: true, shadowHigh: !isHovered, shadowMedium: isHovered}" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" :draggable="true"
					@mouseenter="isHovered = true" @mouseleave="isHovered = false" >
					<h2 v-if="!userReview.reviewID && !userReview.updated">Add Review</h2>
					<h2 v-else>Update Review</h2>
					<br>
					<h3>{{ userReview.reviewHeader }}</h3>
					<form @submit.prevent="addReview(userReview)" >
						<star-rating
							class="reviewRating"
							:increment="0.5"
							:star-size="30"
							:padding="2"
							:animate="true"
							v-model:rating="userReview.reviewRating">
						</star-rating>
						<label for="reviewHeader">Review Header</label>
						<input id="reviewHeader" type="text" placeholder="Review Header" v-model="userReview.reviewHeader">
						<label for="reviewText">Review Text</label>
						<textarea name="reviewText" id="reviewText" placeholder="Review Body" v-model="userReview.reviewBody"></textarea>
						<button v-if="!userReview.reviewID && !userReview.updated">Add Review</button>
					</form>
					<div v-if="userReview.reviewID || userReview.updated" class="buttons">
						<button @click="updateReview(userReview)">Update Review</button>
						<button @click="deleteReview(userReview)">Remove Review</button>
					</div>
					<span @click="$emit('close')" class="close-btn material-icons" aria-label="Close modal">cancel</span>
				</div>
			</div>
		</Transition>
  	</teleport>
</template>

<script>
import { onMounted, ref } from 'vue'
import StarRating from 'vue-star-rating'
import { updateAverage } from '@/composables/updateAverage'
import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, query, serverTimestamp, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore'
import { db } from '@/firebase/config'

export default {
	emits: ['close', 'new-review', 'update-review', 'delete-review'],
	props: ['album', 'user'],
	components: { StarRating },
	setup(props, context) {

		const review = ref(null)
		const reviewRating = ref(null)
		const reviewHeader = ref(null)
		const reviewBody = ref(null)
		const reviewID = ref(null)
		const isHovered = ref(false)
		// This file only
		const userReview = ref({})
		// const user = ref(props.user)

		const updateReview = async (review) =>{
			// Update review
			let updatedData = {...review }
			delete updatedData.timeUpdated;
			updatedData.updated = serverTimestamp()

			// Update Firestore Review
			const reviewRef = doc(db, 'reviews',reviewID.value)
			const updatedReview = await updateDoc(reviewRef, updatedData, { merge: true })
			
			// Update Average
			let newAvg = await updateAverage(props.album)
			props.album.ratingAverage = newAvg
			
			// make custom date for faking firestore timestamp
			updatedData.updated = Timestamp.fromDate(new Date())
			
			// Emit data to parent (Home.vue)
			context.emit('update-review', updatedData, props.user)
			
			// Log Results & Close Modal 
			console.log('%c Updated Review ','color:white;font-size:1.2em;background:orange', updatedData)
			context.emit('close')
		}

		const addReview = async (review) => {
			// Build data
			const data = {}
			if (review.reviewHeader !== undefined && review.reviewHeader !== ''){ data.reviewHeader = review.reviewHeader }
			if (review.reviewBody !== undefined && review.reviewBody !== ''){ data.reviewBody = review.reviewBody }
			if (review.reviewRating){ data.reviewRating = review.reviewRating }
			let albumTitle = `${props.album.Artist} - ${props.album.Album} (${props.album.Year})`
			data.albumTitle = albumTitle
            data.albumID = props.album.id
			data.userID = props.user.id
			data.userName = props.user.name
			data.created = serverTimestamp()
			console.log('%c addReview ', 'color:green', data)

			// Create Firestore Review
			const reviewsRef = collection(db, "reviews")
			const newReview = await addDoc(reviewsRef, data, {merge: true})
			data.reviewID = newReview.id
			
			// Update Firestore Review
			const albumRef = doc(db, 'Jazz', props.album.id)
			await updateDoc(albumRef, {
				reviews: arrayUnion(newReview.id)
			})
			
			// Update Firestore User
			const userRef = doc(db, 'users', props.user.id)
			await updateDoc(userRef, {
				reviewIDs: arrayUnion(newReview.id),
				reviews: arrayUnion(data.albumID)
			})
			
			// Custom date for faking firestore timestamp
			data.created = Timestamp.fromDate(new Date())
			
			// Update Average
			let newAvg = await updateAverage(props.album)
			props.album.ratingAverage = newAvg
			
			let updatedUser = {...props.user}
			// Modify User Obj
			updatedUser.reviewIDs.push(newReview.id)
			updatedUser.reviews.push(data.albumID)
			
			// Emit data to parent (Home.vue)
			context.emit('new-review', data, updatedUser)

			// Log Results & Close Modal 
			console.log('%c New Review ','color:white;font-size:1.2em;background: green', data)
			context.emit('close')
		}

		const deleteReview = async (review) => {
			// Delete Firestore Review
			let reviewRef = doc(db, 'reviews', reviewID.value)
			await deleteDoc(reviewRef)
			
			// Update Firestore Album
			let albumRef = doc(db, 'Jazz', props.album.id)
			await updateDoc(albumRef, {
				reviews: arrayRemove(reviewID.value)
			})
			
			// Update Firestore User
			let userRef = doc(db, 'users', props.user.id)
			await updateDoc(userRef, {
				reviewIDs: arrayRemove(reviewID.value),
				reviews: arrayRemove(props.album.id)
			})
			
			// Update Average
			let newAvg = await updateAverage(props.album)
			props.album.ratingAverage = newAvg
			
			// Update review icon 
			let pos = props.user.reviews.indexOf(props.album.id)
			
			// Modify user obj
			let updatedUser = {...props.user}
			updatedUser.reviews.splice(pos, 1)
			updatedUser.reviewIDs.splice(pos, 1)
			
			// Emit changes to userReview
			context.emit('delete-review', review, updatedUser)
			
			// Log Results & Close Modal 
			console.log('%c Deleted Review ', 'color:yellow;font-size:1.2em;background: red', review)
			context.emit('close')
		}

		const getUserReview = async () => {
			// Check for user, even though only users can click button
			if(props.user.id != null) {
				let reviewIDs = props.user.reviewIDs
				let reviews = props.user.reviews
				console.log('%c user reviews', 'color:purple;font-size:1.2em', reviews, reviewIDs)

				if(props.user.reviews.includes(props.album.id)) {
					const reviewIndex = reviews.indexOf(props.album.albumID)
					const userReviewID = reviewIDs[reviewIndex]
					const docRef = doc(db, 'reviews', userReviewID)
					const docSnap = await getDoc(docRef)
					if (docSnap.exists()) {
						userReview.value = {...docSnap.data(), reviewID: userReviewID }
						console.log('%c userReview','color:brown', userReview)
						reviewRating.value = docSnap.data().reviewRating
						reviewHeader.value = docSnap.data().reviewHeader
						reviewBody.value = docSnap.data().reviewBody
						reviewID.value = docSnap.id
						review.value = {...docSnap.data(), reviewID: docSnap.id }
						console.log('%c User has a review for this album', 'color:orange', userReview.value)
					}

				} else {
					console.log('%c User has no review for this album', 'color:red')
				}
			}
		}

		onMounted(()=> {
			getUserReview()
		})
	
		return { updateAverage, addReview, updateReview, reviewRating, reviewHeader, reviewBody, review, deleteReview, reviewID, 
			isHovered, getUserReview, userReview
		 }
	}
}
</script>

<style scoped>
.reviewRating{
	display: block;
	width: 180px;
	margin: 10px auto;
}
#reviewHeader{
	height: 50px;
	width: 100%;
	margin: 2px auto 20px;
}
#reviewText{
	height: 150px;
	width: 100%;
	margin: 2px auto 10px;
	resize: vertical;
}






</style>