<template>
	<div class="heading"> 
		<h1>{{ user.name }} Playlist</h1>
	</div>
	<div class="viewicons">
		<span :class="{ icon: true, active: gridView}">
			<span @click="changeView" class="material-icons gridView">grid_view</span>
		</span>
		<span :class="{ icon: true, active: !gridView}">
			<span @click="changeView" class="material-icons listView">view_list</span>
		</span>
	</div>
	<PlaylistModule
		v-if="playlists"
		:playlist="playlistArray"
		:user="user"
		:gridView="gridView"
		@get-more="fetchItems"
	/>
</template>

<script>
import { db } from '@/firebase/config'
import { formatDistanceToNow } from 'date-fns'
import { collection, getDocs, limit, orderBy, query, startAfter } from 'firebase/firestore'
import { onMounted, onUnmounted, ref, watch } from 'vue'
// Composables
import { timestampToInput, timestampToLongdate, timestampToTimedate, unixtimeToLongdate } from '@/composables/timestampConverter'
// Components
import PlaylistModule from '@/components/PlaylistModule.vue'

export default {
	emits: ['get-more', 'update-user'],
	props: ['playlist', 'user'],
	components: { PlaylistModule },
	setup(props){


		// Watch (wait) for update from App user, then startup module
		const waitForUser = watch(()=> props.user, (newVal) => {
            console.log('hello', newVal)
			window.addEventListener("scroll", handleScroll);
			// fetchPlaylistData()
			fetchItems()
			console.log('Fetch Start!!')
        })

		const playlists = ref([])
		const playlistArray = ref([])

		const gridView = ref(true)
		
		const latestDoc = ref(null)
		const limiter = ref(12)
		const loading = ref(false)
		const hasMore = ref(true)
		let unsubscribe = null

		const changeView = (e) => {
			if(e.target.classList.contains('listView') ){
				gridView.value = false
				console.log('LIST')
				console.log('gridView', gridView.value)
			} else {
				gridView.value = true
				console.log('GRID')
				console.log('gridView', gridView.value)

			}
		}

		const fetchItems = async () => {
			let q
			if(latestDoc.value){
				q = query(
				collection(db, 'users', props.user.id, 'playlist' ), 
				orderBy('time', 'desc'),
				startAfter(latestDoc.value.time),
				limit(limiter.value))
			} else {
				q = query(
				collection(db, 'users', props.user.id, 'playlist' ), 
				orderBy('time', 'desc'),
				limit(limiter.value))
			}
			const albumSnapshot = await getDocs(q)
			albumSnapshot.forEach( async (item) => {

				let data = {...item.data(), id: item.id}
				if(item.data().time) { data.timeCreated = formatDistanceToNow(item.data().time.toDate()) }
				if(item.data().updated) { data.timeUpdated = formatDistanceToNow(item.data().updated.toDate()) }
				playlistArray.value.push(data)

					latestDoc.value = playlistArray.value[playlistArray.value.length -1 ]
				})
			console.log('%c latestDoc: ', 'color:orange;font-size:1.2em', latestDoc)
			console.log('%c playlistArray', 'color:rgb(145, 80, 163);font-size:1.2em', playlistArray)

		}
	  	// const fetchPlaylistData = async (num) => {
		// 	console.log('%c fetchPlaylistData()', 'color:orange;font-size:1.4em', props.user.id)
		// 	try {
		// 		console.log('fetchPlaylistData props.user.id: ', props.user.id)
		// 		let q;
		// 		if(latestDoc.value){
		// 			q = query(
		// 			collection(db, 'users', props.user.id, 'playlist' ), 
		// 			orderBy('time', 'desc'),
		// 			startAfter(latestDoc.value.data().time),
		// 			limit(limiter.value))
		// 		} else {
		// 			q = query(
		// 			collection(db, 'users', props.user.id, 'playlist' ), 
		// 			orderBy('time', 'desc'),
		// 			limit(limiter.value))
		// 		}
		// 		unsubscribe = onSnapshot(q, async (playlistSnapshot) => {
		// 			if (playlistSnapshot.empty) {
		// 				console.log('empty')
		// 				hasMore.value = false;
		// 				latestDoc.value = null
		// 			}
		// 			const playlistPromises = playlistSnapshot.docs.map(async (item) => {
		// 				const albumDocRef = doc(db, 'Jazz', item.data().albumId)
		// 	  			const albumSnapshot = await getDoc(albumDocRef)
		
		// 				let timeCreated, timeUpdated
		// 				if(item.data().time){
		// 					timeCreated = formatDistanceToNow(item.data().time.toDate())
		// 					}
		// 				if(item.data().update){
		// 					timeUpdated = formatDistanceToNow(item.data().update.toDate())
		// 					}
		// 				// Listen to changes in the album document
		// 				const albumDocSnapshot = await getDoc(albumDocRef)
		// 				const albumData = albumDocSnapshot.data()

		// 				return { ...item.data(),
		// 					id: item.id,
		// 					albumData: albumData,
		// 					timeCreated: timeCreated,
		// 					timeUpdated: timeUpdated
		// 				}
		// 			})
		// 			// Update playlists array
		// 			let newPlaylist = await Promise.all(playlistPromises)
		// 			// playlists.value.push(newPlaylist)
		// 			playlists.value = await Promise.all(playlistPromises)
		// 			// playlists.value = await Promise.all(playlistPromises)
		// 			console.log('%c Updated Playlists: ', 'color:lime;font-size:1.4em', playlists)

		// 			latestDoc.value = playlistSnapshot.docs[playlistSnapshot.docs.length -1]
		// 			// console.log('%c latestDoc: ', 'color:orange;font-size:1.2em', latestDoc.value.data())
		// 		})
				
		// 	} catch (error) {
		// 		console.error('Error fetching playlist:', error)
		// 	}
		// }

		const handleScroll = () => {
			if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
				if( props.user != null ){
					console.log('User is NOT NULL => fetchPlaylistData()')
					// fetchPlaylistData()
					fetchItems()
				}
			}
			// window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 20
			// window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight
		}
		// watch: {
		// 	props(props.user, old) {
		// 		if( old === undefined && props.user !== undefined) {
		// 			console.log('%c Watch User prop', 'coolor:red;font-size:1.4em', props.user)
		// 		}
		// 	}
		// }

		// Startup
		onMounted( async ()=> {
			console.log('MOUNT!!')
			window.addEventListener("scroll", handleScroll);
			// fetchPlaylistData()
			fetchItems()
			
		})
		onUnmounted(() => {
			window.removeEventListener("scroll", handleScroll);
			if (unsubscribe) {unsubscribe()}
		})
  
  		return { playlists, latestDoc, limiter, handleScroll, hasMore, loading, gridView, changeView,
			unixtimeToLongdate, timestampToInput, timestampToTimedate, timestampToLongdate,
			fetchItems, playlistArray
		 }
	}

}
</script>

<style scoped>
.heading{
	position: relative;
	/* display: flex; */
	background: rgba(120, 120, 120, 0.15);
	/* height: 50px; */
	width: 100%;
	margin-bottom: 15px;
	border-radius: 5px;
	/* margin-top: 15px; */
	padding: 10px;
	/* line-height: 0.5; */
	/* height: 50px; */
}
.heading h1{
	display: inline;
	margin: 0;
	padding: 0;
	/* font-size: 1.6em; */
	text-align: left;
	/* width: 60vw; */
	/* width: 80vw; */
	padding-top: 10px;
}
.viewicons{
	/* display: flex; */
	/* display: inline; */
	/* flex-wrap: wrap; */
	margin-top: 10px;
	/* margin-right: 20px; */
	/* padding-top: 10px; */
	/* justify-content: flex-end; */
	/* position: absolute; */
	/* top: 35px;
	right: -20px; */
	/* z-index: -9; */
	/* float: right; */
}
.tooltiptext{
	position: absolute;
	height: 30px;
	top: -35px;
}
.icon {
	color: rgba(143, 143, 143, 0.6);
}
.active {
	color: rgba(27, 87, 27, 0.9);
}
</style>