<template>
	<h1>All Artists</h1>
	<header>
		<div class="alpha-buttons">
			<template v-for="letter in alpha" :key="letter">
				<div @click="getArtistsByLetter($event)" :class="{ letter: true, active: startLetter == letter }">{{ letter }}</div>
			</template>
		</div>
		<!-- Searchbox -->
		<input v-model="searchQuery" @keyup="searchArtists" placeholder="Search artist" />
	</header>

	<div class="artistsContainer">
		<div v-for="artist in searchHits" :key="artist.id" class="artistBox">
			<div class="artistHeader">
				<h2 v-if="artist.alias">{{ artist.alias }}</h2>
			</div>
			<router-link :to="{ name: 'Artist', params: { id: artist.id } }">
				<div class="profileImage">
					<img :src="require('../assets/profiles/' + artist.id + '.jpg')" 
					:alt="artist.name" loading="lazy"
					class="bilde"
					>
				</div>
			</router-link>
			<h3 v-if="artist.alias">
				<span id="timespan" v-if="artist.birth">{{ timestampToYear(artist.birth) }}</span>
				<span v-if="artist.death"> - {{ timestampToYear(artist.death) }}</span>
			</h3>
			<!-- <div class="basicInfo">
				<p v-if="artist.id">ID: {{ artist.id }}</p>
				<p v-if="artist.birth">Birth: {{ timestampToLongdate(artist.birth) }}</p>
				<p v-if="artist.death">Death: {{ timestampToLongdate(artist.death) }} ({{ timestampToTotalYears(artist.birth, artist.death) }} Years)</p>
				<p v-if="artist.Instrument">Instrument: {{ artist.Instrument }}</p>
				<a :href="artist.bioAmg" target="blank">Allmusic</a><br>
				<a :href="artist.bioWiki" target="blank">Wikipedia</a>
			</div>
			<div class="artistBio">
				<h3>Bio:</h3>
				<p v-if="artist.bioText && !artist.readMore">{{ artist.bioText.slice(0, 500) }}</p>
				<a class="readMore" v-if="artist.bioText.length > 200" @click="activateReadMore(artist)">Read more...</a>
				<p v-if="artist.bioText && artist.readMore" v-html="artist.bioText"></p>
			</div> -->
			
		</div>
	</div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
// import getCollection from '@/composables/getCollection'
// Sunes composables
import { fetchReviews } from '@/composables/fetchReviews';
import { timestampToLongdate, timestampToTotalYears, timestampToYear } from '@/composables/timestampConverter';
import Artist from '@/views/Artist.vue';
// Firebase import
import { db } from '@/firebase/config';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { toTitleCase, getNextString } from '@/composables/stringConverter';

export default {
  components: { Artist },
  props: ['id', 'user'],
  emits: ['update-user'],
  setup(props){
		const readMore = ref(false)
		const artists = ref([])
		const allReviews = ref([])

		const alpha = ref(['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'])
		const startLetter = ref('A')
		const stopLetter = ref('B')
		
		const activeArtist = ref(null)
		const activeSorting = ref('name')
		const limiter = ref(12)
		// Search Engine
		const searchQuery = ref('');
		const searchHits = ref([])

		const searchArtists = async () => {
			searchHits.value = [] // Clear if search query is empty
			console.log('%c searchQuery', 'color:rgb(198, 218, 16);font-size:1.2em', searchQuery.value)
			// Normalize the search query to Title Case
			const normalizedQuery = toTitleCase(searchQuery.value)
			  // Check if there's any query to search
			// if (normalizedQuery.length === 0) {
			// 	searchHits.value = [] // Clear if search query is empty
			// 	return
			// }
			  // Get the next string for upper bound comparison
  			const nextString = getNextString(normalizedQuery);

			// Firestore query
			console.log('%c normalizedQuery', 'color:rgb(205, 66, 0);font-size:1.2em', normalizedQuery)
			console.log('%c nextString', 'color:rgb(62, 82, 173);font-size:1.2em', nextString)
			const artistRef = collection(db, 'artist')
			const q1 = ['name','>=', normalizedQuery]
			const q2 = ['name','<', nextString]
			const queryRef = query(artistRef, 
				where(...q1),
				where(...q2),
				orderBy(activeSorting.value),
				limit(limiter.value)
			)
			const querySnapshot = await getDocs(queryRef);
			querySnapshot.forEach((doc) => {
				searchHits.value.push({...doc.data(), id: doc.id})
			});
			console.log('%c searchHits', 'color:rgb(160, 172, 244);font-size:1.2em', searchHits)
			
		}

		const getArtists = async () => {
			const q1 = ['name','>=', startLetter.value]
			const q2 = ['name','<=', stopLetter.value]
			const colRef = collection(db, 'artist')
			const queryRef = query(colRef, 
				where(...q1),
				where(...q2),
				orderBy(activeSorting.value),
				limit(limiter.value)
			)
			const querySnapshot = await getDocs(queryRef);
			querySnapshot.forEach((doc) => {
				searchHits.value.push({...doc.data(), id: doc.id})
				// artists.value.push({...doc.data(), id: doc.id})
			});
			console.log('Artists fetched', artists.value.length , startLetter.value, artists.value)
		}

		const getArtistsByLetter = (evt) => {
			// console.log('evt: ', evt)
			const clickLetter = evt.target.textContent
			startLetter.value = clickLetter
			// console.log('clickLetter: ', clickLetter)
			const index = alpha.value.indexOf(clickLetter)
			// console.log('index: ', index)
			const nextLetter = alpha.value[index+1]
			if(clickLetter != 'Z'){
				stopLetter.value = nextLetter
			} else {
				stopLetter.value = 'z'
			}
			// console.log('nextLetter: ', nextLetter)
			artists.value = []
			getArtists()
		}

		const clickImage = (artist, evt) => {
			console.log('clickImage', activeArtist.value)
			activeArtist.value = artist
		}

		const activateReadMore = (artist) => {
			if(!artist.readMore){
				artist.readMore = true
			} else {
				artist.readMore = false
			}
			console.log('activateReadMore', artist.readMore)
		}

		const getReviews = async () => {
			const { reviews: allReviews } = fetchReviews()
			const formattedReviews = computed(() => {
				if (reviews.value) {
					return reviews.value.map((item) => {
						let timeCreated = formatDistanceToNow(item.created.toDate())
						let timeUpdated = formatDistanceToNow(item.updated.toDate())
						return { ...item, timeCreated, timeUpdated }
					})
				}
				console.log('formattedReviews: ', formattedReviews)
				return { formattedReviews }
			})
		}

		const changeSort = (evt) => {
			let sort = evt.target.value
			console.log('sort', sort)
			activeSorting.value = sort
			console.log('activeSorting: ', activeSorting.value)
		}

		onMounted(() => {
			getArtists()
		})

		return { artists, timestampToLongdate, timestampToYear, clickImage, activateReadMore, readMore, timestampToTotalYears,
			activeArtist, activeSorting, changeSort, getReviews, allReviews, alpha, startLetter, getArtistsByLetter, stopLetter, 
			getArtists, limiter,
			searchQuery, searchArtists, searchHits, toTitleCase, getNextString
		}
	}

}
</script>

<style scoped>
input{
	width: 80vw;
	height: 40px;
	display: block;
	padding: 5px;
	margin: 20px auto;
	font-size: 1.2em;
	font-weight: 500;
}
.artistsContainer{
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 10px;
}
.artistBox {
	background: #58585845;
	padding: 10px;
	border-radius: 10px;
	margin: 20px auto;
	max-width: 400px;
	min-width: 180px;
	flex: 0 1 12%;
	flex-grow: 1;
}
.artistHeader h2{
	margin: 0 auto 20px;
	text-align: center;
}
.artistBox h3{
	margin: 10px auto 5px;
	font-size: 1.2rem;
	text-align: center;
}
.profileImage img{
	width: 100%;
	display: block;
	border-radius: 50%;
	--crop-focus-x: 0.6;
	--crop-focus-y: 0.1;
	aspect-ratio: 1 / 1;
	object-fit: cover;
	object-position: calc(var(--crop-focus-x) * 100%) calc(var(--crop-focus-y) * 100%);
	margin: 0 auto;
}
.alpha-buttons{
	display: flex;
	flex-wrap: wrap;
	justify-content:center;
	align-content: space-between;
	gap: 5px;
	cursor: pointer;
}
.letter{
	font-size: 1em;
	line-height: 1em;
	background: #cccccccc;
	color: #181818;
	display: block;
	padding: 5px;
	height: 30px;
	width: 30px;
	text-align: center;
	transition: all 0.5s;
}
.letter:hover{
	font-weight: 700;
	background: #ddd;
}
.letter.active{
	font-size: 1.2em;
	padding-top: 4px;
	font-weight: 900;
	background: #d8afaf;
}
.controls{
	width: fit-content;
	margin: 0 auto;
}
.custom-select {
	max-width: 350px;
	margin: 0 auto;
	position: relative;
}
.custom-select select {
	appearance: none;
	width: 100%;
	font-size: 1.15rem;
	padding: 0.675em 6em 0.675em 1em;
	cursor: pointer;
	background-color: var(--dropdown-background);
	border-radius: var(--dropdown-border-radius);
	color: var(--dropdown-text-color);
}
.custom-select::before,
.custom-select::after {
	--size: 0.3rem;
	position: absolute;
	content: "";
	right: 1rem;
	pointer-events: none;
}
.custom-select::before {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-bottom: var(--size) solid black;
	top: 40%;
}
.custom-select::after {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-top: var(--size) solid black;
	top: 55%;
}
.custom-select select:hover {
	background-color: var(--dropdown-hover-color);
}

.custom-select::before {
/* ... */
	border-bottom: var(--size) solid var(--dropdown-icon-color)
}

.custom-select::after {
/* ... */
	border-top: var(--size) solid var(--dropdown-icon-color);
}
:root {
	/* light theme */
	--dropdown-background: #fff;
	--dropdown-text-color: #111;
	--dropdown-hover-color: #eee;
	--dropdown-border-color: #181818;
	--dropdown-border-radius: 0.25em;
	--dropdown-icon-color: #111;
	--background-color: #eee;
	--text-color: #111;
}
/* dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--dropdown-background: #111;
		--dropdown-text-color: #fff;
		--dropdown-hover-color: #222;
		--dropdown-border-color: #555;
		--dropdown-border-radius: 0.25em;
		--dropdown-icon-color: #fff;
		--background-color: #111;
		--text-color: #fff;
	}
}
</style>