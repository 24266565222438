import { db } from "@/firebase/config"
import { addDoc, collection, doc, serverTimestamp } from "firebase/firestore"

export const addToPlaylist = async (album, userId) => {
	console.log('addToPlaylist', album, userId)

    const docRef = doc(db, 'users', userId)
    const subColRef = collection(docRef, 'playlist')
	let data = {
		albumId: album.albumID,
		created: serverTimestamp(),
        time: serverTimestamp(),
		artist: album.Artist,
		album: album.Album,
		year: album.Year
	}
    const writeDoc = await addDoc(subColRef, data)
	console.log('writeDoc: ', writeDoc.id)
}